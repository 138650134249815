import axios from "axios";

export const hasLoggedIn = () => {
  return !!localStorage.getItem("access");
};

export const logout = () => {
  localStorage.removeItem("access");
};

const inst = axios.create({
  timeout: 25000,
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
    crossDomain: false,
  },
});

inst.interceptors.request.use((config) => {
  const access = localStorage.getItem("access");
  if (access) {
    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${access}`,
      },
    };
  }
  return config;
});

inst.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    logout();
    window.location.replace("/Account/SignIn");
  } else return Promise.reject(error.response);
});

export const login = async (request) => {
  logout();

  const response = await request();

  const { data } = response;
  if (data && data.access_token) {
    localStorage.setItem("access", data.access_token);
  }

  return response;
};

export default inst;
