export const camelCase = (text: string) => {
  text = text.replace(/[-_\s.]+(.)?/g, (match, chr) => {
    return chr ? chr.toUpperCase() : ``;
  });
  return text.substr(0, 1).toLowerCase() + text.substr(1);
};

export const removeWhiteSpaceUpperCaseWord = (string: string) => {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .replace(/\s/g, "");
};

export const removeSpecialCharatersLowerCase = (string: string) => {
  string = string.replace(/\W+/g, "");
  return string.toLowerCase();
};
