import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import ConversationsJson from "../../Json_Templates/Conversations/ConversationsWithLovedOne/conversations-step7.json";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles/conversation-styles";

import Button from "../../components/Button/Button";

import Accordion from "../../components/Accordion/Accordion";
import ConversationTitle from "../../components/ConversationTitle/ConversationTitle";

export default function ConversationsStep5({ history }: any) {
  const classes = useStyles();

  return (
    <div>
      {/* @ts-ignore */}
      <Header showProgressBar progressBarStatus={80}>
        <Box className={classes.responsiveWrapper}>
          <ConversationTitle title={ConversationsJson.title} history={history}/>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "21px",
              letterSpacing: "-0.07px",
              color: "#188781",
              textAlign: "left",
              textTransform: "uppercase",
              opacity: 1,
              marginBottom: "10px",
            }}
          >
            {ConversationsJson.content.title}
          </Typography>
          <Container
            style={{ width: "100% !important" }}
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              width: "100%",
              position: "static",
            }}
            containerStyles={{
              width: "100%",
              position: "static",
            }}
            responsiveBackgroundColor="#FAF7DC"
            isFixed={false}
          >
            {ConversationsJson.content.conversations.map((conversation) => {
              return (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={0}
                  height="100%"
                  margin={1}
                >
                  {
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#4A4A4A",
                        opacity: 1,
                      }}
                      gutterBottom
                    >
                      <span>{conversation}</span>
                    </Typography>
                  }
                </Box>
              );
            })}
          </Container>
          <Container
            style={{ width: "100% !important" }}
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              width: "100%",
              marginTop: "20px",
              position: "static",
            }}
            containerStyles={{
              width: "100%",
              position: "static",
              marginBottom: "20px",
            }}
            responsiveBackgroundColor="#FAF7DC"
          >
            {ConversationsJson.content.list.map((conversation, index) => {
              return (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start"
                  height="100%"
                  margin={2}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "italic",
                      fontSize: "13px",
                      lineHeight: "17px",
                      letterSpacing: "-0.3px",
                      textAlign: "left",
                      opacity: 1,
                      borderBottom:
                        index === ConversationsJson.content.list.length - 1
                          ? "none"
                          : "1px solid #D9D9D9",
                      paddingBottom: "5px",
                      color:
                        conversation ===
                        "(Please refer to Palliative Care and Hospice Care sections in The Turning Point in Advanced Illness: Options for palliative care.)"
                          ? "#FF0000"
                          : "#4A4A4A",
                    }}
                  >
                    <span>{conversation}</span>
                  </Typography>
                </Box>
              );
            })}
          </Container>
          <Accordion
            title="The Advantages of Palliative and palliative care:"
            onClick={() => {}}
            plankColor="#188781"
            plankFont="#FFFFFF"
            expandedPlankColor="#188781"
            paragraphs={["test"]}
            arrowColor="#ffffff"
            overFlow
            renderBody={() => {
              return (
                <Box
                  style={{
                    backgroundColor: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ width: "90%", margin: "0 auto" }}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: "bold",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#1D918B",
                        opacity: 1,
                        width: "80%",
                        marginTop: "12px",
                      }}
                    >
                      <p>{ConversationsJson.content.paragraphsTitle}</p>
                    </Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#4A4A4A",
                        opacity: 1,
                      }}
                    >
                      {ConversationsJson.content.paragraphs.map(
                        (conversation) => {
                          return <p>{conversation}</p>;
                        }
                      )}
                    </Typography>
                  </Box>
                </Box>
              );
            }}
          />
          <Box style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                title="Next"
                type="button"
                onClick={() => history.push("/Conversations/WithDoctor/Step0")}
              />
            </Box>
          </Box>
        </Box>
      </Header>
    </div>
  );
}
