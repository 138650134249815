import React, { Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import styles from "./DropDown.module.scss";

const DropDown = ({
  placeholder,
  width,
  height,
  marginBottom,
  items,
  showPopUpLabel = false,
  onChange,
  defaultValue,
  popUpLabel,
  label,
  value,
  labelStyles = {},
  ...rest
}) => {
  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #9CBF85",
      fontSize: 16,
      paddingLeft: 10,
      paddingRight: 0,
      width,
      height,
      marginBottom: marginBottom,
      display: "flex",
      alignItems: "center",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);
  const useStyles = makeStyles((theme) => ({
    marginRight: 20,
    marginTop: 0,
  }));
  const classes = useStyles();
  return (
    <Fragment>
      <FormControl className={classes.width}>
        {showPopUpLabel && (
          <InputLabel id="demo-customized-select-label">
            {popUpLabel}
          </InputLabel>
        )}
        <div>
          {label && (
            <label className={styles.label} style={{ ...labelStyles }}>
              {label}
            </label>
          )}
        </div>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          input={<BootstrapInput />}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
        >
          {items &&
            items.map((item, i) => {
              return (
                <MenuItem key={item} value={item} id={i}>
                  {item}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default DropDown;
