import React, { useState, useEffect } from "react";

import { getProfile } from "../../services/profile-service";

import styles from "../Plank/Plank.module.scss";
import Media from "react-bootstrap/Media";
import { IoIosArrowForward } from "react-icons/io";
import AnimateHeight from "react-animate-height";
import Button from "../Button/Button";
import classnames from "classnames";

import Icon from "../Icon/Icon";

import { camelCase } from "../../utils/string-util";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";
import { Link } from "react-router-dom";

const CLOSED_PLANK_HEIGHT = 50;
const OPEN_PLANK_HEIGHT = "auto";

const DELAY = 500;

const Plank = ({
  icon,
  title,
  history,
  isLast,
  contentOne,
  contentTwo,
  bulletList,
  buttonLink,
  userData,
  planks,
  subHeading,
  isCompleted,
}) => {

  const [userProfile, loadProfile] = useState([]);
  const [src, setSrc] = useState("");
  import(`../../assets/${icon}.svg`).then((image) => setSrc(image.default));

  useEffect(() => {
    getProfile().then(({ data }) => {
      const userProfile = data.find(
        (user) => user.profileId === userData.profileId
      );

      loadProfile(userProfile.choices);
    });
  }, []);

  const mappingTabs = {
    Homecare: 0,
    Healthcare: 1,
    Insurance: 2,
  };

  const [isOpen, setOpen] = useState(false);

  const [height, setHeight] = useState(CLOSED_PLANK_HEIGHT);

  const [edit, setEdit] = useState();
  const iHeight = edit || height;



  const completedCare =
    planks &&
    planks.find((plank) => {
      const whiteSpaceRegex = /\s/;
      if (whiteSpaceRegex.test(title)) {
        return camelCase(title) === plank ? true : false;
      } else {
        return title.toLowerCase() === plank ? true : false;
      }
    });

  const renderBodyContent = () => {
    return (
      <div className={styles.contentContainer}>
        {completedCare ? (
          <>
            {title === "My Wellness" ? (
              <p className={styles.content}>
                Let's explore some self-care wellness tools you can use while
                you're helping your aging loved one.
              </p>
            ) : (
              <p className={styles.content}>
                If your loved ones condition has changed, you can review and
                update the information you have provided to be more accurate
              </p>
            )}
          </>
        ) : (
          <div>
            {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
            {contentOne.map((text, index) => {
              return (
                <p key={index} className={styles.content}>
                  {text}
                </p>
              );
            })}


            <div className={styles.bulletLinkContainer}>
              {bulletList && bulletList.map((bullet, index) => {
                return (
                  <Link
                    to={bullet.link}
                    className={styles.bulletLink}
                    key={index}
                  >
                    {bullet.label}
                    <IoIosArrowForward color="#818181" size={12} />
                  </Link>
                );
              })}
            </div>

            {contentTwo &&
              contentTwo.map((text, idx) => {
                if (typeof text === "object") {
                  const [label, paragraph] = Object.entries(text)[0];
                  return (
                    <p className={`${styles.content} ${styles.step}`} key={idx}>
                      <strong>{label}</strong> {paragraph}
                    </p>
                  );
                }
                return (
                  <p className={styles.content} key={idx}>
                    {text}
                  </p>
                );
              })}
          </div>
        )}

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              const actionOn = completedCare
                ? Events.Actions.edit
                : Events.Actions.launch;
              const thisPlank = buttonLink.match(/[^\/]+/g)[0].toLowerCase();
              const eventAction = `${actionOn} ${thisPlank}`;
              recordEvent(Events.Categories.homescreen, eventAction);
              history.push(buttonLink, { isInEditMode: isCompleted });
            }}
            borderRadius={7}
            title={completedCare ? "Edit" : "Start"}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Media
        className={classnames(styles.plank, {
          [styles.footerWidth]: isLast,
        })}
        onClick={() => {
          !completedCare && height === CLOSED_PLANK_HEIGHT
            ? setHeight(OPEN_PLANK_HEIGHT)
            : setHeight(CLOSED_PLANK_HEIGHT);
          isOpen && setOpen(false);
        }}
      >
        {<img className="mr-2" src={src} alt="Home Icon" />}
        <AnimateHeight height={iHeight} duration={DELAY}>
          <Media.Body>
            <div>
              <span className={styles.alignedIcon}>{title}</span>
              {isCompleted ? (
                <Icon icon="check-green-mark" style={{ float: "right" }} />
              ) : (
                <Icon icon="check-grey-mark" style={{ float: "right" }} />
              )}
            </div>
            {!isOpen &&
              (isCompleted && completedCare ? (
                <div className={styles.breadCrumbs}>
                  <span
                    onClick={() => {
                      edit === OPEN_PLANK_HEIGHT
                        ? setEdit(CLOSED_PLANK_HEIGHT)
                        : setEdit(OPEN_PLANK_HEIGHT);
                      !(edit === OPEN_PLANK_HEIGHT) && setOpen(!isOpen);
                    }}
                  >
                    <span className={styles.text}>Edit</span>
                    <IoIosArrowForward color="#818181" size={12} />
                  </span>
                  {title !== "My Wellness" && (
                    <>
                      <span>
                        <span
                          className={styles.text}
                          onClick={() =>
                            history.push("/MyPlans", {
                              tab: mappingTabs[title],
                            })
                          }
                        >
                          Plan
                        </span>
                        <IoIosArrowForward color="#818181" size={12} />
                      </span>
                    </>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => {
                    !(edit === OPEN_PLANK_HEIGHT) && setOpen(!isOpen);
                  }}
                >
                  <span className={styles.text}>Begin</span>
                  <IoIosArrowForward color="#818181" size={12} />
                </div>
              ))}
            {renderBodyContent()}
          </Media.Body>
        </AnimateHeight>
      </Media>
    </div>
  );
};

export default Plank;
