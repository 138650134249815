import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../../components/Container/Container";
import Header from "../../../components/Header/Header";
import ConversationsJson from "../../../Json_Templates/ConversationsWithFamily/ConversationsWithFamilyStep1.json";
import { Typography } from "@material-ui/core";

import { useStyles } from "../styles/conversation-styles";

import Button from "../../../components/Button/Button";

export default function ConversationsWithFamily({ history }: any) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        {/* @ts-ignore */}
        <Header showProgressBar isSticky progressBarStatus={97} />
        <div style={{ maxWidth: "90%", margin: "34px auto", height: "100vh" }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "15px",
              fontWeight: 600,
              lineHeight: "31px",
              letterSpacing: "0.09px",
              textAlign: "left",
              color: "#000000",
              textTransform: "uppercase",
              opacity: 1,
              marginBottom: "10px",
            }}
          >
            {ConversationsJson.title}
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: 600,
                letterSpacing: "0px",
                textAlign: "left",
                color: "#188781",
                opacity: 1,
              }}
            >
              Tips for Talking with Family Members
            </Typography>
          </Typography>
          <Container
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              backgroundColor: "#fff",
            }}
            containerStyles={{
              position: "static",
              width: "100%",
            }}
            responsiveBackgroundColor="#FAF7DC"
          >
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              margin={1}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
            >
              {ConversationsJson.content.titleParagraph.map((conversation) => {
                return (
                  <>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: 600,
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#188781",
                        opacity: 1,
                        marginTop: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      {conversation.title}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#4A4A4A",
                        opacity: 1,
                      }}
                    >
                      {conversation.paragraphs.map((paragraph) => {
                        return <p>{paragraph}</p>;
                      })}
                    </Typography>
                  </>
                );
              })}
            </Box>
          </Container>

          <Box
            display={"flex"}
            justifyContent="center"
            width={"100%"}
            marginTop={2}
          >
            <Button
              title="Next"
              type="button"
              onClick={() => history.push("/Conversations/WithFamily/Step3a")}
              buttonStyles={{ zIndex: 1 }}
            />
          </Box>
        </div>
      </div>
  );
}
