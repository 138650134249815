import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { withStyles } from "@material-ui/core/styles";
import { useQuestionnaire } from "../../hooks/UseQuestionnaire";
import Button from "../Button/Button";
import { Box } from "@material-ui/core";

const CustomCheckbox = withStyles({
  root: {
    color: "#1d918b",
    borderRadius: "10px",
    "&$checked": {
      color: "#1d918b",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Questionnaire = () => {
  const { handleOnChange, handleSubmit, selectedQuestions, questions } =
    useQuestionnaire();

  return (
    <>
      <FormGroup>
        {questions.map((question, index) => (
          <FormControlLabel
            key={index}
            control={
              <CustomCheckbox
                // @ts-ignore
                checked={selectedQuestions.includes(question)}
                onChange={handleOnChange}
                name={question}
              />
            }
            label={question}
            labelPlacement="end"
          />
        ))}
        <Box style={{ display: "flex", justifyContent: "center", zIndex: 2 }}>
          <Button
            title="Confirm"
            type="button"
            onClick={handleSubmit}
            isDisabled={selectedQuestions.length === 0}
          />
        </Box>
      </FormGroup>
    </>
  );
};

export default Questionnaire;
