import React from "react";
import styles from "./ProceedButton.module.scss";

interface ProceedButtonProps {
  onClick?: () => void;
  buttonStyles: { [key: string]: string };
  title: string;
  isFixed?: boolean;
  isButtonDisabled?: boolean;
}

const ProceedButton: React.FC<ProceedButtonProps> = ({
  onClick,
  buttonStyles,
  title,
  isFixed,
  isButtonDisabled,
}) => {
  const fixedStyles = isFixed ? { position: "fixed", bottom: 0 } : null;
  const disabledStyles = isButtonDisabled
    ? { backgroundColor: "#D3D3D3", cursor: "not-allowed" }
    : null;
  return (
    <button
      className={styles.button}
      onClick={() => onClick && onClick()}
      disabled={isButtonDisabled}
      //@ts-ignore
      style={{ ...buttonStyles, ...fixedStyles, ...disabledStyles }}
    >
      <span className={styles.buttonTitle}>{title}</span>
    </button>
  );
};

export default ProceedButton;
