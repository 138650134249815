import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function CheckboxesGroup({
  handleOnChange,
  items,
  color = "#1D918B",
  checkBoxColor = "#1D918B",
  labelStyles,
  labelRootStyles,
  height,
  item,
  checked,
  indexesToCheck,
  checkBoxMargin = "33px 15px 30px 35px",
  margin = "18px auto",
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      color,
      fontSize: 13,
      margin: margin
    },
    formControl: {
      height: height,
      paddingLeft: 10,
      margin: 0
    },
  }));
  const labelRootStyle = makeStyles((theme) => ({
    root: {
      ...labelRootStyles,
      pointerEvents: 'auto',
    },
  }));

  const CheckBox = withStyles({
    root: {
      color: checkBoxColor,
      margin: checkBoxMargin,
      width: "18px",
      height: "18px",
      borderRadius: "0",
      pointerEvents: 'none',
    },
    checked: {
      color: "#1d918b",
    },
  })((props) => <Checkbox color="default" {...props} />);
  const classes = useStyles();
  const labelStylesRoot = labelRootStyle();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {items ? (
            items.map((item, index) => (
              <React.Fragment key={index}>
                <FormControlLabel
                  onChange={(e) => handleOnChange(e, index)}
                  control={
                    <CheckBox
                      onChange={(e) => handleOnChange(e, index)}
                      name={item}
                      defaultChecked={checked}
                      checked={indexesToCheck && indexesToCheck.includes(item)}

                    />
                  }
                  className={labelStylesRoot.root}
                  label={
                    <span
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontSize: 13,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        ...labelStyles,
                      }}
                      key={index}
                    >
                      {item}
                    </span>
                  }
                  labelPlacement="end"
                />
              </React.Fragment>
            ))
          ) : (
            <FormControlLabel
              control={
                <CheckBox
                  onChange={(e) => handleOnChange(e)}
                  defaultChecked={checked}
                  name={item}
                />
              }
              className={labelStylesRoot.root}
              label={
                <span
                  style={{
                    fontSize: 13,
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    ...labelStyles,
                  }}
                >
                  {item}
                </span>
              }
            />
          )}
        </FormGroup>
      </FormControl>
    </div>
  );
}
