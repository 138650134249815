import React, { useState, useContext, useEffect } from "react";
import ReactGA from 'react-ga4';

import { putProfileOnboarding } from "../../services/profile-service";
import { UserDataContext } from "../../Context/Auth-Provider";

import styles from "./OnBoardingStepFour.module.scss";

import Container from "../../components/Container/Container";
import Modal from "../../components/Modal/Modal";
import DropDown from "../../components/DropDown/DropDown";
import InputSlider from "../../components/Slider/Slider";


import OnBoardingJSON from "../../Json_Templates/OnBoarding/OnBoarding.json";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";
import Banner from "../../components/Banner/Banner";
import Header from "../../components/Header/Header";
import { Box, Typography } from "@material-ui/core";
import InputFieldSet from "../../components/InputFieldSet/InputFieldSet";

const marks = [
  {
    value: 0,
    label: 'Yes',
  },
  {
    value: 50,
    label: 'No',
  },
  {
    value: 100,
    label: 'Not Sure',
  },
];



const OnBoardingStepFour = ({ history }) => {
  const data = useContext(UserDataContext);

  const person = data.fullName;
  const userId = data.profile.profileId;
  const [unComplete, setUnComplete] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);

  const [error, setError] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [userInput, setUserInput] = useState();


  useEffect(() => {
    const allQuestions = OnBoardingJSON.OnBoardingStepFour.map(item => item.question);
    const allKeys = Object.keys(inputValues);


    const allQuestionsAnswered = allQuestions.every(q => allKeys.includes(q) && inputValues[q] !== undefined);

    setUnComplete(!allQuestionsAnswered);
  }, [inputValues]);


  const handleOnClick = async () => {

    const reshapedResponses = Object.keys(inputValues).map(question => {
      // Find the corresponding question object in the OnBoardingJSON.OnBoardingStepFour array
      const questionObj = OnBoardingJSON.OnBoardingStepFour.find(item => item.question === question);

      // Check if questionObj exists and if it has answers
      if (questionObj && questionObj.answers) {
        const selectedAnswer = questionObj.answers.find(answer => answer.value === inputValues[question]);
        return {
          choiceId: selectedAnswer ? selectedAnswer.choiceId : null,
          question: questionObj.question,
          value: inputValues[question],
          category: "onboarding"
        };
      } else if (questionObj && questionObj.inputField) {
        return {
          choiceId: questionObj.choiceId,
          question: questionObj.question,
          value: inputValues[question],
          category: "onboarding"
        };
      } else if (questionObj && questionObj.sliderType) {
        return {
          choiceId: questionObj.choiceId,
          question: questionObj.question,
          value: inputValues[question],
          category: "onboarding"
        };
      }
    });

    try {
      const response = await putProfileOnboarding(userId, reshapedResponses);
      if (response.status === 200) {
        recordEvent(
          Events.Categories.onboarding,
          Events.Actions.save,
          Events.Labels.stepFour
        );
        ReactGA.gtag('set', 'user_id', userId);
        history.push("/HomeScreen");
      }
    } catch (error) {
      setError(true);
      setModalOpen(true);
    }
  };


  return (
    <Container
      style={{ containerPadding: "50px", height: "100vh" }}
      backgroundColor="#fff"
      containerStyles={{
        height: "100vh",
        width: "100vw",
        position: "relative",
        top: null,
      }}
      wrapperStyles={{ backgroundColor: "#fff" }}
      isButtonShown
      buttonProps={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
      }}
      buttonTitle="NEXT"
      buttonOnClick={() => handleOnClick()}
      isButtonDisabled={unComplete}
    >
      <Modal
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        message={"There has been an error. Please try again."}
      />
      <Header showGauge={false} isConversationPath={false} />
      <Box style={{ marginTop: "40px", height: "auto", marginBottom: "40px" }}>
        <Banner iconName="account-setup" history={history}
          color="#FAF7DC"
          textColor="#146E6C"
          showArrow />
        <div className={styles.container}>
          <Typography
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: "17px",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#146E6C",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            About Your Your Loved One
          </Typography>

          {OnBoardingJSON.OnBoardingStepFour.map((item, itemIndex) => {
            if (item.answers) {
              const dropdownItems = item.answers.reduce((items, val) => {
                items.push(val.value);
                return items;
              }, []);

              return (
                <>
                  <span className={styles.question}>
                    {item.question.replace("person", person)}
                  </span>

                  <div className={styles.inputContainer}>
                    <div className={styles.dropDownStyle}>
                      <DropDown
                        placeholder={"select"}
                        fullWidth
                        height={35}
                        items={dropdownItems}
                        itemsId={OnBoardingJSON.OnBoardingStepFour[0].choiceId}
                        value={userInput && userInput.value}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          const selectedAnswer = item.answers.find(answer => answer.value === selectedValue);


                          setInputValues(prevValues => ({
                            ...prevValues,
                            [item.question]: selectedAnswer.value
                          }));

                          setUserInput(selectedAnswer);
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            } else if (item.inputField) {
              return (
                <div className={styles.inputFieldWrapper}>
                  <span className={styles.question}>{item.question}</span>
                  <InputFieldSet
                    type="number"
                    onChange={(e) => {
                      setInputValues(prevValues => ({
                        ...prevValues,
                        [item.question]: parseInt(e.target.value)
                      }));
                    }}
                    width={100}

                  />
                </div>
              );
            } else {
              return (
                <InputSlider
                  verbiage={item.question.replace("person", person)}
                  verbiageFontSize={14}
                  step={null}
                  marks={marks}
                  min={0}
                  max={100}
                  verbiageStyles={{
                    fontSize: "14px",
                    color: '#1d918b',
                    fontWeight: 'bold'
                  }}
                  sliderStyle={{ margin: "45px 0" }}
                  legendStyles={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    paddingLeft: "15px",
                    marginBottom: "5px",
                  }}
                  defaultValue={
                    inputValues[item.question] && inputValues[item.question].value
                  }
                  value={inputValues[item.question]}
                  onChangeCommitted={(e, value) => {

                    setInputValues(prevValues => ({
                      ...prevValues,
                      [item.question]: value
                    }));

                  }}
                />
              );
            }
          })}
        </div>
      </Box>
    </Container >
  );
};

export default OnBoardingStepFour;
