import { Box, Typography } from "@material-ui/core";
import React from "react";
import Header from "../../components/Header/Header";
import { useStyles } from "./styles/conversation-styles";
import ConversationsJson from "../../Json_Templates/Conversations/ConversationsWithLovedOne/conversations-step3.json";
import ConversationCheckBox from "../../components/ConversationCheckBox/ConversationCheckBox";
import Container from "../../components/Container/Container";
export default function ConversationStep3() {
  const classes = useStyles();
  return (
    <Box style={{ backgroundColor: "#FAF7DC" }}>
      <div className={classes.root}>
        <Header showProgressBar progressBarStatus={60} />
        <div className={classes.step4Wrapper}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "9px",
              lineHeight: "20px",
              letterSpacing: "-0.03px",
              textTransform: "uppercase",
              opacity: 1,
              color: "#000000",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            {ConversationsJson.title}
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "21px",
              letterSpacing: "-0.07px",
              color: "#188781",
              textAlign: "left",
              textTransform: "uppercase",
              opacity: 1,
            }}
          >
            {ConversationsJson.content.title}
          </Typography>
          <Container
            style={{ width: "100% !important" }}
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              width: "100%",
              marginTop: "10px",
            }}
            containerStyles={{
              width: "100%",
              position: "static",
            }}
          >
            {ConversationsJson.content.conversations.map((conversation) => {
              return (
                <Box
                  display="flex"
                  alignItems="flex-start"
                  height="100%"
                  margin={2}
                >
                  <span>{conversation}</span>
                </Box>
              );
            })}
          </Container>

          <Box style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ConversationCheckBox
                verbiage="READY TO PROCEED WITH THE CONVERSATION?"
                onYesNavigateTo="/Conversations/WithDoctor"
                onNoNavigateTo="/Conversations/Step3"
              />
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
}
