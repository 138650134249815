import React, { useState, useEffect } from "react";

import styles from "./Insurance-step-one.module.scss";
import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import SliderMenu from "../../components/SliderMenu/SliderMenu";
import Step from "../../components/Step/Step";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";

import { putProfile } from "../../services/profile-service";
import { createMarks } from "../../utils/slider.util";
import { emptyState } from "../../utils/modal-messages";
import { syntaxify } from "../../utils/helpers.util";
import Device from "../../utils/responsive.util";
import InsuranceData from "../../Json_Templates/Insurance/Insurance.json";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const device = Device();

const InsuranceStepOne = ({ history, userData }) => {
  const [profileData, setProfileData] = useState([]);
  const [userProfile, loadProfile] = useState([]);

  const [labelResponsive, setLabelResponsive] = React.useState({});
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [doesUserRequireInsurance, setDoesUserRequireInsurance] = useState();
  const [validationError, setValidationError] = useState(false);

  const [insuranceUserSelection, saveSelection] = useState({});
  let userSavedSelection = {};

  useEffect(() => {
    if (userData.profileChoices) {
      saveSelection({
        ...userSavedSelection,
      });
    }
  }, [userData.profileChoices]);

  const [profileError, catchError] = useState();
  const saveProfile = async () => {
    const validatedSelection = Object.values(insuranceUserSelection)
      .filter((service) => service.value > 10)
      .reduce((validated, service) => {
        validated[service.title] = service;
        return validated;
      }, {});
    let response;
    const choices = syntaxify(insuranceUserSelection, "insurance");
    try {
      response = await putProfile(userData.profileId, choices);
      if (response.status === 200) {
        recordEvent(
          Events.Categories.insurance,
          Events.Actions.finish,
          Events.Labels.stepOne
        );
        history.push("/Insurance/Step2", {
          insuranceUserSelection: validatedSelection,
        });
      }
    } catch (err) {
      catchError(err);
    }
  };

  React.useEffect(() => {
    device.isPhone() && setLabelResponsive({ whiteSpace: "pre-line" });
  }, []);

  const handleNextStep = () => {
    let doesUserRequireInsuranceAssistance;
    let isEmpty = true;

    if (Object.keys(insuranceUserSelection).length > 0) {
      doesUserRequireInsuranceAssistance = Object.values(
        insuranceUserSelection
      ).every((userSelected) => userSelected.value === 10);
      if (doesUserRequireInsuranceAssistance) {
        setValidationError(true);
        setDoesUserRequireInsurance(true);
        setModalOpen(true);
        return;
      }
    }

    if (
      Object.keys(insuranceUserSelection).length ===
      InsuranceData.InsuranceStepOne.choices.length
    ) {
      isEmpty = false;
    }

    if (isEmpty) {
      setModalOpen(true);
    }

    if (!isEmpty) {
      saveProfile();
    }
  };

  const renderModalBody = () => {
    if (doesUserRequireInsurance) {
      return (
        <div>
          <p className={styles.errorMessage}>
            Based on your selections, it looks like your loved one does not have
            any insurance needs at this time.
          </p>
          <p className={styles.errorMessage}>
            You can proceed to the home screen, or review and refine your
            selections
          </p>
          <Button
            title={"Proceed To The Home Screen"}
            onClick={() => history.push("/HomeScreen")}
            width={"100%"}
            borderRadius={5}
          />
          <Button
            title={"Review & Refine Your Selections"}
            onClick={() => setModalOpen(false)}
            width={"100%"}
            borderRadius={5}
            fontColor={{ color: "#9cbf85", fontWeight: "bold" }}
            buttonStyles={{ backgroundColor: "transparent" }}
          />
        </div>
      );
    } else {
      return <div>{emptyState}</div>;
    }
  };

  return (
    <Header showGauge={false}>
      <Container
        isButtonShown
        wrapperStyles={{
          display: "flex",
          flexDirection: "column",
        }}
        buttonProps={{
          onClick: () => handleNextStep(),
          title: "PROCEED TO STEP 2",
        }}
      >
        <Modal
          open={isModalOpen}
          message={() => renderModalBody()}
          handleClose={() => setModalOpen(false)}
        />
        <Step
          stepCount={"1"}
          label={"Insurance"}
          title={"Whats going On"}
          verbiage={
            "Let’s get a sense of your loved one’s insurance, finance, and legal issues by answering the following questions:"
          }
          iconName={"insurance"}
        />
        {userData.profileChoices &&
          InsuranceData.InsuranceStepOne.choices.map((choice, index) => {
            const marks =
              choice.title === "Medicare"
                ? ["Regular", "Managed", "Unsure"]
                : ["Yes", "No", "Unsure"];

            const hasSaved = userData.profileChoices.find(
              (qa) => qa.choiceId === choice.choiceId
            );
            const profileSliderValue = hasSaved && hasSaved.value;
            if (!!profileSliderValue) {
              userSavedSelection = {
                ...userSavedSelection,
                [choice.title]: {
                  title: choice.title,
                  value: profileSliderValue,
                  userSelected: createMarks(marks).find(
                    (mark) => mark.value === profileSliderValue
                  ).label,
                  choiceId: choice.choiceId,
                },
              };
            }

            return (
              <SliderMenu
                backgroundColor={index % 2 === 0 ? "#FAF7DC" : "#FFF"}
                showStep={false}
                title={choice.title}
                verbiage={choice.question}
                step={null}
                max={100}
                marks={marks}
                borderBottom
                useMarks={false}
                width={"82%"}
                labelStyles={{
                  fontSize: 11.5,
                  fontFamily: "Poppins",
                }}
                id={choice.choiceId}
                defaultValue={
                  insuranceUserSelection[choice.title] &&
                  insuranceUserSelection[choice.title].value
                }
                onChangeCommitted={(e, value) => {
                  const labels = createMarks(marks);
                  const userSelected = labels.find(
                    (mark) => mark.value === value
                  );

                  saveSelection({
                    ...insuranceUserSelection,
                    [choice.title]: {
                      title: choice.title,
                      value: value,
                      userSelected: userSelected.label,
                      choiceId: choice.choiceId,
                    },
                  });
                }}
              />
            );
          })}
      </Container>
    </Header>
  );
};

export default InsuranceStepOne;
