import api from "../utils/api";
import { ChatMessageInterface } from "./chat-messages-service";

export interface ChatUserInterface {
  userId: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
}

export interface ChatInterface {
  id: string;
  users: ChatUserInterface[];
  latestMessage?: ChatMessageInterface;
  dateCreated: string;
}

export function getChats() {
  return api.get(`${appSettings.apiBaseUrl}/api/chats`);
}

export function getUserChats() {
  return api.get(`${appSettings.apiBaseUrl}/api/chats/user`);
}

export function getChat(chatId: string) {
  return api.get(`${appSettings.apiBaseUrl}/api/chats/${chatId}`);
}

export function joinChat(chatId: string) {
  return api.put(`${appSettings.apiBaseUrl}/api/chats/${chatId}`);
}

export function createChat() {
  return api.post(`${appSettings.apiBaseUrl}/api/chats`);
}
