interface obj {
  [key: string]: {};
}

export const filterArrayOfObject = (
  valueToFilter: string,
  array: Array<obj>
) => {
  return Array.from(new Set(array.map((a: obj) => a[valueToFilter]))).map(
    (x) => {
      return array.find((a: obj) => a[valueToFilter] === x);
    }
  );
};
