import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../../components/Container/Container";
import Header from "../../../components/Header/Header";
import ConversationsJson from "../../../Json_Templates/ConversationsWithDoctor/ConversationsWithDoctorStep1.json";
import { Typography } from "@material-ui/core";

import { useStyles } from ".././styles/conversation-styles";

import Button from "../../../components/Button/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(() => ({
  customUnorderedList: {
    fontFamily: "Poppins",
    fontSize: "13px",
    lineHeight: "17px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#4A4A4A",
    opacity: 1,
    paddingLeft: 0,
    listStyleType: "none",
    width: "95%",
    margin: "0 auto",
    "& li": {
      position: "relative",
      marginBottom: "0.5em",
      "&::before": {
        content: '""',
        position: "absolute",
        left: "-10px",
        top: "5px",
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        backgroundColor: "#188781",
      },
    },
  },
}));

export default function ConversationsWithDoctor({ history }: any) {
  console.log("test");
  const classes = useStyles();
  const inlineStyles = styles();
  const { title, orderedList } = ConversationsJson.content.listItems;

  return (
    <div className={classes.root}>
      {/*@ts-ignore */}
      <Header showProgressBar isSticky progressBarStatus={93} />
      <div style={{ maxWidth: "90%", margin: "34px auto" }}>
        <Typography
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "31px",
            letterSpacing: "0.09px",
            textAlign: "left",
            color: "#000000",
            textTransform: "uppercase",
            opacity: 1,
            marginBottom: "10px",
          }}
        >
          {ConversationsJson.title}
        </Typography>
        <Container
          backgroundColor="#FAF7DC"
          wrapperStyles={{
            display: "flex",
            alignItems: "center",
            paddingTop: "0px",
            borderRadius: "10px",
          }}
          containerStyles={{
            position: "static",
            width: "100%",
            marginBottom: "20px",
            backgroundColor: "#FFF",
          }}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            margin={1}
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            {ConversationsJson.content.titleParagraph.map((conversation) => {
              return (
                <>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: 600,
                      letterSpacing: "0px",
                      textAlign: "left",
                      color: "#188781",
                      opacity: 1,
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    {conversation.title}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      lineHeight: "17px",
                      letterSpacing: "0px",
                      textAlign: "left",
                      color: "#4A4A4A",
                      opacity: 1,
                    }}
                  >
                    {conversation.paragraphs.map((paragraph) => {
                      return <p>{paragraph}</p>;
                    })}
                  </Typography>
                </>
              );
            })}
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: 600,
                letterSpacing: "0px",
                textAlign: "left",
                color: "#188781",
                opacity: 1,
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              {title}
            </Typography>
            <ul className={inlineStyles.customUnorderedList}>
              {orderedList.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </Box>
        </Container>
        <Box
          display={"flex"}
          justifyContent="center"
          width={"100%"}
          marginTop={5}
        >
          <Button
            title={"Next"}
            type="button"
            onClick={() => history.push("/Conversations/WithDoctor/Step2a")}
            buttonStyles={{ zIndex: 1 }}
          />
        </Box>
      </div>
    </div>
  );
}
