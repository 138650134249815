import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "react-bootstrap";
import styles from "./Header.module.scss";
import PsiLogo from "../../components/PsiLogo/PsiLogo";
import HomeIcon from "../../assets/menu.svg";
import SideBar from "react-sidebar";
import { GoX } from "react-icons/go";
import Device from "../../utils/responsive.util";
import { History } from "history";

import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import classnames from "classnames";

import MenuIcon from "../Icon/MenuIcon";
import MapElementToScreen from "../MapElementToScreen/MapElementToScreen";

import { UserDataContext } from "../../Context/Auth-Provider";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";
import ProgressBar from "../ProgressBar/ProgressBar";

interface HeaderProps extends RouteComponentProps {
  title?: string;
  showGauge?: boolean;
  noBottomBorder?: boolean;
  isSticky?: boolean;
  children?: any[];
  history: History;
  showProgressBar?: boolean;
  progressBarStatus?: number;
  isConversationPath?: boolean;
  hideHamburger?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  children,
  history,
  isSticky = true,
  noBottomBorder = false,
  showProgressBar = false,
  progressBarStatus = 0,
  isConversationPath = true,
  hideHamburger,
}) => {
  const screen = Device();
  const divRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      //@ts-ignore
      setOffsetTop(divRef.current!.offsetTop);
    }
  }, []);

  const [isSideBarOpen, setSideBar] = useState(false);
  const userProfile = React.useContext(UserDataContext);

  const isAdmin = userProfile!.profile.isAdmin;
  const menuIconStyles = {
    margin: "10px 15px",
  };

  const [sidebarZIndex, setSidebarZIndex] = useState(0);
  const toggleSidebar = () => {
    const newState = !isSideBarOpen;

    setSideBar(newState);

    // Manage the zIndex
    if (newState) {
      setSidebarZIndex(1003);
    } else {
      setSidebarZIndex(0);
    }
  };

  return (
    <SideBar
      sidebar={
        // @ts-ignore
        <MapElementToScreen
          renderBody={() => {
            return (
              <GoX
                color={"#CCCCCC"}
                size={32}
                width={32}
                onClick={() => setSideBar(false)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  cursor: "pointer",
                }}
              />
            );
          }}
          renderFirst={() => {
            return !isAdmin ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "50px",
                  width: "100%",
                }}
              >
                <span
                  className={styles.menuTitle}
                  onClick={() => history && history.push("/HomeScreen")}
                >
                  <MenuIcon icon="home" style={menuIconStyles} />
                  Home
                </span>
                <span
                  className={styles.menuTitle}
                  onClick={() =>
                    history && history.push("/Library/healthcare?1")
                  }
                  style={{ fontSize: "15px" }}
                >
                  <MenuIcon icon="homecare" style={menuIconStyles} />
                  Advance Care Planning
                </span>
                <span
                  className={styles.menuTitle}
                  onClick={() => history && history.push("/Library")}
                >
                  <MenuIcon icon="library" style={menuIconStyles} />
                  Library
                </span>

                <span
                  className={styles.menuTitle}
                  onClick={() => history && history.push("/Chat")}
                >
                  <MenuIcon icon="chat" style={menuIconStyles} />
                  Contact
                </span>
              </div>
            ) : (
              <>
                <div
                  style={{
                    height: "169px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    backgroundColor: "#146E6C",
                  }}
                >
                  <p
                    style={{
                      color: "#EEE487",
                      fontSize: "13pt",
                      fontWeight: "bold",
                    }}
                  >
                    Admin &amp; RN Portal
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "15px",
                  }}
                >
                  <span
                    className={styles.menuTitle}
                    onClick={() => history.push("/Dashboard", { tab: 0 })}
                  >
                    <MenuIcon icon="chat" />
                    Chats
                  </span>
                  <span
                    className={styles.menuTitle}
                    onClick={() => history.push("/Dashboard", { tab: 1 })}
                  >
                    <MenuIcon icon="people" />
                    Family Information
                  </span>
                </div>
              </>
            );
          }}
          renderAfter={() => {
            return (
              !isAdmin && (
                <div
                  style={{
                    backgroundColor: "#1D918B",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    padding: "15px",
                  }}
                  ref={divRef}
                >
                  <span
                    style={{
                      color: "#EEE487",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    <MenuIcon icon="search" style={{ margin: "11px" }} />
                    Services &amp; Providers
                  </span>
                  <Link
                    className={styles.servicesAndProvidersButton}
                    to={{ pathname: "https://communityresources.trinity-health.org/" }} 
                    target="_blank"
                  >
                    Search for Resources
                  </Link>
                </div>
              )
            );
          }}
          list={{
            ls: () => {
              return (
                <div
                  style={{
                    backgroundColor: "#FAF7DC",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  className={
                    !isAdmin
                      ? styles.sideBarUserContainer
                      : styles.sideBarAdminContainer
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "20px",
                      height: `calc(98vh - ${offsetTop}px)`,
                    }}
                  >
                    <span
                      className={styles.subMenuTitle}
                      onClick={() => history && history.push("/About")}
                    >
                      <MenuIcon icon="about" />
                      About Psī
                    </span>
                    {isAdmin && (
                      <span
                        className={styles.subMenuTitle}
                        onClick={() => history.push("/AdminAccount")}
                      >
                        <MenuIcon icon="settings" />
                        Account Settings
                      </span>
                    )}
                    <span
                      className={styles.subMenuTitle}
                      onClick={() => {
                        userProfile!.logout();
                        recordEvent(
                          Events.Categories.header,
                          Events.Actions.logout
                        );
                        history &&
                          history.push(
                            `/?cId=${
                              userProfile!.profile!.affiliation
                                ? userProfile!.profile!.affiliation.toLowerCase()
                                : null
                            }`
                          );
                        history.go(0);
                      }}
                    >
                      <MenuIcon icon="logout" />
                      Logout
                    </span>
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      fontSize: "11px",
                      color: "#4A4A4A",
                      fontFamily: "Poppins",
                    }}
                  >
                    <Link to="/TermsOfService">
                      <p>Privacy Policy & Terms of Service</p>
                    </Link>
                    <span>© 2023 PsiLife LCC</span>
                  </div>
                </div>
              );
            },
          }}
        />
      }
      styles={{
        sidebar: {
          background: "white",
          width: "250px",
          zIndex: sidebarZIndex.toString(),
          minHeight: "100vh",
          overflowY: "auto",
          boxSizing: "border-box",
        },
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          boxSizing: "border-box",
          //@ts-ignore
          flexGrow: 1,
        },
      }}
      open={isSideBarOpen}
      pullRight={true}
    >
      <Navbar
        expand="lg"
        className={classnames(
          styles.header,
          {
            [styles.isSticky]: screen.isDesktop() ? true : isSticky,
            [styles.headerConversation]: isConversationPath,
          },
          "justify-content-between"
        )}
        style={noBottomBorder ? { borderColor: "#cadcdc" } : {}}
      >
        <Navbar.Brand>
          <PsiLogo
            height={30}
            onClick={() => history && history.push("/HomeScreen")}
          />
        </Navbar.Brand>

        <Navbar.Brand>
          {!hideHamburger && (
            <Navbar.Brand>
              <img
                src={HomeIcon}
                onClick={toggleSidebar}
                alt="icon"
                style={{ cursor: "pointer" }}
              />
            </Navbar.Brand>
          )}
        </Navbar.Brand>
      </Navbar>
      {/*@ts-ignore */}
      {showProgressBar && <ProgressBar progress={progressBarStatus} />}

      {children}
    </SideBar>
  );
};
/*
// @ts-ignore */
export default withRouter<HeaderProps>(Header);
