import React, { useState } from "react";

function MapElementToScreen({
  componentStyle,
  componentClass,
  onClickComponent,

  componentTitle,
  componentTitleStyle,
  componentTitleClass,
  onClickComponentTitle,

  paragraph,
  paragraphStyle,
  paragraphClass,
  onClickParagraph,

  list = {},

  renderBody,
  renderFirst,
  renderAfter,
  children,
}) {
  const [renderState, setRenderState] = useState([]);
  const mapList = () => {
    return (
      typeof list.ls !== "function" &&
      list.ls &&
      list.ls.map((li, idx) => {
        return (
          <>
            {list.renderBeforeElement && list.renderBeforeElement()}
            <li
              style={list.elementStyle}
              className={list.elementClass}
              onClick={list.onClickElement}
            >
              <p
                style={li.titleStyle}
                className={li.titleClass}
                onClick={li.onClicktitle}
              >
                {li.title}
              </p>
              <span
                style={li.textStyle}
                className={li.textClass}
                onClick={li.onClicktext}
              >
                {li.text}
              </span>
            </li>
            {list.renderAfterElement && list.renderAfterElement()}
            {list.isSeparated && idx < list.ls.length - 1 && <hr />}
          </>
        );
      })
    );
  };

  return (
    <div
      style={componentStyle}
      className={componentClass}
      onClick={onClickComponent}
    >
      {renderBody && renderBody()}
      {componentTitle && (
        <h4
          style={componentTitleStyle}
          className={componentTitleClass}
          onClick={onClickComponentTitle}
        >
          {componentTitle}
        </h4>
      )}
      {renderFirst && renderFirst()}
      <span
        style={paragraphStyle}
        className={paragraphClass}
        onClick={onClickParagraph}
      >
        {paragraph}
      </span>
      {list.isOrdered ? (
        <ol
          type={list.orderType || "decimal"}
          style={list.listStyle}
          className={list.listClass}
          onClick={list.onClickList}
        >
          {mapList()}
        </ol>
      ) : (
        list.ls && (
          <ul
            style={list.listStyle}
            className={list.listClass}
            onClick={list.onClickList}
          >
            {mapList()}
          </ul>
        )
      )}
      {children}
      {renderAfter && renderAfter()}
      {typeof list.ls === "function" && list.ls()}
    </div>
  );
}

export default MapElementToScreen;
