import React, { Fragment, useState } from "react";
import SimpleAccordion from "../SimpleAccordion/SimpleAccordion";

import SectionA from "../SectionA/SectionA";
import SectionB from "../SectionB/SectionB";
import SectionC from "../SectionC/SectionC";
import SectionD from "../SectionD/SectionD";
import AdvancedDirectives from "../../Json_Templates/Healthcare/Step2/AdvancedDirectives.json";

const MultipleChoice = ({ items, overlay }) => {
  const letters = ["A", "B", "C", "D"];

  const [expandedStates, setExpandedStates] = useState(
    items.map(() => false) // Initialize all to false
  );
  const handleAccordionClick = (index) => {
    const newStates = expandedStates.map((state, idx) =>
      idx === index ? !state : state
    );
    setExpandedStates(newStates);
  };

  return (
    <div>
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            <SimpleAccordion
              item={item}
              index={index}
              plankColor={"#FAF7DC"}
              plankFont={"#4A4A4A"}
              expandedPlankColor={"#FAF7DC"}
              expanded={expandedStates[index]}
              title={`${letters[index]}. ${item} `}
              arrowColor="#188781"
              onClick={handleAccordionClick}
              style={{ borderBottom: "1px solid #EAEBEB" }}
            >
              <SectionA data={overlay[index]} />
              {index === 2 && <SectionD data={AdvancedDirectives} />}
            </SimpleAccordion>
          </Fragment>
        );
      })}
    </div>
  );
};

export default MultipleChoice;
