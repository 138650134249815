import React from "react";
import SectionContainer from "../SectionContainer/SectionContainer";
import { renderComponents } from "../../utils/json-content-renderer";
import AdvancedDirectives from "../../Json_Templates/Healthcare/Step2/AdvancedDirectives.json";

interface SectionCProps {
  data: any;
}

const SectionC: React.FC<SectionCProps> = ({ data }: any) => {
  const { hasCheckedLibrary, isLibraryChecked } = data;

  const advancedDirectivesWithLibrary = {
    ...AdvancedDirectives,
    hasCheckedLibrary,
    isLibraryChecked,
  };

  return (
    <>
      <SectionContainer backgroundColor={data.backgroundColor} paddingTop={25}>
        {/*@ts-ignore */}
        {renderComponents(data)}
        {/*@ts-ignore */}
        {renderComponents(advancedDirectivesWithLibrary)}
      </SectionContainer>
    </>
  );
};

export default SectionC;
