import api from "../utils/api";

export function createProfile(relationship: string, name: string) {
  return api.post(`${appSettings.apiBaseUrl}/api/user/profiles`, {
    relationship,
    name,
  });
}

export function putProfileOnboarding(profileId: string, choices: []) {
  return api.put(
    `${appSettings.apiBaseUrl}/api/user/profiles/${profileId}/onboarding`,
    choices
  );
}
export function putProfile(profileId: string, choices: []) {
  return api.put(
    `${appSettings.apiBaseUrl}/api/user/profiles/${profileId}/choices`,
    choices
  );
}

export function updateProfile(profileId: string, profile: {}) {
  return api.put(
    `${appSettings.apiBaseUrl}/api/user/profiles/${profileId}`,
    profile
  );
}

export function getProfile() {
  return api.get(`${appSettings.apiBaseUrl}/api/user/profiles`);
}


export function setContactMode(ids: string[]) {
  return api.post(`${appSettings.apiBaseUrl}/api/sendcontact/${ids}`)
}

export function setContactsMode(ids: string[]) {
  return api.post(`${appSettings.apiBaseUrl}/api/sendcontacts/${ids}`)
}