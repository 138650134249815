import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Button from "../../../components/Button/Button";
import Container from "../../../components/Container/Container";

import Verbiage from "../../../Json_Templates/Conversations/Onboarding/Onboarding-step1.json";
import { History, LocationState } from "history";
import Header from "../../../components/Header/Header";
import ConversationTitle from "../../../components/ConversationTitle/ConversationTitle";

function ConversationOnboardingStep1({
  history,
}: {
  history: History<LocationState>;
}) {
  const useStyles = makeStyles({
    conversationText: {
      fontFamily: "Poppins",
      fontWeight: 600,
      textAlign: "left",
      fontSize: 13,
      letterSpacing: 0.09,
      marginTop: 32,
      textTransform: "uppercase",
      color: "#1D918B",
    },
    header: {
      fontFamily: "Poppins",
      fontSize: 15,
      color: "#146E6C",
      marginTop: 16,
      marginBottom: 9,
      fontWeight: 600,
    },
    paragraph: {
      font: "normal normal 300 13px/20px Poppins",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
    },
  });
  const classes = useStyles();

  return (
    <div>
      <Container
        style={{ containerBackground: "#faf7dc" }}
        isFixed={false}
        isConversation
        //@ts-ignore
      >
        <Header showProgressBar progressBarStatus={10} />
        <Box p={1} height="100%" marginTop={10} marginLeft={1} marginRight={1}>
          <Box marginBottom={2}>
            <ConversationTitle title={Verbiage.title} history={history} />
          </Box>

          {Verbiage.content.conversations.map((conversation) => {
            return (
              <Typography variant="h6" className={classes.paragraph}>
                <p>{conversation}</p>
              </Typography>
            );
          })}
          <Box
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            display={"flex"}
          >
            <Button
              title={"Next"}
              type="button"
              onClick={() => history.push(Verbiage.buttonLink)}
              buttonStyles={{ zIndex: 1 }}
            />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default ConversationOnboardingStep1;
