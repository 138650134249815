import React from "react";
import MapElementToScreen from "../../../../components/MapElementToScreen/MapElementToScreen";
import * as Render from "../../../../components/RenderMappedJSONContent/RenderMappedJSONContent";

import styles from "../../Library.module.scss";

function AccordionContent({
  paragraph,
  title,
  subTitle,
  paragraphList,
  titleParagraph,
  titleParagraphList,
  titleParagraphCount,
}) {
  return (
    <MapElementToScreen
      componentClass={styles.contentContainer}
      renderBody={() => {
        return <p className={styles.containerOption}>{title}</p>;
      }}
      componentTitle={subTitle}
      componentTitleClass={styles.containerTitle}
      paragraph={typeof paragraph === "string" && paragraph}
      paragraphClass={
        typeof paragraph === "string" ? styles.paragraphStyle : {}
      }
      renderFirst={() => {
        return (
          <>
            {paragraph && <Render.Paragraph paragraph={paragraph} />}

            <Render.TitleParagraphList item={paragraphList} />

            <Render.NestedList item={paragraphList} />

            <Render.TitleParagraphList item={titleParagraphList} />

            {titleParagraphCount && titleParagraphCount.title && (
              <Render.Title title={titleParagraphCount.title} />
            )}
            {titleParagraphCount && titleParagraphCount.paragraph && (
              <Render.Paragraph paragraph={titleParagraphCount.paragraph} />
            )}

            {titleParagraphCount &&
              titleParagraphCount.orderedParagraphs &&
              titleParagraphCount.orderedParagraphs.map((item, index) => {
                return (
                  <>
                    <Render.TitleParagraphList
                      item={item}
                      titleNumber={index + 1}
                      paragraphNumber={index + 1}
                    />
                  </>
                );
              })}

            {titleParagraph &&
              titleParagraph.map((item, index) => {
                return (
                  <Render.TitleParagraph item={item} key={`tpt-${index}`} />
                );
              })}
          </>
        );
      }}
    />
  );
}

export default AccordionContent;
