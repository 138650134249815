import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import styles from "./Accordion.module.scss";

interface AccordionProps {
  items?: { [key: string]: string };
  renderBody: (
    a?: string | { [key: string]: string },
    b?: number
  ) => JSX.Element;
  item?: string;
  plankColor: string;
  plankFont?: string;
  expandedPlankColor: string;
  onClick?: (e: string) => void;
  paragraphs: string[];
  title?: string;
  arrowColor?: string;
  overFlow?: boolean;
  defaultExpanded?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  renderBody,
  item,
  plankColor = "#1D918B",
  plankFont = "#fff",
  expandedPlankColor = "#1D918B",
  onClick,
  paragraphs,
  title,
  arrowColor,
  overFlow = false,
  defaultExpanded = true,
}): any => {
  const ExpansionPanel = withStyles({
    root: {
      backgroundColor: "#FAF7DC",
      boxShadow: "none",
      overflow: overFlow ? "visible" : "hidden",
      margin: "0 !important",
      "&:not(:last-child)": {},
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "0",
        backgroundColor: "#FAF7DC",
      },
    },
  })(MuiExpansionPanel);
  const ExpansionPanelSummary = withStyles({
    root: {
      minHeight: 44,
      backgroundColor: plankColor,
      color: plankFont,
      fontSize: 13,
      borderBottom: "1px solid #3C3C434A",
      "&$expanded": {
        minHeight: 44,
      },
    },
    content: {
      fontFamily: "Poppins",
      margin: "4px 0",
      fontSize: "13px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&$expanded": {
        margin: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    expanded: {
      backgroundColor: expandedPlankColor,
      margin: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      root: {
        margin: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  })(MuiExpansionPanelSummary);

  const [expanded, setExpanded] = useState(false);

  if (paragraphs) {
    return (
      <>
        <ExpansionPanel
          square
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
          defaultExpanded={defaultExpanded}
        >
          <ExpansionPanelSummary aria-controls="panel1d-content">
            <div className={styles.summaryWrapper}>
              <Typography className={styles.title}>{title}</Typography>
              <div className={styles.arrowWrapper}>
                {expanded ? (
                  <KeyboardArrowRight
                    className={styles.arrow}
                    style={arrowColor ? { color: arrowColor } : undefined}
                  />
                ) : (
                  <KeyboardArrowDown
                    className={styles.arrow}
                    style={arrowColor ? { color: arrowColor } : undefined}
                  />
                )}
              </div>
            </div>
          </ExpansionPanelSummary>
          {renderBody && renderBody()}
        </ExpansionPanel>
      </>
    );
  }
  if (typeof items === "object") {
    return (
      <>
        {Object.keys(items).map((diagnosis, index) => {
          return (
            <ExpansionPanel
              square
              key={index}
              defaultExpanded={defaultExpanded}
            >
              <ExpansionPanelSummary aria-controls="panel1d-content">
                {diagnosis}
              </ExpansionPanelSummary>
              {renderBody && renderBody(items[diagnosis], index)}
            </ExpansionPanel>
          );
        })}
      </>
    );
  }
  if (typeof item === "string") {
    return (
      <>
        <ExpansionPanel square defaultExpanded={defaultExpanded}>
          <ExpansionPanelSummary
            aria-controls="panel1d-content"
            onClick={() => {
              onClick && onClick(item);
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {item}
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {expanded ? (
                <KeyboardArrowDown
                  className={styles.arrow}
                  style={arrowColor ? { color: arrowColor } : undefined}
                />
              ) : (
                <KeyboardArrowRight
                  className={styles.arrow}
                  style={arrowColor ? { color: arrowColor } : undefined}
                />
              )}
            </div>
          </ExpansionPanelSummary>
          {renderBody && renderBody(items)}
        </ExpansionPanel>
      </>
    );
  } else return null;
};
export default Accordion;
