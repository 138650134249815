import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../../components/Container/Container";
import Header from "../../../components/Header/Header";
import ConversationsJson from "../../../Json_Templates/ConversationsWithDoctor/ConversationsWithDoctorStep4.json";
import { Typography } from "@material-ui/core";

import { useStyles } from ".././styles/conversation-styles";

import Button from "../../../components/Button/Button";
import Accordion from "../../../components/Accordion/Accordion";

export default function ConversationsWithDoctorStep4() {
  const classes = useStyles();

  return (
    <Box style={{ backgroundColor: "#FAF7DC", height: "130vh" }}>
      <div className={classes.root}>
        <Header showProgressBar isSticky />
        <div className={classes.step4Wrapper}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "15px",
              fontWeight: 600,
              lineHeight: "31px",
              letterSpacing: "0.09px",
              textAlign: "left",
              color: "#000000",
              textTransform: "uppercase",
              opacity: 1,
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            {ConversationsJson.title}
          </Typography>
          <Container
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
            }}
            containerStyles={{
              position: "static",
              width: "100%",
            }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              margin={1}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
            >
              {ConversationsJson.content.titleParagraph.map((conversation) => {
                return (
                  <>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: 600,
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#188781",
                        opacity: 1,
                        marginTop: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      {conversation.title}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#4A4A4A",
                        opacity: 1,
                      }}
                    >
                      {conversation.paragraphs.map((paragraph) => {
                        return <p>{paragraph}</p>;
                      })}
                    </Typography>
                  </>
                );
              })}
            </Box>
          </Container>
          <Container
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
            }}
            containerStyles={{
              position: "static",
              width: "100%",
              marginTop: "18px",
              marginBottom: "32px",
            }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              margin={1}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
            >
              {ConversationsJson.secondContent.titleParagraph.map(
                (conversation) => {
                  return (
                    <>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontWeight: 600,
                          letterSpacing: "0px",
                          textAlign: "left",
                          color: "#188781",
                          opacity: 1,
                          marginTop: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {conversation.title}
                      </Typography>

                      {conversation.paragraphs.map((paragraph, index) => {
                        return (
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "italic",
                              fontSize: "13px",
                              lineHeight: "17px",
                              letterSpacing: "-0.3px",
                              color: "#4A4A4A",
                              textAlign: "left",
                              opacity: 1,
                              paddingBottom: "5px",
                              borderBottom:
                                conversation.paragraphs.length - 1 === index
                                  ? 0
                                  : "1px solid #D9D9D9",
                              margin: "12px 0 12px 0px",
                            }}
                          >
                            {paragraph}
                          </Typography>
                        );
                      })}
                    </>
                  );
                }
              )}
            </Box>
          </Container>
          <Accordion
            title="Doctor Conversation Summary"
            onClick={() => {}}
            plankColor="#188781"
            plankFont="#FFFFFF"
            expandedPlankColor="#188781"
            paragraphs={["test"]}
            arrowColor="#ffffff"
            overFlow
            renderBody={() => {
              return (
                <Box
                  style={{
                    backgroundColor: "#ffffff",
                    minHeight: "350px",
                    display: "flex",
                  }}
                >
                  <Box style={{ width: "90%", margin: "0 auto" }}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        color: "#1D918B",
                        textAlign: "left",
                        opacity: 1,
                        marginBottom: "5px",
                        paddingTop: "12px",
                      }}
                    >
                      {ConversationsJson.Accordion.title}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        color: "#4A4A4A",
                        textAlign: "left",
                        opacity: 1,
                        marginTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      {ConversationsJson.Accordion.paragraphs.map(
                        (paragrah) => {
                          return <p>{paragrah}</p>;
                        }
                      )}
                    </Typography>
                  </Box>
                </Box>
              );
            }}
          />
          <Box
            display={"flex"}
            justifyContent="center"
            width={"100%"}
            marginTop={5}
            marginBottom={5}
          >
            <Button title="Next" type="button" />
          </Box>
        </div>
      </div>
    </Box>
  );
}
