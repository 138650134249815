import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Button from "../../../components/Button/Button";
import Container from "../../../components/Container/Container";

import Header from "../../../components/Header/Header";

import Verbiage from "../../../Json_Templates/ConversationsWithDoctor/ConversationsWithDoctorStep0.json";

function ConversationWithDoctorStep0({ history }: { history: any }) {
  const useStyles = makeStyles({
    conversationText: {
      fontFamily: "Poppins",
      fontWeight: 600,
      textAlign: "left",
      fontSize: 15,
      letterSpacing: 0.09,
      marginTop: 32,
      textTransform: "uppercase",
    },
    header: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "31px",
      letterSpacing: "0.09px",
      color: "#000000",
      textAlign: "left",
      textTransform: "uppercase",
      opacity: 1,
      marginBottom: 10,
    },
    paragraph: {
      font: "normal normal 300 13px/20px Poppins",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
    },
    list: {
      font: "normal normal medium 13px/20px Poppins",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
    },
  });
  const classes = useStyles();

  return (
    //@ts-ignore
    <Container
      style={{ containerBackground: "#faf7dc" }}
      isFixed={false}
      containerStyles={{
        width: "100%",
        position: "static",
      }}
    >
      <Header showProgressBar progressBarStatus={90} />
      <Box marginTop={10} height="100%" margin={2}>
        <Typography
          variant="h6"
          className={classes.header}
          style={{ color: "black" }}
        >
          {Verbiage.content.description}
        </Typography>

        {Verbiage.content.conversations.map((conversation) => {
          return (
            <Typography variant="h6" className={classes.paragraph}>
              <p>{conversation}</p>
            </Typography>
          );
        })}
        {Verbiage.content.list.map((list, index) => {
          return (
            <Typography variant="h6" className={classes.paragraph}>
              {index === 0 && (
                <p style={{ color: "#146E6C", fontWeight: 600 }}>
                  These conversations include:
                </p>
              )}
              <li>{list}</li>
            </Typography>
          );
        })}

        <Box
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          display={"flex"}
          margin={5}
        >
          <Button
            title={"Next"}
            type="button"
            onClick={() => history.push("/Conversations/WithDoctor")}
            buttonStyles={{ zIndex: 1, marginBottom: 20 }}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default ConversationWithDoctorStep0;
