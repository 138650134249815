import {
  HealthCareStepThreeJSON,
  HealthCareStepTwoJSON,
} from "../Json_Templates/Healthcare/healthcare";
import { removeWhiteSpaceUpperCaseWord } from "../utils/string-util";

const mappingJSONStepThree = {
  ...HealthCareStepThreeJSON,
};
const mappingJSONStepTwo = {
  ...HealthCareStepTwoJSON,
};

export const getContent = (selectedDiagnosis, userData) => {
  let checkBoxItems = [];
  const mappedSliderValues = [];
  if (!userData.isLoading) {
    const healthcareChoices = userData.profileChoices.filter(
      (element) => element.category === "healthcare"
    );
    const sliderValues = userData.profileChoices.filter(
      (element) => element.category === "healthcare" && element.value > 9
    );

    mappingJSONStepTwo[selectedDiagnosis].forEach((element) => {
      sliderValues.forEach((sliderValue, index) => {
        if (sliderValue.choiceId === element.sliderChoiceId) {
          mappedSliderValues.push({
            value: sliderValue.value || 0,
            affliction: selectedDiagnosis,
            diagnosis: selectedDiagnosis,
          });
        }
      });

      if (element.checkBoxQuestions) {
        element.checkBoxQuestions.questions.forEach((checkBoxQuestion) => {
          healthcareChoices.forEach((healthcareChoice, index) => {
            checkBoxQuestion.choiceId === healthcareChoice.choiceId &&
              healthcareChoice.value === 1 &&
              checkBoxItems.push(
                checkBoxQuestion.response.replace(/[^a-zA-Z ]/g, "")
              );
          });
        });
      }
    });

    const renderContent = checkBoxItems.reduce((arr, title) => {
      arr.push(mappingJSONStepThree[selectedDiagnosis][title]);
      return arr;
    }, []);

    mappedSliderValues[0] &&
      mappedSliderValues[0].value === 100 &&
      renderContent.push(
        mappingJSONStepThree[selectedDiagnosis]["Advanced Directives"]
      );

    return [renderContent, mappedSliderValues];
  }
};

export const getMyPlans = (category, profileChoices) => {
  let plans = {};
  const jsonPlans = Object.keys(mappingJSONStepTwo).map((plans) => {
    return mappingJSONStepTwo[plans];
  });
  const choicesByCategories = profileChoices.filter((profileChoice, index) => {
    if (category === profileChoice.category) {
      return profileChoice;
    }
  });

  const healthcarePlansSectionC = jsonPlans.map((jsonPlans) => {
    const healthcarePlan = jsonPlans
      .map((jsonPlan) => {
        if (jsonPlan.component === "sectionC") {
          return {
            checkBoxQuestion: jsonPlan.checkBoxQuestions.questions,
            name: removeWhiteSpaceUpperCaseWord(jsonPlans[0].OptionTitle),
          };
        }
      })
      .filter((x) => x);
    return healthcarePlan;
  });

  choicesByCategories.forEach((choiceByCategory) => {
    healthcarePlansSectionC.forEach((plan) => {
      plan.forEach((item) => {
        item.checkBoxQuestion.find((checkBoxQuestion) => {
          if (checkBoxQuestion.choiceId === choiceByCategory.choiceId) {
            plans[plan[0].name] = mappingJSONStepTwo[plan[0].name];
          }
        });
      });
    });
  });
  const result = Object.values(plans).map((plan) => plan[0]);
  return result;
};
