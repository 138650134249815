import React from "react";

import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import AuthProvider from "../Context/Auth-Provider";

import CreateAccount from "../Views/Account/CreateAccount";
import SignIn from "../Views/Account/SignIn";
import ResetPassword from "../Views/Account/ResetPassword";

import { Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { AdminRoute } from "../components/AdminRoute/AdminRoute";

import HomeScreen from "../Views/Homescreen/HomeScreen";
import Library from "../Views/Library/Library";
import MyWellness from "../Views/MyWellness/MyWellness";


import Dashboard from "../Views/Dashboard/Dashboard";

import OnBoarding from "../Views/OnBoarding/OnBoardingStart";
import OnBoardingStepOne from "../Views/OnBoarding/OnBoardingStepOne";
import OnBoardingStepTwo from "../Views/OnBoarding/OnBoardingStepTwo";
import OnBoardingStepThree from "../Views/OnBoarding/OnBoardingStepThree";
import OnBoardingStepFour from "../Views/OnBoarding/OnBoardingStepFour";
import OnBoardingStepFive from "../Views/OnBoarding/OnBoardingStepFive";

import HomecareStepOne from "../Views/Homecare/Homecare-step-one";
import HomecareStepTwo from "../Views/Homecare/Homecare-step-two";
import HomecareStepThree from "../Views/Homecare/Homecare-step-three";

import HealthcareStepOne from "../Views/Healthcare/Healthcare-step-one";
import HealthcareStepTwo from "../Views/Healthcare/Healthcare-step-two";
import HealthcareStepTwoDiagnosis from "../Views/Healthcare/Healthcare-step-one-diagnosis";
import HealthcareStepThree from "../Views/Healthcare/Healthcare-step-three";

import InsuranceStepOne from "../Views/Insurance/Insurance-step-one";
import InsuranceStepTwo from "../Views/Insurance/Insurance-step-two";
import InsuranceStepThree from "../Views/Insurance/Insurance-step-three";
import MyPlans from "../Views/MyPlans/MyPlans";

import ServicesAndProviders from "../Views/ServicesAndProviders/ServicesAndProviders";
import ASASMenu from "../Views/ASASMenu/ASASMenu";

import Conversations from "../Views/Conversations/Conversations";
import ConversationOnboardingStepOne from "../Views/Conversations/Onboarding/OnboardingStep1";
import ConversationOnboardingStep2 from "../Views/Conversations/Onboarding/OnboardingStep2";
import ConversationOnboardingStep3 from "../Views/Conversations/Onboarding/OnboardingStep3";
import ConversationOnboardingStep4 from "../Views/Conversations/Onboarding/OnboardingStep4";
import ConversationStep2 from "../Views/Conversations/ConversationsStep2";
import ConversationStep3 from "../Views/Conversations/ConversationsStep3";
import ConversationsStep4 from "../Views/Conversations/ConversationsStep4";
import ConversationsStep5 from "../Views/Conversations/ConversationsStep5";
import ConversationsStep6 from "../Views/Conversations/ConversationsStep6";
import ConversationsStep7 from "../Views/Conversations/ConversationsStep7";
import ConversationsStep9 from "../Views/Conversations/ConversationsStep9";
import ConversationsStep10 from "../Views/Conversations/ConversationsStep10";

import ConversationWithDoctorStep0 from "../Views/Conversations/ConversationsWithDoctor/ConversationsWithDoctorStep0";
import ConversationsWithDoctor from "../Views/Conversations/ConversationsWithDoctor/ConversationsWithDoctor";
import ConversationsWithDoctorStep2 from "../Views/Conversations/ConversationsWithDoctor/ConversationsWithDoctorStep2";
import ConversationsWithDoctorStep2a from "../Views/Conversations/ConversationsWithDoctor/ConversationWithDoctorStep2a";
import ConversationsWithDoctorStep2b from "../Views/Conversations/ConversationsWithDoctor/ConversationWithDoctorStep2b";
import ConversationsWithDoctorStep2c from "../Views/Conversations/ConversationsWithDoctor/ConversationWithDoctorStep2c";
import ConversationsWithDoctorStep3 from "../Views/Conversations/ConversationsWithDoctor/ConversationsWithDoctorStep3";
import ConversationsWithDoctorStep4 from "../Views/Conversations/ConversationsWithDoctor/ConversationsWithDoctorStep4";

import ConversationsWithFamilyStep0 from "../Views/Conversations/ConversationsWithFamily/ConversationsWithFamilyStep0";
import ConversationsWithFamilyStep1 from "../Views/Conversations/ConversationsWithFamily/ConversationsWithFamilyStep1";
import ConversationsWithFamilyStep3 from "../Views/Conversations/ConversationsWithFamily/ConversationsWithFamilyStep3";
import ConversationsWithFamilyStep3a from "../Views/Conversations/ConversationsWithFamily/ConversationsWithFamilyStep3a";
import ConversationsWithFamilyStep3b from "../Views/Conversations/ConversationsWithFamily/ConversationsWithFamilyStep3b";
import ConversationsWithFamilyStep3c from "../Views/Conversations/ConversationsWithFamily/ConversationsWithFamilyStep3c";
import RoutingError from "../components/RoutingError/RoutingError";
//@ts-ignore

import ComfortCare from "../Views/ComfortCare/ComfortCare";
import AccountSetup from "../Views/Account/AccountSetup";
import AccountSetupDetails from "../Views/Account/AccountSetupDetails";
import AccountUserDetails from "../Views/Account/AccountUserDetails";
import Contact from "../Views/Contact/Contact";

import TermsOfService from "../Views/TermsOfService/TermsOfService";

export const Routes = () => {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <Switch>
          <Route path="/resetpassword" component={ResetPassword} />
          <Route exact path="/" component={SignIn} />
          <Route exact path="/Account/SignIn" component={SignIn} />
          <Route exact path="/Account/Create" component={CreateAccount} />
          <Route exact path="/Account/AccountSetup" component={AccountSetup} />
          <Route exact path="/Account/AccountUserDetails" component={AccountUserDetails} />
          <Route exact path="/Account/AccountSetupDetails" component={AccountSetupDetails} />
          <Route exact path="/OnBoarding" component={OnBoarding} />
          <Route exact path="/OnBoarding/Step1" component={OnBoardingStepOne} />
          <Route
            exact
            path="/OnBoarding/Step2"
            component={OnBoardingStepTwo}
          />

          <ProtectedRoute exact path="/HomeScreen" component={HomeScreen} />
          <ProtectedRoute exact path="/Library" component={Library} />
          <ProtectedRoute path="/Library/:tabName" component={Library} />


          <ProtectedRoute exact path="/MyWellness" component={MyWellness} />
          <ProtectedRoute
            exact
            path="/Conversations/Onboarding/Step1"
            component={ConversationOnboardingStepOne}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Onboarding/Step2"
            component={ConversationOnboardingStep2}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Onboarding/Step3"
            component={ConversationOnboardingStep3}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Onboarding/Step4"
            component={ConversationOnboardingStep4}
          />
          <ProtectedRoute exact path="/MyPlans" component={MyPlans} />
          <ProtectedRoute
            exact
            path="/homecareHomeScreen"
            component={HomeScreen}
          />
          <ProtectedRoute exact path="/Conversations" component={HomeScreen} />
          <ProtectedRoute
            exact
            path="/Conversations/Step1"
            component={Conversations}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step2"
            component={ConversationStep2}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step3"
            component={ConversationStep3}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step4"
            component={ConversationsStep4}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step5"
            component={ConversationsStep5}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step6"
            component={ConversationsStep6}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step7"
            component={ConversationsStep7}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step9"
            component={ConversationsStep9}
          />
          <ProtectedRoute
            exact
            path="/Conversations/Step10"
            component={ConversationsStep10}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor/Step0"
            component={ConversationWithDoctorStep0}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor"
            component={ConversationsWithDoctor}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor/Step2"
            component={ConversationsWithDoctorStep2}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor/Step2a"
            component={ConversationsWithDoctorStep2a}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor/Step2b"
            component={ConversationsWithDoctorStep2b}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor/Step2c"
            component={ConversationsWithDoctorStep2c}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor/Step3"
            component={ConversationsWithDoctorStep3}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithDoctor/Step4"
            component={ConversationsWithDoctorStep4}
          />

          <ProtectedRoute
            exact
            path="/Conversations/WithFamily/Step0"
            component={ConversationsWithFamilyStep0}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithFamily/Step1"
            component={ConversationsWithFamilyStep1}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithFamily/Step3a"
            component={ConversationsWithFamilyStep3a}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithFamily/Step3"
            component={ConversationsWithFamilyStep3}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithFamily/Step3b"
            component={ConversationsWithFamilyStep3b}
          />
          <ProtectedRoute
            exact
            path="/Conversations/WithFamily/Step3c"
            component={ConversationsWithFamilyStep3c}
          />
          <ProtectedRoute
            exact
            path="/ComfortCare/Step1"
            component={ComfortCare}
          />

          <ProtectedRoute
            exact
            path="/Contact"
            component={Contact}
          />

          <ProtectedRoute exact path="/Chat" component={Contact} />
          <ProtectedRoute path="/Chat/:chatId" component={Contact} />
          <ProtectedRoute exact path="/TermsOfService" component={TermsOfService} />
          <AdminRoute
            exact
            path="/Dashboard"
            component={Dashboard}
            redirect={"/Chat"}
          />


          <ProtectedRoute
            exact
            path="/OnBoarding/Step3"
            component={OnBoardingStepThree}
          />
          <ProtectedRoute
            exact
            path="/OnBoarding/Step4"
            component={OnBoardingStepFour}
          />
          <ProtectedRoute
            exact
            path="/OnBoarding/Step5"
            component={OnBoardingStepFive}
          />
          <ProtectedRoute
            exact
            path="/Homecare/Step1"
            component={HomecareStepOne}
          />
          <ProtectedRoute
            exact
            path="/Homecare/Step2"
            component={HomecareStepTwo}
          />
          <ProtectedRoute
            exact
            path="/Homecare/Step3"
            component={HomecareStepThree}
          />

          <ProtectedRoute
            exact
            path="/Healthcare/Step1"
            component={HealthcareStepOne}
          />
          <ProtectedRoute
            exact
            path="/Healthcare/Step1/Diagnosis"
            component={HealthcareStepTwoDiagnosis}
          />
          <ProtectedRoute
            exact
            path="/Healthcare/Step2"
            component={HealthcareStepTwo}
          />
          <ProtectedRoute
            exact
            path="/Healthcare/Step3"
            component={HealthcareStepThree}
          />

          <ProtectedRoute
            exact
            path="/Insurance/Step1"
            component={InsuranceStepOne}
          />
          <ProtectedRoute
            exact
            path="/Insurance/Step2"
            component={InsuranceStepTwo}
          />
          <ProtectedRoute
            exact
            path="/Insurance/Step3"
            component={InsuranceStepThree}
          />
          <ProtectedRoute
            exact
            path="/ServicesAndProviders"
            component={ServicesAndProviders}
          />
          <ProtectedRoute
            exact
            path="/About"
            component={ASASMenu}
            view="about"
          />
          <ProtectedRoute
            exact
            path="/Settings"
            component={ASASMenu}
            view="settings"
          />
          <ProtectedRoute
            exact
            path="/Account"
            component={ASASMenu}
            view="account"
          />
          <ProtectedRoute
            exact
            path="/AdminAccount"
            component={ASASMenu}
            view="admin"
          />
          <ProtectedRoute
            exact
            path="/Support"
            component={ASASMenu}
            view="support"
          />

          <Route
            path="*"
            render={(props) => (
              <RoutingError {...props} message="This page doesn't exist" />
            )}
          />
        </Switch>
      </ErrorBoundary>
    </AuthProvider>
  );
};
