import React, { useContext, useState } from "react";
import styles from "../../Views/Library/Library.module.scss";

import {
  homecareStep1,
  homecareStep3,
} from "../../Json_Templates/Homecare/combinedHomecareImports";
import {
  insuranceStep1,
  insuranceStep3,
} from "../../Json_Templates/Insurance/combinedInsuranceImports";
import { UserDataContext } from "../../Context/Auth-Provider";
import ArchiveAccordion from "../ArchiveAccordion/ArchiveAccordion";
import { HealthCareStepTwoJSON } from "../../Json_Templates/Healthcare/healthcare";
import StepThreeModal from "../../components/StepThreeModal/StepThreeModal";
import {
  removeWhiteSpaceUpperCaseWord,
  getHealthcareIconCategory,
} from "../../utils/helpers.util";
import { getContent, getMyPlans } from "../../utils/healthcare-util";
import { SavedChoices } from "../../Types/saved-choices-types";
import { ProfileChoices } from "../../Types/saved-choices-types";
import { filterArrayOfObject } from "../../utils/filter";
import { getProfile } from "../../services/profile-service";

interface MyPlansContentProps {
  tabIndex: number;
  tabName: string;
  userId?: string;
}
interface JSONPlans {
  optionTitle?: string;
  title: string;
  list: string[];
  paragraphs: string[];
  renderAddToLibrary: boolean;
  choiceId: string;
  key: string | string[];
}
interface MappingJSON {
  choices: {
    title: string;
    question: string;
    choiceId: string;
  }[];
  healthcare?: {
    [key: string]: {
      optionTitle: string;
      title: string;
      paragraphs: string[];
      list: string[];
      renderAddToLibrary: boolean;
      choiceId: string;
    };
  };
}

const mappingJSON: { [key: string]: any } = {
  homecare: homecareStep1,
  insurance: insuranceStep1,
  healthcare: HealthCareStepTwoJSON,
};

const mappingJSONStepThree: { [key: string]: any } = {
  homecare: homecareStep3,
  insurance: insuranceStep3,
};
interface MYPlansVerbiage {
  title: string;
  verbiage: string;
  tab: string;
  userId?: string;
}
interface UserSelectedChoices {
  [key: string]: any;
}
let savedChoices: SavedChoices = [];
const MyPlansContent: React.FC<MyPlansContentProps> = ({
  tabIndex,
  userId,
}) => {
  let userSelectedChoices: Array<UserSelectedChoices> = [];

  const [selectedDiagnosis, setSelectedDiagnosis] = useState<string>();

  const myPlansVerbiage: { [key: number]: MYPlansVerbiage } = {
    0: {
      title: "Homecare",
      verbiage:
        "See your homecare plan for each of your loved one's situations.",
      tab: "homecare",
    },
    1: {
      title: "Healthcare",
      verbiage:
        "See your healthcare plan for each of your loved one's conditions below.",
      tab: "healthcares",
    },
    2: {
      title: "Insurance",
      verbiage:
        "See your insurance plan for each of your loved one's situations below.",
      tab: "insurance",
    },
  };

  const userSelectedPlan: string = myPlansVerbiage[tabIndex].tab;

  const userProfile = useContext(UserDataContext);

  let profileChoices =
    !userProfile!.profile.isLoading && userProfile!.profile.profileChoices;

  const getSignedInUserProfile = (profileChoices: SavedChoices) => {
    savedChoices = profileChoices.filter(
      (profileChoice: ProfileChoices, index: number) => {
        if (
          profileChoice.category === myPlansVerbiage[tabIndex].tab &&
          myPlansVerbiage[tabIndex].tab === "healthcare"
        ) {
          return profileChoice;
        }
        if (
          profileChoice.category === myPlansVerbiage[tabIndex].tab &&
          profileChoice.value > 10
        ) {
          return profileChoice;
        }
      }
    );
    return savedChoices;
  };

  function getAnotherUsersProfile() {
    const response = userId && getProfile();
    return response;
  }

  if (profileChoices) {
    if (!userId) {
      savedChoices = getSignedInUserProfile(profileChoices);
    }
    userId &&
      //@ts-ignore
      getAnotherUsersProfile().then(
        //@ts-ignore
        (response) => (savedChoices = response.data[0].choices)
      );

    const flatten = (obj: MappingJSON) => {
      return Object.values(obj);
    };

    const findJSONForHomecareAndInsurance = (choiceId: string) => {
      const stepOneJSON = mappingJSON[myPlansVerbiage[tabIndex].tab];

      const plan = stepOneJSON.choices.find(
        (
          element: {
            choiceId: string;
          },
          index: number
        ) => element.choiceId === choiceId
      );
      let stepThreeJSONContent;
      if (plan) {
        stepThreeJSONContent =
          mappingJSONStepThree[myPlansVerbiage[tabIndex].tab][
            plan.title.split(" ").join("")
          ];

        return stepThreeJSONContent;
      }
    };

    const doesChoiceIdExistInJsonMap = (jsonPlans: JSONPlans[]) => {
      const plans = getMyPlans("healthcare", profileChoices);
      return jsonPlans.map((JsonPlan) => {
        if (JsonPlan.choiceId) {
          savedChoices.forEach((savedChoice) => {
            if (savedChoice.choiceId === JsonPlan.choiceId) {
              if (userSelectedPlan !== "healthcare") {
                const plans = findJSONForHomecareAndInsurance(
                  savedChoice.choiceId
                );

                userSelectedChoices.push(plans);
              } else if (userSelectedPlan === "healthcare") {
                userSelectedChoices = plans;
              }
            }
          });
        }
      });
    };

    const flattenedJSON = flatten(mappingJSON[userSelectedPlan]);

    flattenedJSON.forEach((flattenedJSON) =>
      doesChoiceIdExistInJsonMap(flattenedJSON)
    );
  }

  function renderPlanItems() {
    const healthcarePlans =
      userSelectedPlan === "healthcare" &&
      filterArrayOfObject("OptionTitle", userSelectedChoices);
    const plans =
      userSelectedPlan !== "healthcare" &&
      filterArrayOfObject("title", userSelectedChoices);
    const healthcarePlansContent =
      healthcarePlans &&
      healthcarePlans.map((healthcarePlan) =>
        getContent(
          removeWhiteSpaceUpperCaseWord(
            //@ts-ignore
            healthcarePlan.OptionTitle
          ),
          userProfile!.profile
        )
      );

    return userSelectedPlan === "healthcare"
      ? // @ts-ignore */
        healthcarePlans.map((healthcarePlan, index) => {
          return (
            <ArchiveAccordion
              subtitle={healthcarePlan.OptionTitle}
              OptionTitle={healthcarePlan.OptionTitle}
              onClick={(diagnosis: string) => setSelectedDiagnosis(diagnosis)}
              icon={getHealthcareIconCategory(healthcarePlan.OptionTitle)}
              tabContent={
                selectedDiagnosis && (
                  <StepThreeModal
                    currentStage={
                      // @ts-ignore */
                      healthcarePlansContent[index][1][0] &&
                      // @ts-ignore */
                      healthcarePlansContent[index][1][0].value
                    }
                    // @ts-ignore */
                    content={healthcarePlansContent[index][0]}
                  />
                )
              }
            />
          );
        })
      : // @ts-ignore */
        plans.map((userSelectedChoice: any, index: number) => {
          return (
            // @ts-ignore */
            <ArchiveAccordion
              additionalSubTitlePadding
              title={myPlansVerbiage[tabIndex].title}
              subtitle={userSelectedChoice.title}
              icon={
                userSelectedPlan === "healthcare"
                  ? null
                  : userSelectedChoice.icon
              }
              tabContent={
                // @ts-ignore */
                <StepThreeModal
                  content={userSelectedChoice.pageContent}
                  category={"homecare"}
                  renderTitle={{
                    icon: undefined,
                    title: userSelectedChoice.title,
                  }}
                  displayTitle={false}
                />
              }
            />
          );
        });
  }

  return (
    <div className={styles.container}>
      <div
        style={{
          padding: "5px 0px",
        }}
      >
        <span className={styles.plansTitle}>
          {`${myPlansVerbiage[tabIndex].title} Planning`}
        </span>
        <p className={styles.verbiage}>{myPlansVerbiage[tabIndex].verbiage}</p>
      </div>
      {renderPlanItems()}
    </div>
  );
};

export default MyPlansContent;
