import React from "react";
import PsiLogo from "../../components/PsiLogo/PsiLogo";

const ProvidedByLogo = () => {
  return (
    <div>
      <PsiLogo height={30} isConversation={false} />
    </div>
  );
};

export default ProvidedByLogo;
