import React, { useState } from "react";

const CustomIcon = ({ folder = "", icon, classIcon, style }) => {
  const [src, setSrc] = useState("");
  import(`../../assets/${folder}/${icon}.svg`).then((image) =>
    setSrc(image.default)
  );
  return (
    <img src={src} alt={`icon-${icon}`} style={style} className={classIcon} />
  );
};

export default CustomIcon;
