import React from "react";

import styles from "./OnBoardingStepFive.module.scss";

import Container from "../../components/Container/Container";
import PsiLogo from "../../components/PsiLogo/PsiLogo";
import Button from "../../components/Button/Button";

import howto from "../../assets/_howto.png";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const OnBoardingStepFive = ({ history }) => {
  return (
    <Container
      style={{ containerPadding: "50px", containerBackground: "#faf7dc" }}
    >
      <div className={styles.logoContainer}>
        <PsiLogo height={40} width={130} />
      </div>

      <p className={styles.header}>
        Based on your answers we will now help you figure out:
      </p>

      <div className={styles.stepsContainer}>
        <div className={styles.stepWrap}>
          <span className={styles.step}>STEP 1</span>
          <p className={styles.stepText}>What's going on</p>
        </div>
        <div className={styles.stepWrap}>
          <span className={styles.step}>STEP 2</span>
          <p className={styles.stepText}>Your options</p>
        </div>
        <div className={styles.stepWrap}>
          <span className={styles.step}>STEP 3</span>
          <p className={styles.stepText}>Your plan</p>
        </div>
      </div>
      <div className={styles.howto}>
        <p className={styles.title}>Get to know your homescreen</p>
        <img src={howto} width="100%" />
        <div className={styles.buttonContainer}>
          <Button
            title={"Visit Your Homescreen"}
            onClick={() => {
              recordEvent(
                Events.Categories.onboarding,
                Events.Actions.finish,
                Events.Labels.stepFive
              );
              return history.push("/HomeScreen");
            }}
            width={176}
          />
        </div>
      </div>
    </Container>
  );
};

export default OnBoardingStepFive;
