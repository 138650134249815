import React, { useEffect } from "react";

import styles from "../ArchiveHomeScreen/ArchiveHomeScreen.module.scss";
import { History, LocationState } from "history";

import Icon from "../Icon/Icon";

interface ArchiveHomeScreenProps {
  history: History<LocationState>;
  archiveType: string;
  defaultTab?: number;
  data: {
    "background-image-banner": {
      text: string;
    };
    bold?: string;
    verbiage: string;
    tabs: string[];
  };
  render?: any;
}

type tabName = {
  [key: string]: string;
};

const ArchiveHomeScreen: React.FC<ArchiveHomeScreenProps> = ({
  history,
  archiveType = "Library",
  data,
  render,
  defaultTab = 0,
}) => {
  const open = (tab: string) => {
    switch (tab) {
      case "homecare":
        history.push(`/${archiveType}`, {
          tab: 0,
        });
        break;
      case "healthcare":
        history.push(`/${archiveType}`, {
          tab: 1,
        });
        break;
      case "insurance":
        history.push(`/${archiveType}`, {
          tab: 2,
        });
        break;
      default:
        history.push(`/${archiveType}`, { tab: 0 });
    }
  };

  const tabName: tabName = {
    homecare: "Homecare",
    healthcare: "Healthcare",
    insurance: "Insurance",

    stress: "Stress Reduction",
    physical: "Physical Wellness",
    spiritual: "Mindfulness & Spirituality",
    financial: "Work & Financial",
  };

  const bgImage =
    archiveType === "Library"
      ? "background-image-banner"
      : archiveType === "MyWellness"
      ? "wellness-image-banner"
      : "plans-image-banner";

  const getTabStyle = (tab: string) => {
    switch (tab) {
      case "homecare":
        return { backgroundColor: "#ddd", cursor: "not-allowed"};
      case "insurance":
        return { backgroundColor: "#ddd", cursor: "not-allowed" };
      default:
        return {
          cursor: "pointer",
        };
    }
  };

  return (
    <>
      <div className={styles[bgImage]}>
        <p>{data["background-image-banner"].text}</p>
      </div>
      {render ? (
        render(tabName)
      ) : (
        <div className={styles.categories}>
          <b className={styles.bold}>{data.bold}</b>
          <p>{data.verbiage}</p>
          <div>
            {data.tabs.map((tab, index) => {
              const style = getTabStyle(tab);
              return (
                <span
                  onClick={tab === "healthcare" ? () => open(tab) : undefined}
                  key={index}
                  style={style}
                >
                  <Icon icon={tab} className={styles.classIcon} />
                  {tabName[tab]}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ArchiveHomeScreen;
