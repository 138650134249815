export const fiveMarks = [
  {
    value: 10,
    label: "1",
  },
  {
    value: 30,
    label: "2",
  },
  {
    value: 50,
    label: "3",
  },
  {
    value: 75,
    label: "4",
  },
  {
    value: 100,
    label: "5",
  },
];

export const threeMarks = [
  {
    value: 10,
    label: "Yes",
  },
  {
    value: 50,
    label: "Not sure",
  },
  {
    value: 100,
    label: "No",
  },
];

export const createMarks = (marks) => {
  const createdMarks = marks.map((mark, index) => {
    return {
      label: mark,
      value: index === 0 ? 10 : index * 50,
    };
  });
  return createdMarks;
};
