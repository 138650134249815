import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Step from "../../components/Step/Step";
import ListItem from "../../components/ListItem/ListItem";
import StepTwoModal2 from "../../components/StepTwoModal2/StepTwoModal2";
import Container from "../../components/Container/Container";
import Overlay from "../../components/Overlay/Overlay";
import ProceedButton from "../../components/ProceedButton/ProceedButton";

import { getProfile, putProfile } from "../../services/profile-service";
import { findJsonData, syntaxify } from "../../utils/helpers.util";
import { insuranceStep2 } from "../../Json_Templates/Insurance/combinedInsuranceImports";

import Toastify, {
  successToast,
  errorToast,
} from "../../components/Toastify/Toastify";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const mappingJSON = {
  ...insuranceStep2,
};

const InsuranceStepTwo = ({ history, userData }) => {
  const [profileData, setProfileData] = useState([]);
  const [userProfile, loadProfile] = useState([]);
  useEffect(() => {
    getProfile().then(({ data }) => {
      const userProfile = data.find(
        (user) => user.profileId === userData.profileId
      );
      loadProfile(userProfile.choices);
      setProfileData(userProfile);
    });
  }, []);

  const [isPanelOpen, setPanel] = useState(false);
  const [left, setLeft] = useState({});
  const [insuranceOptions, setInsuranceOptions] = useState(null);
  const [overlay, setOverlay] = useState(mappingJSON["Medicare"]);
  let categories = [];
  let savedOptions = [];
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [insuranceSelection, setInsuranceSelection] = useState([]);

  const getServerData = userProfile.reduce((matches, remote) => {
    remote.choiceId === findJsonData(remote.choiceId, mappingJSON) &&
      matches.push(remote);
    return matches;
  }, []);
  if (getServerData.length > 0) {
    getServerData.forEach((remote) => {
      const category = findJsonData(remote.choiceId, mappingJSON, true);

      savedOptions = [
        ...savedOptions,
        {
          choice: category,
          choiceId: remote.choiceId,
          value: remote.value,
        },
      ];
    });
  }

  const [profileError, catchError] = useState();
  const saveProfile = async () => {
    let response;
    const choices = syntaxify(insuranceSelection, "insurance");

    try {
      response = await putProfile(userData.profileId, choices);
      response.status === 200 && successToast();
    } catch (err) {
      errorToast();
      catchError(err);
    }
  };

  useEffect(() => {
    if (Object.keys(history.location.state.insuranceUserSelection).length > 0) {
      setInsuranceOptions(history.location.state.insuranceUserSelection);
    }
  }, []);

  useEffect(() => {
    if (userProfile.length > 0) {
      setCheckBoxes([...savedOptions]);
    }
    setInsuranceSelection([...savedOptions]);
  }, [userProfile]);

  const getLibraryOptions = (isChecked, option) => {
    const checkboxItem =
      insuranceSelection.length > 0 &&
      insuranceSelection.find((item) => {
        return item.choiceId === option.choiceId;
      });

    if (isChecked && !!checkboxItem) {
      checkboxItem.value = 1;
    } else {
      isChecked
        ? setInsuranceSelection([
            ...insuranceSelection,
            {
              choice: overlay[0].Title,
              choiceId: option.choiceId,
              value: 1,
            },
          ])
        : !!checkboxItem
        ? (checkboxItem.value = 0)
        : setInsuranceSelection([
            ...insuranceSelection,
            {
              choice: overlay[0].Title,
              choiceId: option.choiceId,
              value: 0,
            },
          ]);
    }
    if (isChecked) {
      setCheckBoxes([
        ...checkBoxes,
        {
          choice: overlay[0].Title,
          choiceId: option.choiceId,
          value: 1,
        },
      ]);
    } else {
      setCheckBoxes(
        checkBoxes.filter((item) => item.choiceId !== option.choiceId)
      );
    }
  };

  return (
    <Header showGauge={false} isSticky={false}>
      <Toastify />
      <Container
        setLeft={!isPanelOpen}
        style={left}
        isButtonShown
        isFixed={false}
        wrapperStyles={{
          display: "flex",
          flexDirection: "column",
        }}
        buttonProps={{
          onClick: () => {
            recordEvent(
              Events.Categories.insurance,
              Events.Actions.finish,
              Events.Labels.stepTwo
            );
            history.push("/Insurance/Step3", {
              params: history.location.state.insuranceUserSelection,
              insuranceSelection,
            });
          },
          title: "PROCEED TO STEP 3",
        }}
      >
        <div>
          <Step
            stepCount={"2"}
            label={"Insurance"}
            subTitle={"Insurance"}
            title={"Your Planning Options"}
            iconName={"insurance"}
            verbiage={
              "Based on what you're telling us, let's explore some potential solutions for those areas that need attention:"
            }
          />

          {insuranceOptions &&
            Object.values(insuranceOptions).map((insuranceOption, index) => {
              categories = insuranceSelection.filter(
                (selection) => selection.choice === insuranceOption.title
              );

              const isChecked = categories.filter(
                (category) => category.value === 1
              );

              return (
                <ListItem
                  title={insuranceOption.title}
                  isChecked={isChecked.length > 0}
                  renderIsCheckedIcon={true}
                  onClick={() => {
                    setOverlay(
                      mappingJSON[insuranceOption.title.split(" ").join("")]
                    );
                    setPanel(!isPanelOpen);
                    const off = isPanelOpen ? "0" : "1";
                    setLeft({
                      width: "50%",
                      leftOn: { off },
                    });
                  }}
                  index={`Insurance-${index}`}
                />
              );
            })}
        </div>
      </Container>
      <Overlay in={isPanelOpen}>
        <div>
          {overlay.map((option, index) => {
            const checkValue =
              checkBoxes.length > 0 &&
              checkBoxes.find((saved) => saved.choiceId === option.choiceId);
            return index === 0 ? (
              <StepTwoModal2
                backgroundColor={"#FAF7DC"}
                isHeader
                onClick={() => {
                  setPanel(!isPanelOpen);
                  const off = isPanelOpen ? "0" : "1";
                  setLeft({ width: "50%", leftOn: { off } });
                }}
                {...option}
                key={`InsuranceOption-${index}`}
              />
            ) : (
              <StepTwoModal2
                backgroundColor={index % 2 === 0 ? "#FAF7DC" : "#fff"}
                titleColor={"#1D918B"}
                {...option}
                isLibraryChecked={checkValue && checkValue.value}
                defaultValue={false}
                hasCheckedLibrary={(isChecked) => {
                  getLibraryOptions(isChecked, option);
                }}
                key={`InsuranceOption-${index}`}
                category={"insurance"}
              />
            );
          })}
          <ProceedButton
            title={`CLOSE & SAVE`}
            onClick={() => {
              saveProfile();
              setPanel(!isPanelOpen);
              const off = isPanelOpen ? "0" : "1";
              setLeft({ width: "50%", leftOn: { off } });
            }}
          />
        </div>
      </Overlay>
    </Header>
  );
};

export default InsuranceStepTwo;
