import React from "react";

import styles from "./NavigationalTabs.module.scss";
import { withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LibraryContent from "../../Views/Library/LibraryTabs/TabContent/TabContent";
import MyPlansContent from "../../components/MyPlansContent/MyPlansContent";
import MyWellnessContent from "../../Views/MyWellness/MyWellnessTabs/TabContent/TabContent";

import Icon from "../Icon/Icon";

const sty = {
  root: {
    color: "#1C908A",
    cursor: "pointer",
    height: "50px",
    padding: "12px 0 0 0",
    textTransform: "inherit",
    fontSize: "12.5px",
    fontWeight: "bolder",
    minWidth: "33.33%",
    "&:focus": {
      outline: "none",
    },
  },
  selected: {
    backgroundColor: "#faf7dc",
  },
  wrapper: {
    display: "flex",
    alignItems: "unset",
    flexDirection: "row",
    justifyContent: "center",
    lineHeight: "27px",
  },
  indicator: {
    backgroundColor: "#1C908A",
    height: "4px",
  },
  colorDefault: { boxShadow: "none", height: "50px" },
  labelIcon: { minHeight: "initial" },
};

const getTabStyles = (tab) => {
  if (tab === "homecare" || tab === "insurance") {
    return {
      color: "#A9A9A9",
      cursor: "not-allowed",
      pointerEvents: "unset"
    };
  }
  return {
    cursor: "pointer"
  };
};

const NavigationalTabs = ({
  classes,
  tab,
  data,
  planType = "Library",
  userId = null,
  tabName: navigatedTab
}) => {
  const [activeTab, setActiveTab] = React.useState(tab);



  const tabName = {
    recents: "Recently Added",
    homecare: "Homecare",
    healthcare: "Healthcare",
    insurance: "Insurance",

    stress: "Stress Reduction",
    physical: "Physical Wellness",
    spiritual: "Mindfulness & Spirituality",
    financial: "Work & Financial",
  };


  return (
    <>
      <AppBar
        position="static"
        color="default"
        classes={{ colorDefault: classes.colorDefault }}
        className={styles.tabBar}
      >
        <Tabs
          value={activeTab}
          onChange={(e, value) => {
            setActiveTab(value);
          }}
          classes={{
            indicator: classes.indicator,
          }}
          variant="scrollable"
          scrollButtons="off"
        >
          {data &&
            data.tabs.map((tab, idx) => {
              const style = getTabStyles(tab);
              
              return (
                <Tab
                  classes={{
                    root: classes.root,
                    wrapper: classes.wrapper,
                    selected: classes.selected,
                    labelIcon: classes.labelIcon,
                  }}
                  label={tabName[tab]}
                  icon={<Icon icon={tab} className={styles.tabIcon} />}
                  key={`tab-${idx}`}
                  disabled={tab !== "healthcare"}
                  style={style}
                />
              );
            })}
        </Tabs>
      </AppBar>
      {planType === "MyWellness" && MyWellnessContent}
      {planType === "Library" ? (
        <LibraryContent tab={activeTab} />
      ) : (
        <MyPlansContent
          tabIndex={activeTab}
          tabName={"Healthcare"}
          userId={userId}
        />
      )}
    </>
  );
};

export default withStyles(sty)(NavigationalTabs);
