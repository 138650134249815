import HomecareSelections from "./Homecare.json";

import AlertnesstoTimeSurroundings2 from "./Step2/AlertnesstoTime&Surroundings.json";
import Driving2 from "./Step2/Driving.json";
import GettingAroundAlone2 from "./Step2/GettingAroundAlone.json";
import PersonalEmergencyResponse2 from "./Step2/PersonalEmergencyResponse.json";
import PreventingFalls2 from "./Step2/PreventingFalls.json";
import Shopping2 from "./Step2/Shopping.json";
import TakingDailyMedication2 from "./Step2/TakingDailyMedication.json";
import UsingTheBathroom2 from "./Step2/UsingTheBathroom.json";

import AlertnesstoTimeSurroundings3 from "./Step3/AlertnesstoTime&Surroundings.json";
import Driving3 from "./Step3/Driving.json";
import GettingAroundAlone3 from "./Step3/GettingAroundAlone.json";
import PersonalEmergencyResponse3 from "./Step3/PersonalEmergencyResponse.json";
import PreventingFalls3 from "./Step3/PreventingFalls.json";
import Shopping3 from "./Step3/Shopping.json";
import TakingDailyMedication3 from "./Step3/TakingDailyMedication.json";
import UsingTheBathroom3 from "./Step3/UsingTheBathroom.json";

export const homecareStep1 = HomecareSelections.HomecareStepOne;

export const homecareStep2 = {
  Driving: Driving2,
  Shopping: Shopping2,
  "AlertnesstoTimeandSurroundings": AlertnesstoTimeSurroundings2,
  GettingAroundAlone: GettingAroundAlone2,
  PersonalEmergencyResponse: PersonalEmergencyResponse2,
  PreventingFalls: PreventingFalls2,
  TakingDailyMedication: TakingDailyMedication2,
  UsingtheBathroom: UsingTheBathroom2,
};

export const homecareStep3 = {
  Driving: Driving3,
  Shopping: Shopping3,
  "AlertnesstoTimeandSurroundings": AlertnesstoTimeSurroundings3,
  GettingAroundAlone: GettingAroundAlone3,
  PersonalEmergencyResponse: PersonalEmergencyResponse3,
  PreventingFalls: PreventingFalls3,
  TakingDailyMedication: TakingDailyMedication3,
  UsingtheBathroom: UsingTheBathroom3,
};
