const config = (() => {
  switch (window.location.hostname) {
    case "app.psilife.com":
      return {
        GOOGLE_MAPS_API_KEY: "AIzaSyBLtkE1aVPhNULQr4fNiVNH7gPkjArx9FY",
        GOOGLE_ANALYTICS_TRACKING_ID: "G-GF334VDE4K",
      };
    case "localhost":
    case "as-app-psilife-alpha.azurewebsites.net":
    case "as-app-psilife-beta.azurewebsites.net":
    default:
      return {
        GOOGLE_MAPS_API_KEY: "AIzaSyBLtkE1aVPhNULQr4fNiVNH7gPkjArx9FY",
        GOOGLE_ANALYTICS_TRACKING_ID: "G-GF334VDE4K",
      };
  }
})();

export default config;
