function Device() {
  const getW = () => {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  };
  const getH = () => {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    );
  };
  const isPhone = (wv) => {
    const w = wv || getW();
    return w < 481 ? true : false;
  };
  const isTablet = (wv) => {
    const w = wv || getW();
    return w > 480 && w < 1024 ? true : false;
  };
  const isDesktop = (wv) => {
    const w = wv || getW();
    return w > 1023 ? true : false;
  };
  return {
    getW: getW,
    getH: getH,
    isPhone: isPhone,
    isTablet: isTablet,
    isDesktop: isDesktop,
    bodyPhone: (w, responsiveBackgroundColor) => {
      document.body.style.background = (w ? w > 480 : isPhone)
        ? responsiveBackgroundColor || "#cfcfcf"
        : "#faf7dc";
    },
    bodyAccount: (w) => {
      document.body.style.background = w ? "#fff" : "#faf7dc";
    },
    bodyCreate: (w) => {
      document.body.style.background = w ? "#fff" : "#cfcfcf";
    },
    bodyOnboarding: (w) => {
      document.body.style.background = (w ? w > 480 : isPhone)
        ? "#cfcfcf"
        : "#fff";
    },
  };
}

export default Device;
