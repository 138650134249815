import React, { useState, useEffect } from "react";

import styles from "./MyWellness.module.scss";

import Device from "../../utils/responsive.util";
import Toastify, {
  successToast,
  errorToast,
} from "../../components/Toastify/Toastify";

import MyWellnessJson from "../../Json_Templates/MyWellness/myWellness.json";
import ArchiveHomeScreen from "../../components/ArchiveHomeScreen/ArchiveHomeScreen.tsx";

import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import Icon from "../../components/Icon/Icon";
import Footer from "../../components/Footer/Footer";

import { putProfile, getProfile } from "../../services/profile-service";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const screen = Device();
const containerMargin = screen.isPhone() ? "auto" : "163px";

function MyWellness({ userData }) {
  const profileId = userData.profileId;

  const [serverChoices, setServerChoices] = useState([
    ...MyWellnessJson.choices,
  ]);
  const [catchError, setCatchError] = useState(false);

  useEffect(() => {
    getProfile().then((profileChoices) => {
      const savedSelections = profileChoices.data[0].choices.filter(
        (choice) => choice.category === "wellness"
      );

      setServerChoices(
        serverChoices.map((stateChoice) => {
          const found = savedSelections.find(
            (serverChoice) => stateChoice.choiceId === serverChoice.choiceId
          );
          return found ? found : stateChoice;
        })
      );
    });
  }, []);
  // RETRIEVABLE PROFILE COUNTER
  const checkSelection = async (tab) => {
    const choiceId = serverChoices[tab].choiceId;
    const isSaved = serverChoices.find(
      (choice) => choice.choiceId === choiceId
    );
    const value = isSaved ? (isSaved.value === 0 ? 1 : 0) : 1;
    const choice = { choiceId: choiceId, value: value, category: "wellness" };
    try {
      const response = await putProfile(profileId, [choice]);
      if (response.status === 200) {
        recordEvent(Events.Categories.wellness, Events.Actions.edit);
        successToast();
        const updatedChoice = response.data[0];
        setServerChoices(
          serverChoices.map((choice) => {
            return choice.choiceId === updatedChoice.choiceId
              ? updatedChoice
              : choice;
          })
        );
      }
    } catch (err) {
      errorToast();
      setCatchError(err);
    }
  };
  const renderSelection = (tabName) => (
    <div className={styles.categories}>
      <p>{MyWellnessJson.verbiage}</p>
      <div>
        {MyWellnessJson.tabs.map((tab, index) => {
          const jsonId = serverChoices[index].choiceId;
          const savedSelection =
            serverChoices &&
            serverChoices.find((choice) => choice.choiceId === jsonId);
          return (
            <span
              onClick={() => checkSelection(index)}
              key={`w-${index}`}
              style={{ backgroundColor: "#fff" }}
            >
              <Icon icon={tab} className={styles.classIcon} />
              {tabName[tab]}
            </span>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      <Header noBottomBorder={screen.isPhone()}>
        <Toastify />
        <Container
          style={{
            backgroundColor: "#FAF7DC",
            containerBackground: "#faf7dc",
            containerMargin: containerMargin,
          }}
          iconHeader={{
            "My Wellness": (
              <Icon icon={MyWellnessJson.icon} className={styles.tabIcon} />
            ),
          }}
        >
          <ArchiveHomeScreen
            data={MyWellnessJson}
            archiveType={MyWellnessJson.title}
            render={renderSelection}
          />
        </Container>
      </Header>
      <Footer hideSearchBar={screen.isPhone()} active="none" isTransparentBG />
    </>
  );
}

export default MyWellness;
