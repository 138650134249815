import React from "react";
import styles from "../../Library.module.scss";

import Content from "./Content";


const TabContent = ({ children, renderChildren = false, tab }) => {
  const log = {
    0: {
      title: "Homecare Information",
      verbiage:
        "See your homecare plan for each of your loved one's situations.",
      tab: "homecare",
    },
    1: {
      title: "Advance Care Planning",
      subTitle: "Getting Advance Care Planning in Order",
      verbiage:
        "",
      tab: "healthcare",
    },
    2: {
      title: "Insurance Information",
      verbiage:
        "See your insurance plan for each of your loved one's situations below.",
      tab: "insurance",
    },
  };


  return (
    <div className={styles.container}>
      <div style={{ padding: "5px 0px" }}>
        <p className={styles.plansTitle}>{log[tab].title}</p>
        <p className={styles.plansTitle}>{log[tab].subTitle}</p>
        <p className={styles.verbiage}>{log[tab].verbiage}</p>
      </div>
      {Object.keys(log).map((keytab) => {
        return (
          tab === parseInt(keytab) && (
            <Content tab={log[keytab].tab} key={`content-${keytab.title}`} />
          )
        );
      })}
      {renderChildren && children}
    </div>
  );
};

export default TabContent;
