import React from "react";
import styles from "./MapMarker.module.scss";

interface MapMarkerProps {
  lat: number;
  lng: number;
  index: number;
}

const MapMarker: React.FC<MapMarkerProps> = ({ index }) => {
  return (
    <div className={styles.mapMarkerContainer}>
      <p className={styles.markerId}>{index}</p>
    </div>
  );
};

export default MapMarker;
