import React, { useState, useEffect } from "react";

import { putProfile } from "../../services/profile-service";
import { syntaxify } from "../../utils/helpers.util";

import Header from "../../components/Header/Header";
import SliderMenu from "../../components/SliderMenu/SliderMenu";
import { createMarks } from "../../utils/slider.util";
import Button from "../../components/Button/Button";
import ProceedButton from "../../components/ProceedButton/ProceedButton";
import Modal from "../../components/Modal/Modal";
import {
  userDoesNotRequireServices,
  emptyState,
} from "../../utils/modal-messages";
import styles from "./Homecare-step-one.module.scss";

import Container from "../../components/Container/Container";

import HomecareData from "../../Json_Templates/Homecare/Homecare.json";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const Homecare = ({ history, userData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doesUserRequireHomeCare, setDoesUserRequireHomecare] = useState();
  const [validationError, setValidationError] = useState(false);

  const [homeCareUserSelection, setHomeCareUserSelection] = useState({});

  const marks = ["Can Do it", "Needs help", "Can't do it"];

  useEffect(() => {
    if (userData.profileChoices) {
      setHomeCareUserSelection({
        ...userSavedSelection,
      });
    }
  }, [userData.profileChoices]);

  const [catchError, setCatchError] = useState();
  const saveProfile = async () => {
    let response;
    const choices = syntaxify(homeCareUserSelection, "homecare");

    try {
      response = await putProfile(userData.profileId, choices);
      if (response.status === 200) {
        recordEvent(
          Events.Categories.homecare,
          Events.Actions.finish,
          Events.Labels.stepOne
        );
        history.push("/Homecare/Step2", {
          homeCareUserSelection,
        });
      }
    } catch (err) {
      setCatchError(err);
    }
  };

  const handleNextStep = async () => {
    let doesUserRequireHomeCareAssistance;
    let isEmpty = true;
    if (Object.keys(homeCareUserSelection).length > 0) {
      doesUserRequireHomeCareAssistance = Object.values(
        homeCareUserSelection
      ).every((userSelected) => userSelected.value === 10);
      if (doesUserRequireHomeCareAssistance) {
        setValidationError(true);
        setDoesUserRequireHomecare(true);
        setIsModalOpen(true);
        return;
      }
    }

    if (
      Object.keys(homeCareUserSelection).length ===
      HomecareData.HomecareStepOne.choices.length
    ) {
      isEmpty = false;
    }

    if (isEmpty) {
      setValidationError(true);
      setIsModalOpen(true);
    }

    if (!doesUserRequireHomeCareAssistance && !isEmpty) {
      saveProfile();
    }
  };

  const renderModalBody = () => {
    if (doesUserRequireHomeCare) {
      return (
        <div>
          {userDoesNotRequireServices.map((message, index) => (
            <p className={styles.errorMessage}>{message}</p>
          ))}
          <Button
            title={"Proceed To The Healthcare Section"}
            onClick={() => history.push("/Healthcare/Step1")}
            width={"100%"}
            borderRadius={5}
          />
          <Button
            title={"Review & Refine Your Selections"}
            onClick={() => setIsModalOpen(false)}
            width={"100%"}
            borderRadius={5}
            fontColor={{ color: "#9cbf85", fontWeight: "bold" }}
            buttonStyles={{ backgroundColor: "transparent" }}
          />
        </div>
      );
    } else {
      return <div>{emptyState}</div>;
    }
  };
  let userSavedSelection = {};
  return (
    <Header showGauge={false} isConversationPath={false}>
      <Container
        wrapperStyles={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Modal
          open={isModalOpen}
          message={() => renderModalBody()}
          handleClose={() => setIsModalOpen(false)}
        />
        {userData.profileChoices &&
          HomecareData.HomecareStepOne.choices.map((choice, index) => {
            const hasSaved = userData.profileChoices.find(
              (qa) => qa.choiceId === choice.choiceId
            );
            const profileSliderValue = hasSaved && hasSaved.value;
            if (!!profileSliderValue) {
              userSavedSelection = {
                ...userSavedSelection,
                [choice.title]: {
                  title: choice.title,
                  value: profileSliderValue,
                  userSelected: createMarks(marks).find(
                    (mark) => mark.value === profileSliderValue
                  ).label,
                  choiceId: choice.choiceId,
                },
              };
            }

            return (
              <SliderMenu
                key={`question-${index}`}
                showStep={index === 0}
                backgroundColor={index % 2 === 0 ? "#FAF7DC" : "#FFF"}
                title={choice.title}
                verbiage={choice.question}
                marks={marks}
                borderBottom
                width={"90%"}
                labelStyles={{ width: "20%" }}
                id={choice.choiceId}
                defaultValue={
                  homeCareUserSelection[choice.title] &&
                  homeCareUserSelection[choice.title].value
                }
                onChangeCommitted={(e, value) => {
                  const labels = createMarks(marks);
                  setHomeCareUserSelection({
                    ...homeCareUserSelection,
                    [choice.title]: {
                      title: choice.title,
                      value: value,
                      userSelected: labels.find((mark) => mark.value === value)
                        .label,
                      choiceId: choice.choiceId,
                    },
                  });
                }}
              />
            );
          })}
        <ProceedButton
          title={`PROCEED TO STEP 2`}
          onClick={() => {
            handleNextStep();
          }}
        />
      </Container>
    </Header>
  );
};

export default Homecare;
