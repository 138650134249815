import React, { useContext } from "react";

import { findJsonData } from "../../../../utils/helpers.util";
import { UserDataContext } from "../../../../Context/Auth-Provider";

import ArchiveAccordion from "../../../../components/ArchiveAccordion/ArchiveAccordion";
import AccordionContent from "./AccordionContent";

import { homecareStep2 } from "../../../../Json_Templates/Homecare/combinedHomecareImports";
import { insuranceStep2 } from "../../../../Json_Templates/Insurance/combinedInsuranceImports";
import { HealthCareStepTwoJSON } from "../../../../Json_Templates/Healthcare/healthcare";

import WhiteSectionContainer from "../../../../components/WhiteSectionContainer/WhiteSectionContainer";
import {
  getLibraryOptionNumber,
  getHealthcareIconCategory,
} from "../../../../utils/helpers.util";
import { renderComponents } from "../../../../utils/json-content-renderer";
import DNR from "../../../../Json_Templates/Healthcare/Step2/DNR.json";
const jsonMap = {
  homecare: homecareStep2,
  insurance: insuranceStep2,
  healthcare: HealthCareStepTwoJSON,
};

function Content({ tab }) {
  const userProfile = useContext(UserDataContext);

  const choicesFromServer =
    !userProfile.profile.isLoading && userProfile.profile.profileChoices;

  let accordionContent = null;

  if (choicesFromServer.length > 0) {
    const allServerIds = choicesFromServer
      .map((rawChoice) => {
        return rawChoice.category === tab && rawChoice.value === 1 && rawChoice;
      })
      .filter((stringId) => stringId);

    const tabItems = [];
    allServerIds.forEach((item) => {
      const found = findJsonData(item.choiceId, jsonMap[tab], true, true);
      tabItems.push({
        tab,
        ...found,
      });
    });

    const allItems = [];
    tabItems.forEach((item, index, arr) => {
      const filteredItems = [];
      const filteredCategories =
        tab !== "healthcare" &&
        arr
          .filter((i) => i.category === item.category)
          .sort((a, b) => (a.item.option < b.item.option ? -1 : 1));

      filteredCategories &&
        filteredCategories.forEach((item) => {
          item.item && filteredItems.push(item.item);
        });
      allItems.push({
        category: item.category,
        items: filteredItems,
      });
    });

    accordionContent = allItems.reduce((arr, i) => {
      arr = {
        ...arr,
        [i.category]: i.items,
      };
      return arr;
    }, {});
  }

  if (tab === "healthcare") {

    return (
      <WhiteSectionContainer>
        {renderComponents(DNR)}
      </WhiteSectionContainer>
    )

  }
  if (tab === "homecare") {
    let libraryOptions = getLibraryOptionNumber(
      userProfile.profile.profileChoices,
      "homecare"
    );
  }

  if (tab === "insurance") {
    let libraryOptions = getLibraryOptionNumber(
      userProfile.profile.profileChoices,
      "insurance"
    );

    libraryOptions =
      libraryOptions &&
      libraryOptions.sort((a, b) => (a.component < b.component ? -1 : 1));

    let accordionContent =
      libraryOptions &&
      libraryOptions.reduce((acc, value) => {
        acc = acc[value.name]
          ? {
            ...acc,
            [value.name]: [...acc[value.name], value],
          }
          : {
            ...acc,
            [value.name]: [value],
          };
        return acc;
      }, {});

    return libraryOptions ? (
      Object.entries(Content).map((libraryOption) => {
        return (
          <ArchiveAccordion
            icon={
              tab === "insurance" &&
              libraryOption[0] &&
              libraryOption[0].replace(/\W+/g, "").toLowerCase()
            }
            subtitle={libraryOption[0]}
            tabContent={
              libraryOption[1][0].libraryContent &&
              libraryOption[1].map((data) => {
                return (
                  <WhiteSectionContainer>
                    {renderComponents(data.libraryContent)}
                  </WhiteSectionContainer>
                );
              })
            }
          />
        );
      })
    ) : (
      <>Loading</>
    );
  }

  return (
    tab === "homecare" &&
    accordionContent &&
    Object.entries(accordionContent).map((choice, index) => {
      return (
        choice[1].length > 0 && (
          <ArchiveAccordion
            icon={
              tab === "homecare" && choice[0].replace(/\s/g, "").toLowerCase()
            }
            title={tab}
            subtitle={choice[0]}
            tabContent={choice[1].map((item) => {
              const libraryContent = item.libraryContent && item.libraryContent;

              const title = item.option ? `OPTION ${item.option}` : "OPTION";
              const subTitle = libraryContent && libraryContent.title;

              const paragraph =
                libraryContent &&
                libraryContent.paragraph &&
                libraryContent.paragraph;

              const titleParagraph =
                libraryContent &&
                libraryContent.titleParagraph &&
                libraryContent.titleParagraph;

              const paragraphList =
                libraryContent &&
                libraryContent.paragraphList &&
                libraryContent.paragraphList;

              const titleParagraphList =
                libraryContent &&
                libraryContent.titleParagraphList &&
                libraryContent.titleParagraphList;

              const titleParagraphCount =
                libraryContent &&
                libraryContent.titleParagraphCount &&
                libraryContent.titleParagraphCount;

              return (
                <AccordionContent
                  paragraph={paragraph}
                  title={title}
                  subTitle={subTitle}
                  titleParagraph={titleParagraph}
                  paragraphList={paragraphList}
                  titleParagraphList={titleParagraphList}
                  titleParagraphCount={titleParagraphCount}
                />
              );
            })}
            key={`${tab}-${index}`}
          />
        )
      );
    })
  );
}

export default Content;
