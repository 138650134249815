import React from "react";
import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import Device from "../../utils/responsive.util";
import NavigationalTabs from "../../components/NavigationalTabs/NavigationalTabs";
import ArchiveHomeScreen from "../../components/ArchiveHomeScreen/ArchiveHomeScreen";
import Footer from "../../components/Footer/Footer";
import LibraryStyles from "../Library/Library.module.scss";

import Icon from "../../components/Icon/Icon";

interface MyPlans {
  history: any;
  userData: {
    isLoading: Boolean;
    profileChoices: [
      {
        id: number;
        label: string;
      }
    ];
    profileId: String;
    userId?: String;
  };
}

const screen = Device();
const containerMargin = screen.isPhone() ? "auto" : "163px";
const MyPlans: React.FC<MyPlans> = ({ history }) => {
  const library = {
    icon: "library",
    title: "Library",
    "background-image-banner": {
      img: "library",
      text: "Find all of your caregiving-related plans in one place.",
    },
    verbiage:
      "Please choose from the categories below for quick and convenient access to the caregiving plans you selected throughout the Psī app.",
    tabs: ["homecare", "healthcare", "insurance"],
  };
  const [bgColor, setBgColor] = React.useState("#faf7dc");
  const showHomeScreen =
    history.location.state && typeof history.location.state.tab === "number";

  const homeScreenColor = showHomeScreen ? "#FFF" : "#F8F6DA";
  const footerDeskStopSize = "163px";
  bgColor === "white" && !showHomeScreen && setBgColor("#faf7dc");

  React.useEffect(() => {
    bgColor !== "white" && showHomeScreen && setBgColor("white");
  }, [showHomeScreen]);
  return (
    <>
      <Header showGauge={false} noBottomBorder={screen.isPhone()}>
        {/*
// @ts-ignore */}
        <Container
          style={{
            backgroundColor: "FAF7DC",
            containerBackground: homeScreenColor,
            containerMargin: containerMargin,
          }}
          wrapperStyles={{
            marginBottom: "75px",
            display: "flex",
            flexDirection: "column",
          }}
          containerStyles={{
            marginBottom: screen.isDesktop() ? footerDeskStopSize : "auto",
          }}
        >
          <div
            className={LibraryStyles.title}
            onClick={() => history.push("/HomeScreen")}
          >
            <p>
              <Icon icon="plansHeader" style={{ marginRight: "5px" }} />
              My Plans
            </p>
          </div>
          {showHomeScreen ? (
            // @ts-ignore
            <NavigationalTabs
              tab={history.location.state.tab}
              data={library}
              planType={"myPlans"}
            />
          ) : (
            <ArchiveHomeScreen
              data={library}
              archiveType={"MyPlans"}
              history={history}
            />
          )}
        </Container>
      </Header>
      <Footer
        hideSearchBar={screen.isPhone()}
        active="plans"
        isTransparentBG={!showHomeScreen}
      />
    </>
  );
};

export default MyPlans;
