import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  plankColor?: string;
  expandedPlankColor?: string;
  arrowColor?: string;
  overFlow?: boolean;
  onClick?: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  plankColor = "#1D918B",
  expandedPlankColor = "#1D918B",
  arrowColor,
  overFlow = false,
  onClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const ExpansionPanel = withStyles({
    root: {
      borderBottom: "1px solid #EAEBEB",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&$expanded": {
        borderBottom: "1px solid #EAEBEB",
      },
    },
    expanded: {
      borderBottom: "1px solid #EAEBEB",
    },
  })(MuiExpansionPanel);

  const ExpansionPanelSummary = withStyles({
    // ... other style objects
  })(MuiExpansionPanelSummary);

  return (
    <ExpansionPanel
      square
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
        onClick && onClick();
      }}
    >
      <ExpansionPanelSummary aria-controls="panel1d-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "auto",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: 12,
              lineHeight: "22px",
              letterSpacing: "-0.29px",
              color: "#188781",
            }}
          >
            {title}
          </Typography>
          <div>
            {expanded ? (
              <KeyboardArrowDown
                style={arrowColor ? { color: arrowColor } : undefined}
              />
            ) : (
              <KeyboardArrowRight
                style={arrowColor ? { color: arrowColor } : undefined}
              />
            )}
          </div>
        </div>
      </ExpansionPanelSummary>
      {expanded && <div>{children}</div>}
    </ExpansionPanel>
  );
};

export default Accordion;
