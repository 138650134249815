import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import StepThreeModal from "../../components/StepThreeModal/StepThreeModal";
import Accordion from "../../components/Accordion/Accordion";
import Step from "../../components/Step/Step";
import { getProfile } from "../../services/profile-service";
import { getMyPlans } from "../../utils/healthcare-util";

import { getContent } from "../../utils/healthcare-util";
import { removeWhiteSpaceUpperCaseWord } from "../../utils/helpers.util";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const HealthcareStepThree = ({ history, userData }) => {
  const [illnessContent, setIllnessContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [healthcarePlan, setHealthcarePlan] = useState([]);

  useEffect(() => {
    async function getUserProfileChoices() {
      const response = await getProfile();
      getIllnessStage(response.data[0].choices);

      setIsLoading(false);
    }
    getUserProfileChoices();
  }, []);

  const getIllnessStage = (profileChoices) => {
    const userData = {
      profileChoices,
    };
    const healthcarePlan = getMyPlans("healthcare", profileChoices).map(
      (x) => x.OptionTitle
    );

    const diagnoses = {};
    healthcarePlan.forEach((illness, index) => {
      diagnoses[illness] = getContent(
        removeWhiteSpaceUpperCaseWord(illness),
        userData
      );
    });

    setHealthcarePlan(healthcarePlan);
    setIllnessContent(diagnoses);
  };

  return (
    <Header showGauge={false}>
      {isLoading ? (
        <p>...Loading</p>
      ) : (
        <Container
          wrapperStyles={{
            display: "flex",
            flexDirection: "column",
          }}
          isButtonShown
          isFixed={false}
          buttonProps={{
            onClick: () => {
              recordEvent(
                Events.Categories.healthcare,
                Events.Actions.finish,
                Events.Labels.stepThree
              );
              history.push("/HomeScreen", {
                sectionCare: "healthcare",
                hasCompleted: true,
              });
            },
            title: "CLOSE",
          }}
        >
          <Step
            title={"Current Diagnosis"}
            label={"Healthcare"}
            iconName={"healthcare"}
            stepCount={"3"}
            verbiage={
              "You and Your family can now use this planner for your next steps."
            }
            fontSize={15}
          />

          {healthcarePlan.map((diagnosis, index) => {
            return (
              <Accordion
                item={diagnosis}
                renderBody={() => {
                  return (
                    <StepThreeModal
                      label="Healthcare"
                      stepIconName="healthcare"
                      stepCount="3"
                      category={"healthcare"}
                      stepVerbiage="You and Your family can now use this planner for your next steps."
                      currentStage={illnessContent[diagnosis][1][0].value}
                      content={illnessContent[diagnosis][0]}
                      isHeader={false}
                    />
                  );
                }}
              />
            );
          })}
        </Container>
      )}
    </Header>
  );
};

export default HealthcareStepThree;
