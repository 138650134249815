import React, { useState, useContext } from "react";
import styles from "./ServicesAndProviders.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Container from "../../components/Container/Container";
import Map from "../../components/Map/Map";
import SPBusinesses from "../../components/SPBusinesses/SPBusinesses";

import Device from "../../utils/responsive.util";
import Icon from "../../components/Icon/Icon";

import homecareIcon from "../../assets/service-and-providers-homecare-icon.svg";
import preventingFalls from "../../assets/preventingfalls-icon.svg";
import insuranceIcon from "../../assets/insurance-icon.svg";
import { UserDataContext } from "../../Context/Auth-Provider";
import { serviceAndProvidersJSON } from "../../Json_Templates/ServicesAndProviders/serviceAndProviders";

const screen = Device();
const containerMargin = screen.isPhone() ? "auto" : "163px";

interface ServiceProvider {
  category: string;
  zipcode: string | null;
  cities: string[];
  businessName: string;
  lat: number;
  lng: number;
  website: string;
  phone: string | null;
  logo: string;
  index: number;
  imgWidth?: number;
  height?: number;
}

const isDesktop = screen.isDesktop();

// @ts-ignore
const ServicesAndProviders: React.FC<ServiceProvider> = () => {
  const [searchArea, setSearchArea] = useState("");

  const [centeredCoordinates, setCenteredCoordinates] = useState({
    lat: 42.65258,
    lng: -73.756233,
  });

  // For footer show / hide animation
  const [shouldShowFooter, setShouldShowFooter] = useState(screen.isDesktop());

  const user = useContext(UserDataContext);

  if (user!.profile.isLoading) {
    return "loading";
  }

  const serviceProviders = serviceAndProvidersJSON["sph"]
    .filter((provider) => provider.cities.includes(searchArea))
    //@ts-ignore
    .sort((a, b) => a.index - b.index)
    .map((provider) => {
      return { ...provider };
    });

  const handleOnChangeSearchArea = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchArea(e.target.value.toLowerCase());
    renderCenteredCoordinates(e, serviceProviders);
  };

  const renderCenteredCoordinates = (
    e: React.ChangeEvent<HTMLInputElement>,
    serviceProviders: any
  ) => {
    const eventValue = e.target.value;
    let filteredCoordinates = serviceProviders.filter(
      // @ts-ignore
      (providers) =>
        providers.cities.includes(eventValue.toLowerCase()) ||
        providers.zipcode === eventValue
    );

    setCenteredCoordinates({
      lat: filteredCoordinates[0] && filteredCoordinates[0].lat,
      lng: filteredCoordinates[0] && filteredCoordinates[0].lng,
    });
  };

  const renderProviders = (serviceProviders: any, category: string) => {
    let foundProviders = serviceProviders
      .filter((provider: any) => {
        return provider.category === category;
      })
      //@ts-ignore
      .map((provider, index) => {
        console.log("provider", provider);
        return (
          <SPBusinesses
            category={category}
            provider={provider}
            index={provider.index}
            key={`SPBusiness - ${index}`}
            // @ts-ignore
            imgWidth={provider.imgWidth}
            height={provider.height}
          />
        );
      });

    return searchArea.length < 4 || foundProviders.length > 0 ? (
      foundProviders
    ) : (
      <div className={styles.noProviderInfoWrapper}>
        <p className={styles.noProvidersText}>
          Search Albany, Schenectady or Troy.
        </p>
      </div>
    );
  };

  /**
   * For footer show / hide animation animation
   * @see psilife.ui/client/src/components/Footer/Footer.tsx
   * @see psilife.ui/client/src/components/Container/Container.tsx
   */
  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    const shouldShow =
      screen.isDesktop() ||
      (() => {
        const MIN_HEIGHT_FOR_SCROLL = 600;
        const containerHeight = e.currentTarget.clientHeight;
        const containerScrollTop = e.currentTarget.scrollTop;
        if (containerHeight)
          return containerHeight + containerScrollTop > MIN_HEIGHT_FOR_SCROLL;
        return false;
      })();
    if (shouldShow !== shouldShowFooter) setShouldShowFooter(shouldShow);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Header noBottomBorder={screen.isPhone()} isConversationPath={false}>
        <Container
          style={{
            backgroundColor: "#fff",
            containerBackground: "#fff",
            containerMargin: containerMargin,
          }}
          wrapperStyles={{ display: "flex", flexDirection: "column" }}
          handleScroll={screen.isPhone() ? handleScroll : undefined}
          notSticky={true}
        >
          <div className={styles.servicesAndProvidersContainer}>
            <div className={styles.servicesAndProvidersInputWrapper}>
              <div className={styles.inputWrapper}>
                <div className={styles.iconTitleWrapper}>
                  <span>
                    <Icon icon="services-providers-search" />
                  </span>
                  <span className={styles.title}>Services & Providers</span>
                </div>
                <input
                  className={styles.input}
                  placeholder={"Search by City"}
                  onChange={(e) => handleOnChangeSearchArea(e)}
                />
              </div>
            </div>
            {/* @ts-ignore */}
            <Map
              searchArea={searchArea}
              centeredCoordinates={centeredCoordinates}
              affiliatedProviderJSON={serviceProviders}
            />
            <div className={styles.providerContainer}>
              <div className={styles.categoryWrapper}>
                <img
                  className={styles.categoryIcon}
                  src={homecareIcon}
                  alt="homecare"
                />
                <p className={styles.categoryText}>Homecare</p>
              </div>
              {searchArea && renderProviders(serviceProviders, "homecare")}
              <div className={styles.categoryWrapper}>
                <img
                  className={styles.categoryIcon}
                  src={preventingFalls}
                  alt="healthcare"
                />
                <p className={styles.categoryText}>Insurance/Finance</p>
              </div>
              {searchArea && renderProviders(serviceProviders, "insurance")}
              <div className={styles.categoryWrapper}>
                <img
                  className={styles.categoryIcon}
                  src={insuranceIcon}
                  alt="insurance"
                />
                <p className={styles.categoryText}>Home Equipment</p>
              </div>
              {searchArea && renderProviders(serviceProviders, "homeequipment")}
            </div>
          </div>
        </Container>
      </Header>
      {!isDesktop && (
        <Footer
          hideSearchBar={true}
          shouldAnimateFooter={true}
          shouldShowFooter={shouldShowFooter}
        />
      )}
    </>
  );
};

export default ServicesAndProviders;
