import React from "react";
import Icon from "../Icon/Icon";
import { IoIosArrowBack } from "react-icons/io";
import { Box } from "@material-ui/core";

interface BannerProps {
  text?: string;
  iconName?: string;
  children?: React.ReactNode;
  showArrow?: boolean;
  history?: any;
  color?: string;
  textColor?: string;
}

const Banner: React.FunctionComponent<BannerProps> = ({
  text = "Account Setup",
  iconName,
  showArrow = true,
  history,
  color = "#1D918B",
  textColor = "#fff",
}) => {
  return (
    <div
      style={{
        backgroundColor: color,
        display: "flex",
        height: "60px",
        alignItems: "center",
        justifyContent: "center",
        position: "relative", // Added this for absolute positioning
        width: "100%",
      }}
    >
      {showArrow && (
        <IoIosArrowBack
          style={{
            color: "#188781",
            cursor: "pointer",
            position: "absolute",
            left: "10px",
          }}
          onClick={() => history.goBack()}
        />
      )}

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon icon={iconName} />
        <h5 style={{ margin: 0, color: textColor, marginLeft: "5px" }}>
          {text}
        </h5>
      </Box>
    </div>
  );
};

export default Banner;
