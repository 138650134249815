import React from "react";
import styles from "../ListItem/ListItem.module.scss";
import { IoIosArrowForward, IoIosCheckmark } from "react-icons/io";

const ListItem = ({
  title,
  onClick,
  index,
  isChecked,
  renderIsCheckedIcon,
}) => {
  return (
    <div
      key={index}
      className={styles.listItem}
      onClick={() => onClick && onClick()}
    >
      <span className={styles.title}>{title}</span>
      {renderIsCheckedIcon && (
        <span className={styles.icon}>
          {isChecked ? (
            <IoIosCheckmark color={"#EEE487"} size={30} />
          ) : (
            <IoIosArrowForward
              color={"#FFFFFF"}
              size={20}
              style={{ marginRight: "5px" }}
            />
          )}
        </span>
      )}
    </div>
  );
};

export default ListItem;
