import React from "react";
import * as ContentHelpers from "../components/JsonContentHelpers/JsonContentHelpers";
import { camelCase } from "../utils/string-util";
import { AdvancedStagingTypes, stages } from "../Types/json-content-types";
const contentHelpers: { [key: string]: object | any } = { ...ContentHelpers };

interface data {
  choiceId?: string;
  optionTitle?: string;
  onClick?: () => void;
  backgroundColor: string;
  Title?: string;
  paragraphs?: Array<string>;
  unorderedList?: Array<string>;
  [key: string]: object | any;
  stages?: Array<stages>;
  advancedStaging?: AdvancedStagingTypes;
}

export const renderComponents = (data: data) => {
  const propKeys = Object.keys(data).map((data) =>
    data.trim().replace(/^\w/, (c) => c.toUpperCase())
  );

  return propKeys.map((propKey) => {
    const key: string = Array.isArray(data[propKey]) && data[propKey][0];
    let componentProps = {
      [propKey.toLowerCase()]: data[propKey],
    };

    const isProp: boolean =
      Object.values(componentProps)[0] && data[propKey] && key in data;

    if (isProp) {
      Object.values(componentProps)[0].forEach((componentProp: string) => {
        componentProps[componentProp] = data[componentProp];
      });
    } else {
      componentProps = {
        [camelCase(propKey)]: data[propKey],
      };
    }
    if (contentHelpers[propKey]) {
      return React.createElement(contentHelpers[propKey], componentProps);
    }
  });
};
