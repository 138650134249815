import React from "react";
import styles from "../../MyWellness.module.scss";

import Content from "./Content";

const TabContent = ({ children, renderChildren = false, tab }) => {
  const log = {
    0: {
      title: "Recently Added Information",
      verbiage:
        "Lorem ipsum dolor sit amet, coctetur adipiscing elit, sed do eiusmod tempor incididunt.",
      tab: "recents",
    },
    1: {
      title: "Homecare Information",
      verbiage:
        "Lorem ipsum dolor sit amet, coctetur adipiscing elit, sed do eiusmod tempor incididunt.",
      tab: "homecare",
    },
    2: {
      title: "Healthcare Information",
      verbiage:
        "Lorem ipsum dolor sit amet, coctetur adipiscing elit, sed do eiusmod tempor incididunt.",
      tab: "healthcare",
    },
    3: {
      title: "Insurance Information",
      verbiage:
        "Lorem ipsum dolor sit amet, coctetur adipiscing elit, sed do eiusmod tempor incididunt.",
      tab: "insurance",
    },
  };
  return (
    <div className={styles.tabContent}>
      <div style={{ padding: "5px 0px" }}>
        <span>{log[tab].title}</span>
        <p>{log[tab].verbiage}</p>
      </div>
      {Object.keys(log).map((keytab) => {
        return (
          tab === parseInt(keytab) && (
            <Content tab={log[keytab].tab} key={`content-${keytab.title}`} />
          )
        );
      })}
      {renderChildren && children}
    </div>
  );
};

export default TabContent;
