import React from "react";

import styles from "./RenderMappedJSONContent.module.scss";

export const Title = ({ title, titleNumber, style, className }) => {
  return title ? (
    <p style={style} className={`${className} ${styles.paragraph}`}>
      <span
        style={{
          display: "block",
          color: "#4A4A4A",
          fontSize: "12px",
        }}
        dangerouslySetInnerHTML={
          titleNumber
            ? {
                __html: `${titleNumber}. ${title}`,
              }
            : {
                __html: title,
              }
        }
      />
    </p>
  ) : (
    <></>
  );
};

export const Paragraph = ({
  paragraph,
  paragraphNumber,
  showParagraphNumber,
  style,
  className,
}) => {
  const renderParagraph = (text) => {
    return (
      <p
        style={style}
        className={`${className} ${styles.paragraph}`}
        dangerouslySetInnerHTML={
          showParagraphNumber
            ? {
                __html: `${paragraphNumber}. ${text}`,
              }
            : {
                __html: text,
              }
        }
      />
    );
  };

  return paragraph ? (
    Array.isArray(paragraph) ? (
      paragraph.map((text, index) => renderParagraph(text))
    ) : (
      renderParagraph(paragraph)
    )
  ) : (
    <></>
  );
};

export const List = ({
  list,
  ordered = false,
  bulletStyle,
  bulletClass,
  listStyle,
  listClass,
}) => {
  const renderList = (arr) => {
    return arr.map((bullet, index) => {
      return (
        <li
          style={bulletStyle}
          className={`${bulletClass} ${styles.paragraph}`}
          key={`bp-${index}`}
          dangerouslySetInnerHTML={{ __html: bullet }}
        />
      );
    });
  };
  return list ? (
    ordered ? (
      <ol style={listStyle} className={listClass}>
        {renderList(list)}
      </ol>
    ) : (
      <ul style={listStyle} className={listClass}>
        {renderList(list)}
      </ul>
    )
  ) : (
    <></>
  );
};

export const TitleParagraph = ({
  item,
  titleStyle,
  titleClass,
  paragraphStyle,
  paragraphClass,
}) => {
  return (
    <>
      <Title title={item.title} style={titleStyle} className={titleClass} />
      <Paragraph
        paragraph={item.paragraph}
        style={paragraphStyle}
        className={paragraphClass}
      />
    </>
  );
};

export const TitleParagraphList = ({
  item,
  titleStyle,
  titleClass,
  paragraphStyle,
  paragraphClass,
  bulletStyle,
  bulletClass,
  listStyle,
  listClass,
  orderedList,
  titleNumber,
  paragraphNumber,
}) => {
  return item ? (
    <>
      <Title
        title={item.title && item.title}
        style={titleStyle}
        className={titleClass}
        titleNumber={titleNumber}
      />
      <Paragraph
        paragraph={item.paragraph}
        style={paragraphStyle}
        className={paragraphClass}
        paragraphNumber={paragraphNumber}
        showParagraphNumber={item.showParagraphNumber}
      />
      <List
        list={item.list && item.list}
        bulletStyle={bulletStyle}
        bulletClass={bulletClass}
        listStyle={listStyle}
        listClass={listClass}
        ordered={orderedList}
      />
    </>
  ) : (
    <></>
  );
};

export const NestedList = ({
  item,
  ordered,
  listStyle,
  listClass,
  bulletStyle,
  bulletClass,
  paragraphStyle,
  paragraphClass,
  nestedBulletStyle,
  nestedBulletClass,
  nestedListStyle,
  nestedListClass,
  nestedOrdered,
}) => {
  const renderNestedList = () => {
    return item.nestedList.map((nested, index) => {
      return (
        <>
          <li
            style={bulletStyle}
            className={`${bulletClass} ${styles.paragraph}`}
            dangerouslySetInnerHTML={{
              __html: nested.bullet,
            }}
          />
          <Paragraph
            paragraph={nested.paragraph}
            style={paragraphStyle}
            className={paragraphClass}
          />
          <List
            list={nested.list}
            bulletStyle={nestedBulletStyle}
            bulletClass={nestedBulletClass}
            listStyle={nestedListStyle}
            listClass={nestedListClass}
            ordered={ordered}
          />
        </>
      );
    });
  };
  return item && item.nestedList ? (
    <>
      <Title title={item.title} />
      <Paragraph paragraph={item.paragraph} />
      {ordered ? (
        <ol style={listStyle} className={listClass}>
          {renderNestedList()}
        </ol>
      ) : (
        <ul style={listStyle} className={listClass}>
          {renderNestedList()}
        </ul>
      )}
    </>
  ) : (
    <></>
  );
};
