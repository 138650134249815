import React from "react";
import { Link } from "react-router-dom";
import Container from "../Container/Container";
import PsiLogo from "../../components/PsiLogo/PsiLogo";
import Button from "../../components/Button/Button";
import { createBrowserHistory } from "history";
import styles from "./RoutingError.module.scss";

const history = createBrowserHistory({ forceRefresh: true });

interface RoutingErrorInterface {
  message?: string;
}

const RoutingError: React.FC<RoutingErrorInterface> = ({
  message = "There has been an error",
}) => {
  return (
    <Container isOnboarding={true}>
      <div className={styles.error}>
        <PsiLogo />
        <h1>So sorry...</h1>
        <p>{message}</p>
        <Link to="/HomeScreen">
          <Button
            type="button"
            onClick={() => {
              history.push("/HomeScreen");
            }}
            title="Homepage"
          />
        </Link>
      </div>
    </Container>
  );
};

export default RoutingError;
