import React, { useEffect, useState } from "react";
import styles from "./Container.module.scss";
import ProceedButton from "../../components/ProceedButton/ProceedButton";
import Device from "../../utils/responsive.util";
import { useStyles } from "../../Views/Conversations/styles/conversation-styles";

interface ContainerProps {
  style?: any;
  backgroundColor?: string;
  isButtonShown?: boolean;
  buttonProps?: any;
  isFixed?: boolean;
  notSticky?: boolean;
  isOnboarding?: boolean;
  iconHeader?: { [key: string]: any };
  wrapperStyles?: { [key: string]: string };
  containerStyles?: { [key: string]: string };
  handleScroll?: (e: any) => void;
  isConversation?: boolean;
  responsiveBackgroundColor?: string;
  buttonTitle?: string;
  buttonOnClick?: () => void;
  isButtonDisabled?: boolean;
}

const Container: React.SFC<ContainerProps> = ({
  children,
  style,
  backgroundColor,
  wrapperStyles,
  containerStyles,
  isButtonShown,
  buttonProps,
  isFixed,
  notSticky = false,
  isOnboarding = false,
  iconHeader,
  handleScroll,
  isConversation,
  responsiveBackgroundColor,
  buttonTitle,
  buttonOnClick,
  isButtonDisabled,
}) => {
  const leftOn = style && style.leftOn;
  const isOff = leftOn && leftOn.off;
  const isOverlayClosed = parseInt(isOff) === 1 ? true : false;

  const classes = useStyles();

  const hasPadding = style && style.containerPadding;
  const padding = hasPadding && hasPadding;

  const hasMargin = style && style.containerMargin;
  const margin = hasMargin && hasMargin;

  const hasBackground = style && style.containerBackground;
  const color = hasBackground && hasBackground;

  const wrapperStyle = {
    paddingBottom: padding,
    backgroundColor: color,
    marginBottom: margin,
  };

  const contentPosition = isFixed ? "fixed" : !notSticky ? "sticky" : "";
  const contentStyle = isFixed
    ? {
        left: "0",
      }
    : {
        top: "60px",
        overflowY: "auto",
      };

  const device = Device();
  const [screenWidth, setScreenWidth] = useState(device.getW());

  window.onresize = () => {
    setScreenWidth(device.getW());
  };
  useEffect(() => {
    if (!isOnboarding) {
      device.bodyPhone(screenWidth, responsiveBackgroundColor);
    } else {
      device.bodyOnboarding(screenWidth);
    }
  }, [isOnboarding, responsiveBackgroundColor, screenWidth]);

  const isMobile = Device().isPhone();
  if (isMobile) {
    style = { ...style, display: "none" };
  }

  const toggleStyle = isOverlayClosed ? style : {};

  const footerPadding =
    style && style.paddingBottom ? style.paddingBottom : "0px";

  return (
    <>
      <div
        className={`${styles.contentContainer} ${
          isConversation ? classes.onBoardingResponsiveWrapper : ""
        }`}
        onScroll={handleScroll}
        style={{
          position: contentPosition,
          ...contentStyle,
          ...toggleStyle,
          paddingBottom: footerPadding,
          ...containerStyles,
        }}
      >
        <div
          className={styles.contentWrapper}
          style={{ ...wrapperStyle, ...wrapperStyles }}
        >
          <div>
            {iconHeader && (
              <div className={styles.iconHeader}>
                <p className={styles.text}>
                  {Object.values(iconHeader)}
                  {Object.keys(iconHeader)}
                </p>
              </div>
            )}
            {children}
          </div>
          {isButtonShown && (
            <ProceedButton
              buttonStyles={{ marginTop: "25px", ...buttonProps }}
              {...buttonProps}
              title={buttonTitle}
              onClick={buttonOnClick}
              isButtonDisabled={isButtonDisabled}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Container;
