import React from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import ConversationsJson from "../../Json_Templates/Conversations/ConversationsWithLovedOne/conversations-step9.json";
import { Typography, Box } from "@material-ui/core";

import { useStyles } from "./styles/conversation-styles";

import ConversationCheckBox from "../../components/ConversationCheckBox/ConversationCheckBox";

export default function ConversationsStep9({ history }: any) {
  const classes = useStyles();

  return (
    <Container
      wrapperStyles={{
        display: "flex",
        alignItems: "center",
        paddingTop: "0px",
      }}
      containerStyles={{
        width: "100%",
        borderRadius: "15px",
      }}
      responsiveBackgroundColor="#ddd"
    >
      {/* @ts-ignore */}
      <Header showProgressBar progressBarStatus={90} />
      <Box
        p={1}
        height="100%"
        marginTop={10}
        style={{ backgroundColor: "#FAF7DC", minHeight: "500px" }}
      >
        <Typography
          style={{
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "9px",
            lineHeight: "20px",
            letterSpacing: "-0.03px",
            textTransform: "uppercase",
            opacity: 1,
            color: "#000000",
            textAlign: "left",
          }}
        >
          {ConversationsJson.title}
        </Typography>

        <Typography
          style={{
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "13px",
            lineHeight: "21px",
            letterSpacing: "-0.07px",
            color: "#188781",
            textAlign: "left",
            textTransform: "uppercase",
            opacity: 1,
            marginBottom: "10px",
          }}
        >
          {ConversationsJson.content.title}
        </Typography>
        <Box
          style={{
            marginLeft: "16px",
            marginRight: "16px",
            marginBottom: "16px",
            marginTop: "16px",
            backgroundColor: "#fff",
            borderRadius: "15px",
          }}
          p={1}
        >
          {ConversationsJson.content.conversations.map((conversation) => {
            return (
              <Box
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                p={0}
                height="100%"
                margin={1}
              >
                {
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      lineHeight: "17px",
                      letterSpacing: "0px",
                      textAlign: "left",
                      color: "#4A4A4A",
                      opacity: 1,
                    }}
                    gutterBottom
                  >
                    <span>{conversation}</span>
                  </Typography>
                }
              </Box>
            );
          })}
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <ConversationCheckBox
              verbiage="Is your loved one ready to proceed with a conversation with the doctor?"
              onYesNavigateTo="/Conversations/WithDoctor/Step0"
              onNoNavigateTo="/Conversations/Step10"
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
