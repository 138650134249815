import React from "react";
import Psi from "../../assets/psi.png";
import HealthLogo from "../../assets/conversation-logo.svg";

interface LogoProps {
  height?: number;
  className?: string;
  onClick?: () => void;
  isConversation?: boolean;
}

const logo: React.FC<LogoProps> = ({
  height,
  className,
  onClick,
  isConversation = true,
}) => {
  return (
    <img
      src={isConversation ? HealthLogo : Psi}
      onClick={onClick && onClick}
      className={className && className}
      height={height}
      alt={"logo"}
      style={{ cursor: "pointer" }}
    />
  );
};

export default logo;
