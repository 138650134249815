import React from "react";

import { AdvancedStagingProps } from "../../Types/json-content-types";
import SectionContainer from "../../components/SectionContainer/SectionContainer";
import { renderComponents } from "../../utils/json-content-renderer";

interface SectionBProps extends AdvancedStagingProps {
  data: {
    category: string;
    backgroundColor: string;
  };
}

const SectionB: React.FC<SectionBProps> = ({ data }) => {
  return (
    <>
      <SectionContainer backgroundColor={data.backgroundColor}>
        {data.category === "insurance" && (
          <p style={{ marginTop: 15, fontSize: "20px" }}>
            Select one or both options below:
          </p>
        )}
        {renderComponents(data)}
      </SectionContainer>
    </>
  );
};

export default SectionB;
