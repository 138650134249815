import React from "react";
import RoutingError from "../RoutingError/RoutingError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error) {
    console.error(error);
    this.setState({ error: error });
  }

  render() {
    if (this.state.error) {
      return <RoutingError />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
