import React from "react";
import { Slide } from "@material-ui/core";

import Device from "../../utils/responsive.util";

import graphics from "./Overlay.module.scss";

const screen = Device();
const isPhone = screen.isPhone(screen.getW());

function Overlay(props) {
  return (
    <div className={graphics.Border}>
      <Slide
        direction={isPhone ? "up" : "left"}
        in={props.in}
        className={graphics.Popper}
        style={props.in && { display: "block" }}
      >
        {props.children}
      </Slide>
    </div>
  );
}

export default Overlay;
