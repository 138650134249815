import React from "react";
import Button from "../components/Button/Button";
import {
  userDoesNotRequireServices,
  emptyState,
} from "../utils/modal-messages";

export const UserDoesNotRequireServices = () => {
  return (
    <div>
      {userDoesNotRequireServices.map((message, index) => (
        <p className={{ fontFamily: "Poppins" }}>{message}</p>
      ))}
      <Button
        title={"Proceed To The Healthcare Section"}
        onClick={() => alert("clicked")}
        width={"100%"}
        borderRadius={5}
      />
      <Button
        title={"Review & Refine Your Selections"}
        onClick={() => alert("clicked")}
        width={"100%"}
        borderRadius={5}
        fontColor={{ color: "#9cbf85", fontWeight: "bold" }}
        buttonStyles={{ backgroundColor: "transparent" }}
      />
    </div>
  );
};

export const modalEmptyState = () => {
  return <div>{emptyState}</div>;
};
