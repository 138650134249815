import { Box, Typography } from "@material-ui/core";
import React from "react";
import Header from "../../components/Header/Header";
import ConversationsJson from "../../Json_Templates/Conversations/ConversationsWithLovedOne/conversations-step2.json";
import ConversationCheckBox from "../../components/ConversationCheckBox/ConversationCheckBox";
import Container from "../../components/Container/Container";
export default function ConversationStep2() {
  return (
    <Container
      wrapperStyles={{
        display: "flex",
        alignItems: "center",
        paddingTop: "0px",
        borderRadius: "15px",
      }}
      containerStyles={{
        position: "static",
        width: "100%",
        borderRadius: "15px",
      }}
      responsiveBackgroundColor="#ddd"
    >
      <Header showProgressBar progressBarStatus={50} />
      <Box
        p={1}
        height="100%"
        marginTop={9}
        style={{ backgroundColor: "#FAF7DC", height: "100%" }}
      >
        <Typography
          style={{
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-0.03px",
            textTransform: "uppercase",
            opacity: 1,
            color: "#000000",
            textAlign: "left",
            marginTop: "16px",
          }}
        >
          {ConversationsJson.title}
        </Typography>
        {ConversationsJson.content.sections.map((section) => {
          return (
            <>
              <Box margin={2}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "21px",
                    letterSpacing: "-0.07px",
                    color: "#188781",
                    textAlign: "left",
                    textTransform: "uppercase",
                    opacity: 1,
                  }}
                >
                  {section.title}
                </Typography>
              </Box>
              <Box style={{ backgroundColor: "#fff" }} margin={2}>
                {section.conversations.map((conversation) => (
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    height="100%"
                    padding={2}
                  >
                    <span>{conversation}</span>
                  </Box>
                ))}
              </Box>
            </>
          );
        })}

        <Box style={{ marginTop: "20px" }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <ConversationCheckBox
              verbiage="Is your loved one ready to proceed with the conversation?"
              onNoNavigateTo="/Conversations/Step9"
              onYesNavigateTo="/Conversations/Step4"
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
