import React from "react";
import SectionContainer from "../../components/SectionContainer/SectionContainer";
import {
  Title,
  Paragraph,
  Link,
  RenderAccordion,
  TitleParagraphs,
  Library,
  UnorderedList,
} from "../../components/JsonContentHelpers/JsonContentHelpers";
import {
  LinkProps,
  AccordionContentProps,
} from "../../Types/json-content-types";
import { renderComponents } from "../../utils/json-content-renderer";
import AdvancedDirectives from "../../Json_Templates/Healthcare/Step2/AdvancedDirectives.json";
import { useQuestionnaire } from "../../hooks/UseQuestionnaire";
import GettingAdvancedDirectivesInOrder from "../GettingAdvancedDirectivesInOrder/GettingAdvancedDirectivesInOrder";

interface SectionDProps {
  data: {
    title: string;
    backgroundColor: string;
    paragraphs: Array<string>;
    link: LinkProps;
    accordionContent: AccordionContentProps;
    category: string;
    titleParagraphs: Array<string>;
    labelParagraph: string[];
    library: boolean;
    hasCheckedLibrary: (isChecked: boolean) => boolean;
    isLibraryChecked: boolean;
    titleParagraphTwo: Array<string>;
    unorderedList: Array<string>;
  };
  paddingTop: number;
}

const SectionD: React.FC<SectionDProps> = ({ data }) => {
  const renderHealthcareSectionD = () => {
    return (
      <>
        <SectionContainer backgroundColor={"#fff"}>
          <Title title={data.title} advancedLabel={true} />
          <Paragraph paragraphs={data.paragraphs} />
          {data.link && (
            <Link title={data.link.title} hyperlink={data.link.hyperlink} />
          )}

          {data.titleParagraphs && (
            <>
              {/*@ts-ignore*/}
              <TitleParagraphs titleParagraphs={data.titleParagraphs} />
              {/*@ts-ignore*/}
            </>
          )}
          {data.library && (
            <>
              {/*@ts-ignore*/}
              <Library
                hasCheckedLibrary={data.hasCheckedLibrary}
                title="Go to Library for advance directives information and forms"
                isLibraryChecked={data.isLibraryChecked}
              />
            </>
          )}
          {data.titleParagraphTwo && (
            <>
              {/*@ts-ignore*/}
              <TitleParagraphs titleParagraphs={data.titleParagraphTwo} />
            </>
          )}
          {data.titleParagraphTwo && (
            <>
              {/*@ts-ignore*/}
              <TitleParagraphs titleParagraphs={data.titleParagraphs} />
            </>
          )}
          {data.unorderedList && (
            <>
              {/*@ts-ignore*/}
              <UnorderedList unorderedList={data.unorderedList} />
            </>
          )}
          {data.paragraphs && (
            <>
              {/*@ts-ignore*/}
              <UnorderedList unorderedList={data.unorderedList} />
            </>
          )}
          {/*@ts-ignore */}
        </SectionContainer>
        {/* this is commented out because the DNR accordion is not needed for healthcare */}
        <div>
          <RenderAccordion
            title={
              AdvancedDirectives.accordionContent.AdvanceCarePlanning.title
            }
            subTitle={
              AdvancedDirectives.accordionContent.AdvanceCarePlanning.subtitle
            }
            paragraphs={
              AdvancedDirectives.accordionContent.AdvanceCarePlanning.paragraphs
            }
          />
          <RenderAccordion
            title={AdvancedDirectives.accordionContent.healthCareProxy.title}
            paragraphs={
              AdvancedDirectives.accordionContent.healthCareProxy.paragraphs
            }
            defaultExpanded={false}
          />
          <RenderAccordion
            title={AdvancedDirectives.accordionContent.livingWill.title}
            paragraphs={
              AdvancedDirectives.accordionContent.livingWill.paragraphs
            }
            headerList={
              AdvancedDirectives.accordionContent.livingWill.headerList
            }
            titleParagraphs={
              AdvancedDirectives.accordionContent.livingWill.titleParagraphs
            }
            defaultExpanded={true}
          />
          <RenderAccordion
            title={AdvancedDirectives.accordionContent.DNR.title}
            paragraphs={AdvancedDirectives.accordionContent.DNR.paragraphs}
            headerList={AdvancedDirectives.accordionContent.DNR.headerList}
            orderedList={AdvancedDirectives.accordionContent.DNR.orderedList}
            paragraph={AdvancedDirectives.accordionContent.DNR.paragraph}
            link={AdvancedDirectives.accordionContent.DNR.link}
          />
          {/*@ts-ignore*/}

          {/*@ts-ignore*/}
        </div>
      </>
    );
  };
  return (
    <>
      <div>
        {data.category === "healthcare" ? (
          renderHealthcareSectionD()
        ) : (
          <SectionContainer backgroundColor={"#FFF"} paddingTop={10}>
            {renderComponents(data)}
            <span style={{ fontFamily: "Poppins" }}>
              Select an option below to speak with someone from a local health
              system in your loved one's community.
            </span>
            <GettingAdvancedDirectivesInOrder />
            <TitleParagraphs
              title={
                AdvancedDirectives.accordionContent.AdvanceCarePlanning.title
              }
            />
            <span style={{ color: "#188781", fontFamily: "Poppins" }}>
              Getting Advance Care Planning in Order
            </span>
            {renderHealthcareSectionD()}
          </SectionContainer>
        )}
      </div>
    </>
  );
};

export default SectionD;
