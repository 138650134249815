import React from "react";
import { createBrowserHistory } from "history";
import styles from "./Step.module.scss";
import { IoIosArrowBack } from "react-icons/io";
import Icon from "../../components/Icon/Icon";
const history = createBrowserHistory();
const Step = ({
  stepCount,
  verbiage,
  title,
  color = "#CADCDC",
  paddingTop = "auto",
  iconName,
  fontSize = 18,
  verbiageFontSize = 12,
  label = "Homecare",
  stepContainerStyles = {},
  stepWrapperStyles = { display: "flex", alignItems: "center" },
  iconStyle = { margin: "0 10px 0 2px" },
  text
}) => {
  return (
    <div className={styles.stepContainer} style={{ ...stepContainerStyles }}>
      <div
        className={styles.iconWrapper}
        style={{ paddingTop: paddingTop && paddingTop, ...stepWrapperStyles }}
      >
        <IoIosArrowBack
          color={color}
          size={20}
          style={{ marginLeft: "3px", zIndex: 100 }}
          onClick={() => history.goBack()}
        />

        <Icon icon={iconName} style={iconStyle} />
        <span className={styles.title} style={{ fontSize }}>
          {label}
        </span>

      </div>
      <div className={styles.verbiageWrapper}>
        <span
          className={styles.verbiage}
          style={{ fontSize: verbiageFontSize }}
        >
          {text}
        </span>
        <div className={styles.stepWrapper}>
          <span className={styles.step}>{`STEP ${stepCount}`}</span>
          <span className={styles.subTitle}>{title}</span>
        </div>
        <span
          className={styles.verbiage}
          style={{ fontSize: verbiageFontSize }}
        >
          {verbiage}
        </span>
      </div>
    </div>
  );
};

export default Step;
