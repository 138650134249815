import ReactGA from "react-ga4";

const recordEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number | undefined
) => {
  ReactGA.event({
    category,
    action,
    label: label || "",
    value: value || undefined,
  });
};

export default recordEvent;
