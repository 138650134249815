import React, { useContext } from "react";

import { findJsonData } from "../../../../utils/helpers.util";
import { UserDataContext } from "../../../../Context/Auth-Provider";

import Accordion from "../../../../components/Accordion/Accordion";

import { homecareStep2 } from "../../../../Json_Templates/Homecare/combinedHomecareImports";
import { insuranceStep2 } from "../../../../Json_Templates/Insurance/combinedInsuranceImports";
import { HealthCareStepTwoJSON } from "../../../../Json_Templates/Healthcare/healthcare";

const jsonMap = {
  homecare: homecareStep2,
  insurance: insuranceStep2,

  healthcare: HealthCareStepTwoJSON,
};

function Content({ tab }) {
  const userProfile = useContext(UserDataContext);
  const choicesFromServer =
    !userProfile.profile.isLoading && userProfile.profile.profileChoices;

  let recents = null;
  let accordionContent = null;
  let mostRecents = null;

  if (choicesFromServer.length > 0) {
    const allServerIds = choicesFromServer
      .map((rawChoice) => {
        return rawChoice.category === tab && rawChoice.value === 1 && rawChoice;
      })
      .filter((stringId) => stringId);

    recents = choicesFromServer
      .map((rawChoice) => {
        return (
          rawChoice.category !== "onboarding" &&
          rawChoice.value === 1 &&
          rawChoice
        );
      })
      .filter((stringId) => stringId);

    const tabItems = [];
    allServerIds.forEach((item) => {
      const found = findJsonData(item.choiceId, jsonMap[tab], true, true);
      tabItems.push({
        tab,
        ...found,
      });
    });

    const allItems = [];
    tabItems.forEach((item, idx, arr) => {
      const filteredItems = [];
      const i = arr.filter((i) => i.category === item.category);
      i.forEach((item) => {
        item.item && filteredItems.push(item.item);
      });
      allItems.push({
        category: item.category,
        items: filteredItems,
      });
    });

    accordionContent = allItems.reduce((arr, i) => {
      arr = {
        ...arr,
        [i.category]: i.items,
      };
      return arr;
    }, {});
  }

  if (tab === "recents" && choicesFromServer.length > 0) {
    const allRecents = recents.filter((i) => i.category);

    const rawChoice = allRecents.reduce((arr, i, idx, a) => {
      const found = a.filter((item) => item.category === i.category);
      const added = found.find((f) => f.choiceId === i.choiceId);
      const option =
        added && findJsonData(added.choiceId, jsonMap[i.category], true, true);

      option &&
        arr.push({
          title: i.category,
          subtitle: option.category,
          item: option.item,
        });
      return arr;
    }, []);

    mostRecents = rawChoice.reduce((arr, i) => {
      arr = {
        ...arr,
        [i.title]: [i.subtitle, i.item],
      };
      return arr;
    }, {});
  }

  return (
    <Accordion
      plankColor={"#F8F3BF"}
      plankFont={"#000"}
      expandedPlankColor={"#F8F3BF"}
      items={[{ title: "text" }]}
      renderBody={(i) => {
        return <p>{i}</p>;
      }}
    />
  );
}

export default Content;
