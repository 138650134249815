import React from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import { Box, Typography } from "@material-ui/core";
import Banner from "../../components/Banner/Banner";
import SliderInput from "../../components/Slider/Slider";
import { createMarks } from "../../utils/slider.util";

const AccountUserDetails = () => {
  const legend = ["Not confused", "A bit confused", "Very confused"];
  return (
    <Container
      style={{ containerPadding: "50px", height: "100vh" }}
      backgroundColor="#fff"
      containerStyles={{
        height: "100vh",
        width: "100vw",
        position: "relative",
        top: null,
      }}
      isButtonShown
      buttonProps={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
      }}
      buttonTitle="NEXT"
    >
      <Header showGauge={false} isConversationPath={false} />
      <Box style={{ marginTop: "40px", height: "auto", marginBottom: "40px" }}>
        <Banner iconName={"account-setup"} />
        <Box
          style={{
            marginLeft: "27px",
            marginRight: "35px",
          }}
        >
          <Typography
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: "17px",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#146E6C",
              marginTop: "40px",
            }}
          >
            About You
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#000000",
            }}
          >
            Enable the Selectors for these 3 Primary Questions.
          </Typography>
          <form style={{ marginTop: "16px" }}>
            <SliderInput
              marks={createMarks(legend)}
              step={5}
              sliderColor={"#3DADA2"}
              title={
                "Has anyone recommended that you look into a palliative care, palliative care, or hospice consult?"
              }
              titleStyles={{
                textAlign: "left",
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                lineHeight: "19px",
                color: "#1D918B",
                fontWeight: 600,
              }}
              sliderStyle={{
                marginTop: "35px",
              }}
            />
            <SliderInput
              marks={createMarks(legend)}
              step={5}
              sliderColor={"#3DADA2"}
              title={
                "If someone has already told you about palliative care, palliative care, or hospice, how likely are you to call for more information about one of those services at this time?"
              }
              onChange={(event, value) => {}}
              titleStyles={{
                textAlign: "left",
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                lineHeight: "19px",
                color: "#1D918B",
                fontWeight: 600,
              }}
              sliderStyle={{
                marginTop: "35px",
              }}
            />
            <SliderInput
              marks={createMarks(legend)}
              step={5}
              sliderColor={"#3DADA2"}
              title={
                "Overall, how burdened do you feel in caring for your loved one??"
              }
              onChangeCommitted={(event, value) => {}}
              titleStyles={{
                textAlign: "left",
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                lineHeight: "19px",
                color: "#1D918B",
                fontWeight: 600,
              }}
              sliderStyle={{
                marginTop: "35px",
              }}
            />
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default AccountUserDetails;
