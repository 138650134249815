import React from "react";
import PsiLogo from "../PsiLogo/PsiLogo";
import AddToLibrary from "../AddToLibrary/AddToLibrary";
import styles from "./JsonContentHelpers.module.scss";
import classnames from "classnames";
import { IoIosClose } from "react-icons/io";
import {
  AdvancedStagingProps,
  LabelParagraphsTypes,
  LinkProps,
} from "../../Types/json-content-types";
import SliderBar from "../Slider/Slider";
import { createMarks } from "../../utils/slider.util";
import CheckBox from "../../components/Checkbox/Checkbox";
import Accordion from "../../components/Accordion/Accordion";

interface RenderStagesProps {
  stages: {
    title: string;
    paragraphs: string[];
  }[];
}

interface SliderProps {
  sliderValue: number;
  sliderCallback: (sliderValue: number) => void;
  hasCheckedLibrary: () => boolean;
  isLibraryChecked: boolean;
  advancedStagingVerbiage: {
    verbiage: { label: string; paragraphs: string[] }[];
    link: LinkProps;
    labelParagraph: {
      label: string;
      hyperlink: string;
      paragraphs: string[];
      paragraphsTwo: string[];
    };

    Library: string[];
  };
}

export const Slider: React.FC<SliderProps> = ({
  sliderValue,
  sliderCallback,
  advancedStagingVerbiage,
  isLibraryChecked,
  hasCheckedLibrary,
}) => {
  const legend = ["Early", "Middle", "Advanced"];
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderWrapper}>
        {/* @ts-ignore */}
        <SliderBar
          marks={createMarks(legend)}
          step={null}
          sliderColor={"#3DADA2"}
          /* @ts-ignore */
          onChangeCommitted={(event, value: number) => {
            sliderCallback && sliderCallback(value);
          }}
          defaultValue={sliderValue}
        />
      </div>
      {sliderValue === 100 && (
        <div
          className={`animate__animated animate__fadeIn animate__faster ${styles.sectionBContainer}`}
        >
          <LabelParagraphsContainer
            labelParagraphs={advancedStagingVerbiage.verbiage}
          />
          <Link
            hyperlink={advancedStagingVerbiage.link.hyperlink}
            title={advancedStagingVerbiage.link.title}
            paragraphs={advancedStagingVerbiage.link.paragraphs}
            headline={advancedStagingVerbiage.link.headline}
          />
          <LabelParagraph
            label={advancedStagingVerbiage.labelParagraph.label}
            hyperlink={advancedStagingVerbiage.labelParagraph.hyperlink}
            paragraphs={advancedStagingVerbiage.labelParagraph.paragraphs}
            paragraphsTwo={advancedStagingVerbiage.labelParagraph.paragraphsTwo}
          />
          <Library
            title={
              "Check this option AS SOON AS your loved one is getting hospice care"
            }
            subTitle={"More information will be available at the library"}
            isLibraryChecked={isLibraryChecked}
            hasCheckedLibrary={hasCheckedLibrary}
          />
        </div>
      )}
    </div>
  );
};

export const Stages: React.FC<RenderStagesProps> = ({ stages }) => {
  return <TitleParagraphs titleParagraphs={stages} isLabel={false} />;
};

interface KindsProps {
  kinds: {
    title: string;
    paragraphs: string[];
  };
};

export const Kinds: React.FC<KindsProps> = ({kinds}) => {
  return (
    <>
      <u>
        <Title title={kinds.title} isLabel={false} />
      </u>
      <Paragraph paragraphs={kinds.paragraphs} />
    </>
  )
};

interface ListStagesProps {
  listStages: {
    title: string;
    paragraphs?: string[];
    unorderedList?: string[];
    orderedList?: string[];
    secondParagraph?: string[];
    secondOrderedList?: string[];
    sentence?: string[];
    sentences?: string[];
    boldSentence?: string;
  }[];
}

export const ListStages: React.FC<ListStagesProps> = ({ listStages }) => {
  return (
    <>
      {listStages.map((listStage, index) => {
        return (
          <div key={index}>
            {listStage.title && <Title title={listStage.title} />}
            {listStage.sentences && (
              <Paragraph paragraphs={listStage.sentences} />
            )}
            {listStage.unorderedList && (
              <List list={listStage.unorderedList} isOrdered={false} />
            )}
            {listStage.paragraphs && (
              <Paragraph paragraphs={listStage.paragraphs} />
            )}
            {listStage.orderedList && (
              <List list={listStage.orderedList} isOrdered={false} />
            )}
            {listStage.paragraphs && (
              <Paragraph paragraphs={listStage.secondParagraph} />
            )}
            {listStage.secondOrderedList && (
              <List list={listStage.secondOrderedList} isOrdered={false} />
            )}
            {listStage.sentence && (
              <Paragraph paragraphs={listStage.sentence} />
            )}
            {listStage.boldSentence && (
              <BoldSentence boldSentence={listStage.boldSentence} />
            )}
          </div>
        );
      })}
    </>
  );
};

export const AdvancedStaging: React.FC<AdvancedStagingProps> = ({
  advancedStaging,
  sliderCallback,
  sliderValue,
}) => {
  return (
    <div className={styles.advancedStagingContainer}>
      <span className={styles.label}>
        {advancedStaging.sliderVerbiage.title}
      </span>
      <p className={styles.paragraph}>
        {advancedStaging.sliderVerbiage.paragraph}
      </p>
    </div>
  );
};

interface TitleProps {
  title?: string;
  isLabel?: boolean;
  advancedLabel?: boolean;
  isNotBold?: boolean;
}

export const Title: React.FC<TitleProps> = ({
  title,
  isLabel = true,
  advancedLabel,
  isNotBold = true,
}) => {
  return (
    <div
      className={classnames({
        [styles.label]: isLabel,
        [styles.title]: !isLabel,
        [styles.advancedLabel]: advancedLabel,
        [styles.isNotBold]: isNotBold,
      })}
    >
      {title}
    </div>
  );
};

interface ParagraphProps {
  paragraphs?: Array<string>;
  paragraph?: string;
  useDefaultParagraphStyles?: boolean;
}

export const Paragraph: React.FunctionComponent<ParagraphProps> = ({
  paragraphs,
  paragraph,
  useDefaultParagraphStyles = true,
}) => {
  return (
    <div>
      {Array.isArray(paragraphs) ? (
        paragraphs.map((text) => (
          <p
            className={classnames({
              [styles.paragraph]: useDefaultParagraphStyles,
              [styles.noPaddingParagraph]: !useDefaultParagraphStyles,
            })}
          >
            {text}
          </p>
        ))
      ) : (
        <p className={styles.paragraph}>{paragraph}</p>
      )}
    </div>
  );
};
interface ParagraphsProps {
  paragraphs: Array<string>;
  useDefaultParagraphStyles?: boolean;
}

export const Paragraphs: React.FunctionComponent<ParagraphsProps> = ({
  paragraphs,
  useDefaultParagraphStyles = true,
}) => {
  return (
    <>
      {paragraphs.map((text) => (
        <p
          className={classnames({
            [styles.paragraph]: useDefaultParagraphStyles,
            [styles.noPaddingParagraph]: !useDefaultParagraphStyles,
          })}
        >
          {text}
        </p>
      ))}
    </>
  );
};

interface List {
  list: Array<string>;
  isOrdered: boolean;
}

export const List: React.FC<List> = ({ list, isOrdered = false }) => {
  const renderList = (list: Array<string>) => {
    return list.map((bullet, index) => {
      return (
        <li
          key={`bp-${index}`}
          dangerouslySetInnerHTML={{ __html: bullet }}
          className={styles.paragraph}
        />
      );
    });
  };
  return list ? (
    isOrdered ? (
      <ol>{renderList(list)}</ol>
    ) : (
      <ul>{renderList(list)}</ul>
    )
  ) : (
    <></>
  );
};

interface UnorderedList {
  unorderedList: Array<string>;
}

export const UnorderedList: React.FC<UnorderedList> = ({ unorderedList }) => {
  const renderList = (unorderedList: Array<string>) => {
    if (!Array.isArray(unorderedList)) return <></>;
    return unorderedList.map((bullet, index) => {
      return (
        <li
          key={`bp-${index}`}
          dangerouslySetInnerHTML={{ __html: bullet }}
          className={styles.unorderedList}
        />
      );
    });
  };
  return unorderedList ? <ul>{renderList(unorderedList)}</ul> : <></>;
};

interface TitleParagraphProps {
  title?: string;
  isLabel?: boolean;
  paragraphs?: string[];
  titleParagraphs?: {
    title: string;
    paragraphs: Array<string>;
  }[];
}
interface TitleParagraphTwoProps {
  title?: string;
  isLabel?: boolean;
  paragraphs?: string[];
  titleParagraphTwo?: {
    title: string;
    paragraphs: Array<string>;
  }[];
}

export const TitleParagraphTwo: React.FC<TitleParagraphTwoProps> = ({
  titleParagraphTwo,
  title,
  paragraphs,
  isLabel = false,
  ...rest
}) => {
  return (
    <>
      {titleParagraphTwo ? (
        titleParagraphTwo.map((titleParagraph, index) => {
          return (
            <>
              <Title title={titleParagraph.title} isLabel={isLabel} />
              <Paragraph paragraphs={titleParagraph.paragraphs} />
            </>
          );
        })
      ) : (
        <>
          {title && <Title title={title} />}
          {paragraphs && <Paragraph paragraphs={paragraphs} />}
        </>
      )}
    </>
  );
};
interface TitleParagraphProps {
  title?: string;
  isLabel?: boolean;
  paragraphs?: string[];
  titleParagraphs?: {
    title: string;
    paragraphs: Array<string>;
  }[];
}

export const TitleParagraphs: React.FC<TitleParagraphProps> = ({
  titleParagraphs,
  title,
  paragraphs,
  isLabel = false,
}) => {
  return (
    <>
      {titleParagraphs ? (
        titleParagraphs.map((titleParagraph, index) => {
          return (
            <>
              <Title title={titleParagraph.title} isLabel={isLabel} />
              <Paragraph paragraphs={titleParagraph.paragraphs} />
            </>
          );
        })
      ) : (
        <>
          {title && <Title title={title} />}
          {paragraphs && <Paragraph paragraphs={paragraphs} />}
        </>
      )}
    </>
  );
};

interface TitleParagraphListProps {
  titleParagraphList: {
    title: string;
    paragraphs: string[];
    list: string[];
  };
}

export const TitleParagraphList: React.FC<TitleParagraphListProps> = ({
  titleParagraphList,
}) => {
  return (
    <>
      {titleParagraphList.title && <Title title={titleParagraphList.title} />}

      {titleParagraphList.paragraphs && (
        <Paragraph paragraphs={titleParagraphList.paragraphs} />
      )}
      <List list={titleParagraphList.list} isOrdered={false} />
    </>
  );
};
interface ParagraphListProps {
  paragraphList: {
    Paragraphs: string[];
    List: string[];
  };
}

export const ParagraphList: React.FC<ParagraphListProps> = ({
  paragraphList,
}) => {
  return (
    <>
      <Paragraph paragraphs={paragraphList.Paragraphs} />
      <List list={paragraphList.List} isOrdered={false} />
    </>
  );
};

interface NestedListProps {}
//@ts-ignore
export const NestedList: React.FC<NestedListProp> = ({
  item,
  isOrdered,
  listStyle,
  listClass,
  bulletStyle,
  bulletClass,
  paragraphStyle,
  paragraphClass,
  nestedListStyle,
  nestedListClass,
}) => {
  const renderNestedList = () => {
    //@ts-ignore
    return item.nestedList.map((nested) => {
      return (
        <>
          <li
            style={bulletStyle}
            className={`${bulletClass} ${styles.paragraph}`}
            dangerouslySetInnerHTML={{
              __html: nested.bullet,
            }}
          />
          {/*@ts-ignore */}
          <Paragraph paragraph={nested.paragraph} className={paragraphClass} />

          <List list={nested.list} isOrdered={isOrdered} />
        </>
      );
    });
  };
  return item && item.nestedList ? (
    <>
      <Title title={item.title} />
      <Paragraph paragraphs={item.paragraph} />
      {isOrdered ? (
        <ol style={listStyle} className={listClass}>
          {renderNestedList()}
        </ol>
      ) : (
        <ul style={listStyle} className={listClass}>
          {renderNestedList()}
        </ul>
      )}
    </>
  ) : (
    <></>
  );
};

interface HeaderProps {
  backgroundColor: string;
  onClick: () => void;
}

export const Header: React.FC<HeaderProps> = ({ backgroundColor, onClick }) => {
  return (
    <div style={{ backgroundColor, paddingBottom: "25px" }}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            <PsiLogo height={40} />
            <IoIosClose
              name="IoIosClos"
              size={30}
              height={30}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface LabelParagraphsProps {
  labelParagraphs: LabelParagraphsTypes;
}

export const LabelParagraphsContainer: React.FC<LabelParagraphsProps> = ({
  labelParagraphs,
}): any => {
  return (
    labelParagraphs &&
    labelParagraphs.map((labelParagraph) => {
      if (labelParagraph.paragraphs) {
        return (
          <div className={styles.labelParagraphsContainer}>
            <div className={styles.label}>{labelParagraph.label}</div>
            {labelParagraph.paragraphs &&
              labelParagraph.paragraphs.map((paragraph) => {
                return <p className={styles.paragraph}>{paragraph}</p>;
              })}
            {labelParagraph.list && (
              <>
                <Title title={labelParagraph.list.headerList} isLabel={false} />
                <ol className={styles.orderedList}>
                  {labelParagraph.list.orderedList.map((list) => {
                    return <li className={styles.paragraph}>{list}</li>;
                  })}
                </ol>
              </>
            )}
            {labelParagraph.paragraph && (
              <p className={styles.paragraph}>{labelParagraph.paragraph}</p>
            )}
          </div>
        );
      }
    })
  );
};

interface LabelParagraphsContainerProps {
  label: string;
  hyperlink?: string;
  paragraphs: string[];
  paragraphsTwo?: string[];
}

export const LabelParagraph: React.FC<LabelParagraphsContainerProps> = ({
  label,
  hyperlink,
  paragraphs,
  paragraphsTwo,
  ...rest
}) => {
  console.log("rest", rest);
  return (
    <>
      <Title title={label} isLabel={true} />
      {/* @ts-ignore */}
      {rest.labelParagraph && (
        /* @ts-ignore */
        <Paragraph paragraphs={rest.labelParagraph.paragraphs} />
      )}

      <a
        href={`https://${hyperlink}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {hyperlink}
      </a>
      <Paragraph paragraphs={paragraphsTwo} />
    </>
  );
};

export const Link: React.FC<LinkProps> = ({
  title,
  paragraphs,
  headline,
  hyperlink,
  hyperlinks,
  isLabel,
  ...rest
}) => {
  return (
    <>
      <Title title={title} isLabel={isLabel} />
      <Paragraph paragraphs={paragraphs} />
      {headline && <Title title={headline} />}
      <div>
        {hyperlinks ? (
          hyperlinks.map((hyperlink) => (
            <p>
              <a
                href={`https://${hyperlink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {hyperlink}
              </a>
            </p>
          ))
        ) : (
          <p>
            <a
              href={`https://${hyperlink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {hyperlink}
            </a>
          </p>
        )}
      </div>
    </>
  );
};

interface LinkListProps {
  linkList: {
    title: string;
    paragraphs: string[];
    headline?: string;
    hyperlink?: string;
    hyperlinks?: string[];
    isLabel: boolean;
  };
}

export const LinkList: React.FC<LinkListProps> = ({ linkList, ...rest }) => {
  const { title, paragraphs, headline, hyperlink, hyperlinks, isLabel } =
    linkList;
  return (
    <>
      <Title title={title} isLabel={isLabel} />
      <div>
        {hyperlinks ? (
          hyperlinks.map((hyperlink: string) => (
            <p>
              <a
                href={`https://${hyperlink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {hyperlink}
              </a>
            </p>
          ))
        ) : (
          <p>
            <a
              href={`https://${hyperlink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {hyperlink}
            </a>
          </p>
        )}
        <Title title={headline} />
        <Paragraph paragraphs={paragraphs} />
      </div>
    </>
  );
};

interface CheckBoxContainerProps {
  items: Array<string>;
  backgroundColor: string;
  checkBoxQuestions: {
    title: string;
    questions: {
      text: string;
      choiceId: string;
      response: string;
    }[];
  };
  handleOnChangeCheckBox: (e: object, index: number, checked: boolean) => void;
  checked: boolean;
  indexesToCheck: Array<number>;
}

export const RenderCheckBox: React.FC<CheckBoxContainerProps> = ({
  handleOnChangeCheckBox,
  checked,
  indexesToCheck,
  checkBoxQuestions,
}) => {
  const questions = checkBoxQuestions.questions.map((item) => item.text);
  return (
    <div
      className={styles.textContainer}
      style={{
        backgroundColor: "#fff",
        marginBottom: "25px",
        marginTop: "15px",
        borderRadius: 10,
      }}
    >
      <span className={styles.checkBoxLabel}>
        I need guidance from the doctor regarding:
      </span>
      {/* @ts-ignore */}
      <CheckBox
        items={questions}
        handleOnChange={(
          e: React.ChangeEvent<HTMLInputElement>,
          index: number
        ) => {
          handleOnChangeCheckBox(
            checkBoxQuestions.questions[index],
            index,
            e.target.checked
          );
        }}
        checked={checked}
        indexesToCheck={indexesToCheck}
        labelRootStyles={{ height: "35px" }}
        labelStyles={{ color: "#4a4a4a", fontWeight: 400 }}
      />
    </div>
  );
};

interface ListParagraphProps {
  listParagraph: {
    title: string;
    paragraphs: Array<string>;
    headerList: string;
    unorderedList: Array<string>;
    isNotBold?: boolean;
  };
}

export const ListParagraph: React.FC<ListParagraphProps> = ({
  listParagraph,
}) => {
  return (
    <>
      <Title title={listParagraph.title} isLabel={false} />
      <Paragraph paragraphs={listParagraph.paragraphs} />
      <Title
        title={listParagraph.headerList}
        isLabel={false}
        isNotBold={listParagraph.isNotBold ? true : false}
      />
      <List list={listParagraph.unorderedList} isOrdered={false} />
    </>
  );
};

interface ListsProps {
  lists: {
    headerList: string;
    unorderedLists: Array<string>;
    title?: string;
    isBold?: boolean;
  }[];
}

export const Lists: React.FC<ListsProps> = ({ lists }) => {
  return (
    <>
      {lists.map((list) => {
        return (
          <>
            {list.title && <Title title={list.title} isLabel={false} />}
            {<Title title={list.headerList} isLabel={list.isBold} />}
            {<List list={list.unorderedLists} isOrdered={false} />}
          </>
        );
      })}
    </>
  );
};

interface RenderAccordionProps {
  title: string;
  paragraphs: Array<string>;
  paragraph?: string;
  headerList?: string;
  titleParagraphs?: {
    title: string;
    paragraphs: Array<string>;
  }[];
  orderedList?: string[];
  link?: LinkProps;
  defaultExpanded?: boolean;
  subTitle?: string;
}

export const RenderAccordion: React.FC<RenderAccordionProps> = ({
  title,
  paragraphs,
  headerList,
  titleParagraphs,
  orderedList,
  paragraph,
  link,
  defaultExpanded = true,
  subTitle,
  ...rest
}) => {
  return (
    <>
      <Accordion
        paragraphs={paragraphs}
        title={title}
        defaultExpanded={defaultExpanded}
        plankColor="#1D918B"
        plankFont="#fff"
        expandedPlankColor="##1D918B"
        arrowColor="#fff"
        renderBody={() => {
          return (
            <div style={{ backgroundColor: "#fff", padding: "15px" }}>
              {paragraphs && <Paragraph paragraphs={paragraphs} />}
              {headerList && <Title title={headerList} isLabel={false} />}
              {subTitle && <Title title={subTitle} isLabel={false} />}
              {titleParagraphs && (
                <TitleParagraphs titleParagraphs={titleParagraphs} />
              )}
              {orderedList && <List list={orderedList} isOrdered={true} />}
              {paragraph && <Paragraph paragraph={paragraph} />}
              {link && <Link hyperlinks={link.hyperlinks} title={link.title} />}
            </div>
          );
        }}
      />
    </>
  );
};

interface LibraryProps {
  title: string;
  subTitle: string;
  isLibraryChecked: boolean;
  hasCheckedLibrary: (e: any) => boolean;
}

export const Library: React.FC<LibraryProps> = ({
  title,
  subTitle,
  isLibraryChecked,
  hasCheckedLibrary,
}) => {
  return (
    <AddToLibrary
      check={isLibraryChecked}
      title={title}
      subTitle={subTitle}
      hasChecked={hasCheckedLibrary}
      defaultValue={false}
    />
  );
};

interface OptionTitleProps {
  optionTitle: string;
}

export const OptionTitle: React.FC<OptionTitleProps> = ({ optionTitle }) => {
  return <Title isLabel={false} title={optionTitle} />;
};

// Start of STEP 3 COMPONENTS
interface HeaderListOrderedListProps {
  headerListOrderedList: {
    title?: string;
    orderedList: string[];
    headerList: string;
    sentence?: string;
  };
}

export const HeaderListOrderedList: React.FC<HeaderListOrderedListProps> = ({
  headerListOrderedList,
}) => {
  return (
    <>
      <h4 className={styles.headerOrderedListTitle}>
        {headerListOrderedList.headerList}
      </h4>
      <p>{headerListOrderedList.title}</p>
      <ol className={styles.orderedListBoldContainer}>
        {headerListOrderedList.orderedList &&
          headerListOrderedList.orderedList.map((orderedList, index) => {
            return (
              <>
                <li className={styles.li}>
                  <p>{orderedList}</p>
                </li>
              </>
            );
          })}
      </ol>
    </>
  );
};

interface HeaderOrderedNestedUnorderedListProps {
  headerOrderedNestedUnorderedList: {
    title: string;
    headerList: string;
    paragraphs: string[];
    titleParagraphList: {
      title: string;
      paragraphs?: string[];
      unorderedList: string[];
      paragraph?: string[];
    }[];
  };
}

export const HeaderOrderedNestedUnorderedList: React.FC<
  HeaderOrderedNestedUnorderedListProps
> = ({ headerOrderedNestedUnorderedList }) => {
  return (
    <>
      <Title
        isLabel={true}
        title={headerOrderedNestedUnorderedList.headerList}
      />
      <ol className={styles.orderedListContainer}>
        {headerOrderedNestedUnorderedList.titleParagraphList.map(
          (titleList, index) => {
            return (
              <div
                className={styles.HeaderOrderedNestedUnorderedList}
                key={index}
              >
                <li className={styles.li}>
                  <p className={styles.content}>{titleList.title}</p>
                </li>
                <Paragraph
                  useDefaultParagraphStyles={false}
                  paragraphs={titleList.paragraphs}
                />
                <UnorderedList unorderedList={titleList.unorderedList} />
                <Paragraph
                  useDefaultParagraphStyles={false}
                  paragraphs={titleList.paragraph}
                />
              </div>
            );
          }
        )}
      </ol>
    </>
  );
};

interface TitleParagraphOrderedListProps {
  titleParagraphOrderedList: {
    headerList: string;
    orderedList: {
      title: string;
      paragraphs?: string[];
    }[];
  };
}

export const TitleParagraphOrderedList: React.FC<
  TitleParagraphOrderedListProps
> = ({ titleParagraphOrderedList }) => {
  return (
    <div className={styles.titleParagraphContainer}>
      <Title isLabel={true} title={titleParagraphOrderedList.headerList} />
      <ol className={styles.orderedListContainer}>
        {titleParagraphOrderedList.orderedList.map((orderedList, index) => {
          return (
            <>
              <li className={styles.li}>
                <p className={styles.listParagraph}>{orderedList.title}</p>
              </li>
              {orderedList.paragraphs &&
                orderedList.paragraphs.map((item, index) => {
                  return (
                    <p className={styles.paragraph}>
                      {item}
                      <hr />
                    </p>
                  );
                })}
            </>
          );
        })}
      </ol>
    </div>
  );
};

type orderedList = {
  title: string;
  paragraphs?: string[];
};

interface OrderedList {
  orderedList: Array<orderedList> & Array<string>;
}

export const OrderedList: React.FC<OrderedList> = ({ orderedList }) => {
  const renderList = (orderedList: Array<orderedList> & Array<string>) => {
    return orderedList.map((item, index) => {
      return !(typeof item === "string") ? (
        <React.Fragment key={`bp-${index}`}>
          <li
            dangerouslySetInnerHTML={{ __html: item.title }}
            className={styles.paragraph}
          />
          <Paragraph paragraphs={item.paragraphs} />
        </React.Fragment>
      ) : (
        <li
          dangerouslySetInnerHTML={{ __html: item }}
          className={styles.paragraph}
        />
      );
    });
  };
  return orderedList ? <ol>{renderList(orderedList)}</ol> : <></>;
};

interface TitleListProps {
  titleList: {
    title: string;
    list?: string[];
  }[];
}

export const TitleList: React.FC<TitleListProps> = ({ titleList, ...rest }) => {
  return (
    <>
      {titleList.map((titleList, index) => {
        return (
          <div key={index}>
            {titleList.title && <Title title={titleList.title} />}
            {titleList.list && <List list={titleList.list} isOrdered={false} />}
          </div>
        );
      })}
    </>
  );
};

interface ColonParagraphProps {
  colonParagraph: {
    colonTitle: string;
    paragraphs: string[];
  };
}

// START OF "LIBRARY SECTION B" in CHRONOLOGICAL ORDER
export const ColonParagraph: React.FC<ColonParagraphProps> = ({
  colonParagraph,
}) => {
  return (
    <>
      <span
        className={styles.colonTitle}
      >{`${colonParagraph.colonTitle} `}</span>
      {colonParagraph.paragraphs.map((paragraph) => {
        return <span className={styles.paragraph}>{paragraph}</span>;
      })}
    </>
  );
};

interface HeaderUnorderedListProps {
  headerUnorderedList: {
    headerList: string;
    unorderedList: string[];
    paragraphs?: string[];
  }[];
}

export const HeaderOrderedList: React.FC<HeaderUnorderedListProps> = ({
  headerUnorderedList,
}) => {
  return (
    <div className={styles.headerUnorderedListContainer}>
      {headerUnorderedList.map((headerUnorderedList) => {
        return (
          <div>
            <span className={styles.colonTitle}>
              {headerUnorderedList.headerList}
              <List
                list={headerUnorderedList.unorderedList}
                isOrdered={false}
              />
              <Paragraph paragraphs={headerUnorderedList.paragraphs} />
            </span>
          </div>
        );
      })}
    </div>
  );
};
interface HeaderUnorderedListProps {
  headerUnorderedList: {
    headerList: string;
    unorderedList: string[];
    paragraphs?: string[];
  }[];
}

export const HeaderUnorderedList: React.FC<HeaderUnorderedListProps> = ({
  headerUnorderedList,
}) => {
  return (
    <div className={styles.headerUnorderedListContainer}>
      {headerUnorderedList.map((headerUnorderedList) => {
        return (
          <div>
            <span className={styles.colonTitle}>
              {headerUnorderedList.headerList}
              <List
                list={headerUnorderedList.unorderedList}
                isOrdered={false}
              />
              <Paragraph paragraphs={headerUnorderedList.paragraphs} />
            </span>
          </div>
        );
      })}
    </div>
  );
};

interface ThingsToDescribeOneProps {
  thingsToDescribeOne: {
    headerList: string;
    orderedList: string[];
  };
}

export const ThingsToDescribeOne: React.FC<ThingsToDescribeOneProps> = ({
  thingsToDescribeOne,
}) => {
  return (
    <div className={styles.NewHeaderOrderedList}>
      <Title title={thingsToDescribeOne.headerList} isLabel={false} />
      <List list={thingsToDescribeOne.orderedList} isOrdered={true} />
    </div>
  );
};

interface NestedUnorderedListProps {
  nestedUnorderedList: {
    headerList: string;
    unorderedList: { headerList: string; unorderedList: string[] }[];
  };
}

export const NestedUnorderedList: React.FC<NestedUnorderedListProps> = ({
  nestedUnorderedList,
}) => {
  return (
    <div className={styles.nestedUnorderedListContainer}>
      <Title isLabel={false} title={nestedUnorderedList.headerList} />
      <ul className={styles.unorderedListWrapper}>
        {nestedUnorderedList.unorderedList.map((list) => {
          return (
            <li>
              <span className={styles.nestedHeaderList}>{list.headerList}</span>
              <ul className={styles.nestedUnorderedList}>
                {list.unorderedList.map((list) => {
                  return <li>{list}</li>;
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface SymptomsGaugeOneProps {
  symptomsGaugeOne: {
    headerList: string;
    unorderedList: string[];
    lowParagraph: string;
    mediumParagraph: string;
    highParagraph: string;
  };
}

export const SymptomsGaugeOne: React.FC<SymptomsGaugeOneProps> = ({
  symptomsGaugeOne,
}) => {
  return (
    <div className={styles.symptomsGaugeContainer}>
      <Title isLabel={false} title={symptomsGaugeOne.headerList} />
      <List isOrdered={false} list={symptomsGaugeOne.unorderedList} />
      <div className={styles.lowMedHighWrapper}>
        <h5 className={styles.lowMedHighText}>LOW</h5>
        <h5 className={styles.lowMedHighText}>MEDIUM</h5>
        <h5 className={styles.lowMedHighText}>HIGH</h5>
      </div>
      <div className={styles.colorParagraphsWrapper}>
        <p className={styles.lowParagraph}>{symptomsGaugeOne.lowParagraph}</p>
        <p className={styles.mediumParagraph}>
          {symptomsGaugeOne.mediumParagraph}
        </p>
        <p className={styles.highParagraph}>{symptomsGaugeOne.highParagraph}</p>
      </div>
    </div>
  );
};

interface TitleColonParagraphProps {
  titleColonParagraph: {
    colonHeader?: string;
    colonTitle: string;
    paragraphs: string[];
  }[];
}

export const TitleColonParagraph: React.FC<TitleColonParagraphProps> = ({
  titleColonParagraph,
}) => {
  return (
    <div className={styles.titleColonParagraphContainer}>
      {titleColonParagraph.map((items, index) => {
        return (
          <div className={styles.titleColonWrapper} key={index}>
            <h4 className={styles.colonHeader}>{items.colonHeader}</h4>
            <span className={styles.colonTitle}>{`${items.colonTitle} `}</span>
            {items.paragraphs.map((paragraph) => {
              return <span className={styles.paragraph}>{paragraph}</span>;
            })}
          </div>
        );
      })}
    </div>
  );
};

interface NonSeverePainSymptomsProps {
  nonSeverePainSymptoms: {
    headerList: string;
    unorderedList: string[];
  };
}

export const NonSeverePainSymptoms: React.FC<NonSeverePainSymptomsProps> = ({
  nonSeverePainSymptoms,
}) => {
  return (
    <div className={styles.nonSeverePainSymptomsContainer}>
      <div>
        <span className={styles.colonTitle}>
          {nonSeverePainSymptoms.headerList}
          <List list={nonSeverePainSymptoms.unorderedList} isOrdered={false} />
        </span>
      </div>
    </div>
  );
};

interface NonDiseaseSpecificPainNestedListProps {
  nonDiseaseSpecificPainNestedList: {
    headerList: string;
    unorderedList: { headerList: string; unorderedList: string[] }[];
  };
}

export const NonDiseaseSpecificPainNestedList: React.FC<
  NonDiseaseSpecificPainNestedListProps
> = ({ nonDiseaseSpecificPainNestedList }) => {
  return (
    <>
      <NestedUnorderedList
        nestedUnorderedList={nonDiseaseSpecificPainNestedList}
      />
    </>
  );
};

interface SymptomsGaugeTwoProps {
  symptomsGaugeTwo: {
    headerList: string;
    unorderedList: string[];
    lowParagraph: string;
    mediumParagraph: string;
    highParagraph: string;
  };
}

export const SymptomsGaugeTwo: React.FC<SymptomsGaugeTwoProps> = ({
  symptomsGaugeTwo,
}) => {
  return (
    <>
      <SymptomsGaugeOne symptomsGaugeOne={symptomsGaugeTwo} />
    </>
  );
};

interface ThingsToDescribeTwoProps {
  thingsToDescribeTwo: {
    headerList: string;
    orderedList: string[];
  };
}

export const ThingsToDescribeTwo: React.FC<ThingsToDescribeTwoProps> = ({
  thingsToDescribeTwo,
}) => {
  return (
    <>
      <ThingsToDescribeOne thingsToDescribeOne={thingsToDescribeTwo} />
    </>
  );
};

interface BehavioralChangeTCPProps {
  behavioralChangeTCP: {
    colonHeader?: string;
    colonTitle: string;
    paragraphs: string[];
    unorderedList: string[];
  }[];
}

export const BehavioralChangeTCP: React.FC<BehavioralChangeTCPProps> = ({
  behavioralChangeTCP,
}) => {
  return (
    <div className={styles.titleColonParagraphContainer}>
      {behavioralChangeTCP.map((items, index) => {
        return (
          <div className={styles.titleColonWrapper} key={index}>
            <h4 className={styles.colonHeader}>{items.colonHeader}</h4>
            <span className={styles.colonTitle}>{`${items.colonTitle} `}</span>
            {items.paragraphs.map((paragraph) => {
              return <span className={styles.paragraph}>{paragraph}</span>;
            })}
            <List list={items.unorderedList} isOrdered={false} />
          </div>
        );
      })}
    </div>
  );
};

interface NonSevereSymptomsProps {
  nonSevereSymptoms: {
    headerList: string;
    unorderedList: string[];
  };
}

export const NonSevereSymptoms: React.FC<NonSevereSymptomsProps> = ({
  nonSevereSymptoms,
}) => {
  return (
    <>
      <NonSeverePainSymptoms nonSeverePainSymptoms={nonSevereSymptoms} />
    </>
  );
};

interface IfThingsGetBadProps {
  ifThingsGetBad: {
    headerList: string;
    unorderedList: string[];
  };
}

export const IfThingsGetBad: React.FC<IfThingsGetBadProps> = ({
  ifThingsGetBad,
}) => {
  return (
    <div className={styles.IfThingsGetBadContainer}>
      <Title title={ifThingsGetBad.headerList} isLabel={false} />
      <List list={ifThingsGetBad.unorderedList} isOrdered={false} />
    </div>
  );
};

interface ThingsToDescribeThreeProps {
  thingsToDescribeThree: {
    headerList: string;
    orderedList: string[];
  };
}

export const ThingsToDescribeThree: React.FC<ThingsToDescribeThreeProps> = ({
  thingsToDescribeThree,
}) => {
  return (
    <>
      <ThingsToDescribeOne thingsToDescribeOne={thingsToDescribeThree} />
    </>
  );
};
interface ImminentDeathTCPProps {
  imminentDeathTCP: {
    colonHeader?: string;
    colonTitle: string;
    paragraphs: string[];
    unorderedList: string[];
  }[];
}

export const ImminentDeathTCP: React.FC<ImminentDeathTCPProps> = ({
  imminentDeathTCP,
}) => {
  return (
    <>
      <BehavioralChangeTCP behavioralChangeTCP={imminentDeathTCP} />
    </>
  );
};

interface NonSevereSymptomsTwoProps {
  nonSevereSymptomsTwo: {
    headerList: string;
    unorderedList: string[];
  };
}

export const NonSevereSymptomsTwo: React.FC<NonSevereSymptomsTwoProps> = ({
  nonSevereSymptomsTwo,
}) => {
  return (
    <>
      <NonSeverePainSymptoms nonSeverePainSymptoms={nonSevereSymptomsTwo} />
    </>
  );
};

interface IfThingsGetBadTwoProps {
  ifThingsGetBadTwo: {
    headerList: string;
    unorderedList: string[];
  };
}

export const IfThingsGetBadTwo: React.FC<IfThingsGetBadTwoProps> = ({
  ifThingsGetBadTwo,
}) => {
  return <IfThingsGetBad ifThingsGetBad={ifThingsGetBadTwo} />;
};

interface ThingsToDescribeFourProps {
  thingsToDescribeFour: {
    headerList: string;
    orderedList: string[];
  };
}

export const ThingsToDescribeFour: React.FC<ThingsToDescribeFourProps> = ({
  thingsToDescribeFour,
}) => {
  return (
    <>
      <ThingsToDescribeOne thingsToDescribeOne={thingsToDescribeFour} />
    </>
  );
};

interface BereavementProps {
  bereavement: {
    title: string;
    paragraphs: string[];
  };
}

export const Bereavement: React.FC<BereavementProps> = ({ bereavement }) => {
  return (
    <div className={styles.bereavementContainer}>
      <Title title={bereavement.title} isLabel={false} />
      <Paragraph
        paragraphs={bereavement.paragraphs}
        useDefaultParagraphStyles={true}
      />
    </div>
  );
};

interface OrderedTasksProps {
  orderedTasks: {
    taskTitle: string;
    paragraph: string;
  }[];
}

export const OrderedTasks: React.FC<OrderedTasksProps> = ({ orderedTasks }) => {
  return (
    <div className={styles.orderedTasksContainer}>
      <ol className={styles.orderedListWrapper}>
        {orderedTasks.map((task, index) => {
          return (
            <>
              <div className={styles.taskTitleWrapper}>
                <h5 className={styles.taskText}>Task</h5>
                <li className={styles.taskTitleText}>{task.taskTitle}</li>
              </div>
              <p className={styles.paragraph}>{task.paragraph}</p>
            </>
          );
        })}
      </ol>
    </div>
  );
};

interface SurvivorsFinancialIssuesProps {
  survivorsFinancialIssues: {
    title: string;
    paragraph: string;
    orderedList: string[];
  };
}

export const SurvivorsFinancialIssues: React.FC<
  SurvivorsFinancialIssuesProps
> = ({ survivorsFinancialIssues }) => {
  return (
    <div className={styles.survivorsFinancialIssuesContainer}>
      <Title title={survivorsFinancialIssues.title} isLabel={false} />
      <Paragraph paragraph={survivorsFinancialIssues.paragraph} />
      <List list={survivorsFinancialIssues.orderedList} isOrdered={true} />
    </div>
  );
};

// Insurance Step 2 Components
interface InsuranceTitleOrderedListProps {
  insuranceTitleOrderedList: {
    headerList: string;
    orderedList: string[];
  };
}

export const InsuranceTitleOrderedList: React.FC<
  InsuranceTitleOrderedListProps
> = ({ insuranceTitleOrderedList }) => {
  return (
    <div className={styles.InsuranceTitleOrderedListContainer}>
      <ThingsToDescribeOne thingsToDescribeOne={insuranceTitleOrderedList} />
    </div>
  );
};

interface TitleParagraphListParagraphProps {
  titleParagraphListParagraph: {
    title: string;
    paragraph: string;
    paragraphs: string[];
    headerList: string;
    unorderedList: string[];
    ListTitle: string;
  };
}

export const TitleParagraphListParagraph: React.FC<
  TitleParagraphListParagraphProps
> = ({ titleParagraphListParagraph }) => {
  return (
    <div className={styles.titleParagraphListParagraphContainer}>
      <Title title={titleParagraphListParagraph.title} isLabel={true} />
      <Paragraph paragraphs={titleParagraphListParagraph.paragraphs} />
      <Title title={titleParagraphListParagraph.headerList} isLabel={false} />
      <Title title={titleParagraphListParagraph.ListTitle} />
      <List
        list={titleParagraphListParagraph.unorderedList}
        isOrdered={false}
      />
      <Paragraph paragraph={titleParagraphListParagraph.paragraph} />
    </div>
  );
};

interface HyperlinkProps {
  hyperlink: {
    hyperlink: string;
  };
}

export const Hyperlink: React.FC<HyperlinkProps> = ({ hyperlink }) => {
  return (
    <div className={styles.hyperlinkContainer}>
      <p>
        <a
          href={`https://${hyperlink.hyperlink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {hyperlink.hyperlink}
        </a>
      </p>
    </div>
  );
};

// Insurance Step 3 Components

interface UnOrderedListParagraphProps {
  unOrderedListParagraphs: {
    titleList: string;
    paragraphs: string[];
  }[];
  titleParagraphs?: {
    title: string;
    paragraphs: string[];
  }[];
}

export const UnOrderedListParagraphs: React.FC<UnOrderedListParagraphProps> = ({
  unOrderedListParagraphs,
  titleParagraphs,
  ...rest
}) => {
  return (
    <div className={styles.orderedListParagraphContainer}>
      {titleParagraphs && <TitleParagraphs titleParagraphs={titleParagraphs} />}
      <ul>
        {unOrderedListParagraphs.map((list, index) => {
          return (
            <div className={styles.orderedListParagraphWrapper}>
              <li className={styles.titleList}>{list.titleList}</li>
              <p className={styles.listParagraphs}>{list.paragraphs}</p>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
interface OrderedListParagraphProps {
  orderedListParagraph: {
    titleList: string;
    paragraphs: string[];
  }[];
}

export const OrderedListParagraph: React.FC<OrderedListParagraphProps> = ({
  orderedListParagraph,
}) => {
  return (
    <div className={styles.orderedListParagraphContainer}>
      <ol>
        {orderedListParagraph.map((list, index) => {
          return (
            <div className={styles.orderedListParagraphWrapper}>
              <li className={styles.titleList}>{list.titleList}</li>
              <p className={styles.listParagraphs}>{list.paragraphs}</p>
            </div>
          );
        })}
      </ol>
    </div>
  );
};

// Homecare Step 3
interface EndingTitleParagraphsProps {
  endingTitleParagraphs: {
    title: string;
    paragraphs: string[];
  }[];
}

export const EndingTitleParagraphs: React.FC<EndingTitleParagraphsProps> = ({
  endingTitleParagraphs,
}) => {
  return (
    <div className={styles.endingTitleParagraphsContainer}>
      {endingTitleParagraphs.map((item, index) => {
        return (
          <div className={styles.endingTitleParagraphsContainer} key={index}>
            <Title title={item.title} isLabel={false} />
            <Paragraph paragraphs={item.paragraphs} />
          </div>
        );
      })}
    </div>
  );
};

interface OptionParagraphsProps {
  optionParagraphs: {
    optionNumber: number;
    paragraphs: string[];
  }[];
}

export const OptionParagraphs: React.FC<OptionParagraphsProps> = ({
  optionParagraphs,
}) => {
  return (
    <>
      {optionParagraphs.map((paragraph, index) => {
        return (
          <div key={`${index} option-paragraph`}>
            <OptionTitle optionTitle={`OPTION ${paragraph.optionNumber}`} />
            <Paragraph paragraphs={paragraph.paragraphs} />
          </div>
        );
      })}
    </>
  );
};

interface BoldSentenceProps {
  boldSentence: string;
}

export const BoldSentence: React.FC<BoldSentenceProps> = (props) => {
  return <p style={{ fontWeight: "bold" }}>{props.boldSentence}</p>;
};
interface SentenceProps {
  sentence: string;
}

export const Sentence: React.FC<SentenceProps> = (props) => {
  return <p>{props.sentence}</p>;
};

interface DNRPROPS {
  dNR: {
    paragraphs: string[];
    headerList: string;
    titleParagraphs: {
      title: string;
      paragraphs: string[];
    }[];
    orderedList: string[];
    paragraph: string;
    link: {
      hyperlinks: string[];
      title: string;
    };
  };
}
export const DNR: React.FC<DNRPROPS> = ({ dNR }) => {
  const {
    paragraphs,
    headerList,
    titleParagraphs,
    orderedList,
    paragraph,
    link,
  } = dNR;
  return (
    <div style={{ backgroundColor: "#fff", padding: "15px" }}>
      {paragraphs && <Paragraph paragraphs={paragraphs} />}
      {headerList && <Title title={headerList} isLabel={false} />}
      {titleParagraphs && <TitleParagraphs titleParagraphs={titleParagraphs} />}
      {orderedList && <List list={orderedList} isOrdered={true} />}
      {paragraph && <Paragraph paragraph={paragraph} />}
      {link && <Link hyperlinks={link.hyperlinks} title={link.title} />}
    </div>
  );
};
