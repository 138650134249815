import { createMuiTheme } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const useStyles = makeStyles({
  root: {
    backgroundColor: "#FAF7DC",
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
  },
  wrapper: {
    width: "91%",
    margin: "11px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  responsiveWrapper: {
    // on desktop max width of 50% of the screen
    // on mobile a max width of 90% of the screen
    width: "50%",
    margin: "10px auto",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  step4Wrapper: {
    // on desktop max width of 50% of the screen
    // on mobile a max width of 90% of the screen
    width: "38%",
    margin: "34px auto",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  step6Wrapper: {
    // on desktop max width of 50% of the screen
    // on mobile a max width of 90% of the screen
    width: "38%",
    margin: "34px auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  outsideTitleWrapper: {
    width: "90%",
    margin: "34px auto",
  },
  customText: {
    font: "italic normal normal 13px/18px Poppins",
    color: "#000000",
    letterSpacing: "-0.3px",
    textAlign: "left",
  },
  customColor: {
    color: "#1D918B",
    "&$checked": {
      color: "#1D918B",
    },
  },
  checked: {
    "&$checked": {
      color: "#1D918B",
    },
  },
  customParagraph: {
    letterSpacing: "-0.3px",
  },
  headerText: {
    height: "27px",
    font: "normal normal 600 15px/31px Poppins",
    color: "#000000",
    textAlign: "left",
    letterSpacing: "0.09px",
    textTransform: "uppercase",
    opacity: 1,
    margin: "20px 0 12px",
  },
  text: {
    font: "normal normal 600 15px/31px Poppins",
    letterSpacing: "0.12px",
    color: "#188781",
    textTransform: "uppercase",
    opacity: "1",

    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15px",
    },
  },
  title: {
    fontSize: "15px",
    letterSpacing: "0.12px",
    color: "#188781",
    textTransform: "uppercase",
    opacity: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "25px",
    },
  },
  conversationStepTwoTitle: {
    fontFamily: "Poppins",
    lineHeight: "20px",
    fontSize: "9px",
    margin: "20px 0 12px",
    fontWeight: 600,
  },
  onBoardingResponsiveWrapper: {
    //on desktop using flexbox to center the content
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100vh",
    },
  },
});
