import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import styles from "./Modal.module.scss";
import CloseIcon from "@material-ui/icons/Close";

export default function Modal({ open, message, handleClose }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles.icon}>
          <CloseIcon color={"#ddd"} onClick={handleClose} />
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {typeof message === "function" ? message() : message}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
