import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../../components/Container/Container";
import Header from "../../../components/Header/Header";
import ConversationsJson from "../../../Json_Templates/ConversationsWithFamily/ConversationsWithFamilyStep0.json";
import { Typography } from "@material-ui/core";

import { useStyles } from ".././styles/conversation-styles";

import Button from "../../../components/Button/Button";

export default function ConversationsWithDoctorStep2({ history }: any) {
  const classes = useStyles();

  return (
    <Container
      backgroundColor="#ddd"
      wrapperStyles={{
        display: "flex",
        alignItems: "center",
        paddingTop: "0px",
        height: "100vh",

        backgroundColor: "#FAF7DC",
      }}
      responsiveBackgroundColor="#ddd"
      isFixed={false}
    >
      <div className={classes.root}>
        {/* @ts-ignore */}
        <Header showProgressBar isSticky progressBarStatus={97}>
          <div
            style={{ maxWidth: "90%", margin: "34px auto", height: "100vh" }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: 600,
                lineHeight: "31px",
                letterSpacing: "0.09px",
                textAlign: "left",
                color: "#000000",
                textTransform: "uppercase",
                opacity: 1,
                marginBottom: "10px",
              }}
            >
              {ConversationsJson.title}
            </Typography>
            {ConversationsJson.content.conversations.map((conversation) => {
              return (
                <>
                  <p>{conversation}</p>
                </>
              );
            })}

            <Box
              display={"flex"}
              justifyContent="center"
              width={"100%"}
              marginTop={5}
              marginBottom={5}
            >
              <Button
                title="Next"
                type="button"
                onClick={() => history.push("/Conversations/WithFamily/Step1")}
              />
            </Box>
          </div>
        </Header>
      </div>
    </Container>
  );
}
