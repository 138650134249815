import React from "react";
import styles from "./IconHolder.module.scss";

const IconHolder = ({ children, title, onClick }) => {
  return (
    <div className={styles.container} onClick={() => onClick && onClick()}>
      {children}
      <div className={styles.iconFont}>{title}</div>
    </div>
  );
};

export default IconHolder;
