import React, { useContext, useState } from "react";

import { putProfile } from "../../services/profile-service";

import styles from "./OnBoardingStepThree.module.scss";

import Container from "../../components/Container/Container";
import Modal from "../../components/Modal/Modal";
import InputSlider from "../../components/Slider/Slider";


import { threeMarks, fiveMarks } from "../../utils/slider.util";

import OnBoardingStepThreeJSON from "../../Json_Templates/OnBoarding/OnBoarding.json";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";
import { UserDataContext } from "../../Context/Auth-Provider";
import Header from "../../components/Header/Header";
import { Box, Typography } from "@material-ui/core";
import Banner from "../../components/Banner/Banner";

const marks = {
  3: threeMarks,
  5: fiveMarks,
};
const confusedLegend = ["Not confused", "A bit confused", "Very confused"];
const notLikelyLegend = ["Not likely", "Somewhat likely", "Very likely"];
const notImportantLegend = ["Not at all", "Mildly", "Very"];
const OnBoardingStepThree = ({ history }) => {
  const data = useContext(UserDataContext);

  const person = data.fullName;
  const userId = data.profile.profileId;


  const userProfile = {};
  const [unComplete, setUnComplete] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userResponses, setUserResponses] = useState([]);
  const [error, setError] = useState(false);

  const setProfileChoices = (choiceId, arrayPosition, value) => {
    userResponses[arrayPosition] = {
      category: "onboarding",
      choiceId,
      value,
      Question: OnBoardingStepThreeJSON.OnBoardingStepThree[arrayPosition].question,
    };
    setUserResponses([...userResponses]); // this is to force a re-render as the previous approach mutates the state directly
    // Have to filter userResponses because some indexes are empty (false) if not filled out but still gives the appropriate length unless filtered
    let validation = userResponses.filter((response) => response);
    setUnComplete(!(validation.length === 3));
  };


  const handleOnClick = async () => {
    try {
      const response = await putProfile(userId, userResponses.filter((response) => response));
      if (response.status === 200) {
        recordEvent(
          Events.Categories.onboarding,
          Events.Actions.save,
          Events.Labels.stepThree
        );
        history.push("/OnBoarding/Step4", {
          userProfile: { ...userProfile, userId },
          person,
          userId,
        });
      }
    } catch (error) {
      setError(true);
      setIsModalOpen(true);
    }
  };
  if (data.profile.isLoading) {
    return <p>Loading</p>
  }
  return (
    <Container
      style={{ containerPadding: "50px", height: "100vh", backgroundColor: "#fff" }}
      wrapperStyles={{ backgroundColor: "#fff" }}
      backgroundColor="#fff"
      containerStyles={{
        height: "100vh",
        width: "100vw",
        position: "relative",
        top: null,
      }}
      isButtonShown

      isButtonDisabled={unComplete}
      buttonProps={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
        isDisabled: unComplete,
      }}
      buttonTitle="NEXT"
      buttonOnClick={handleOnClick}
    >
      <Header showGauge={false} isConversationPath={false} />
      <Box style={{ marginTop: "40px", height: "auto", marginBottom: "40px" }}>

        <Banner iconName={"account-setup"} history={history}
          color="#FAF7DC"
          textColor="#146E6C"
          showArrow
        />
        <Box
          style={{
            marginLeft: "27px",
            marginRight: "35px",
            marginTop: "40px",
            marginBottom: '40px'
          }}
        >
          <Typography
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: "17px",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#146E6C",
              marginTop: "40px",
            }}
          >
            About You
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#000000",
            }}

          >
            Enable the Selectors for these 3 Primary Questions.
          </Typography>
          <Modal
            open={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            message={"Please check your userResponses, you may have missed one"}
          />
          <div className={styles.container}>
            {OnBoardingStepThreeJSON.OnBoardingStepThree.map((item, itemIndex) => {
              let currentLegend = [];
              switch (itemIndex) {
                case 0:
                  currentLegend = confusedLegend;
                  break;
                case 1:
                  currentLegend = notLikelyLegend;
                  break;
                case 2:
                  currentLegend = notImportantLegend;
                  break;
                default:
                  currentLegend = [];
              }
              if (item.answers) {
                return null;
              } else if (item.inputField) {

                return (
                  <div className={styles.inputFieldWrapper}>
                    <span className={styles.question}>{item.question}</span>
                    <input
                      className={styles.numberField}
                      type="number"
                      inputmode="numeric"
                      onChange={(e) =>
                        setProfileChoices(
                          item.choiceId,
                          1,
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </div>
                );
              } else {
                return (
                  <InputSlider
                    verbiage={item.question}
                    legend={currentLegend}
                    useMarks
                    verbiageFontSize={14}
                    step={0}
                    min={0}
                    max={100}
                    marks={marks[item.sliderType]}
                    labelStyles={{
                      fontSize: "11px",
                    }}
                    sliderStyle={{ margin: "45px 0" }}
                    defaultValue={
                      userResponses[itemIndex] && userResponses[itemIndex].value
                    }
                    onChangeCommitted={(e, value) => {
                      setProfileChoices(item.choiceId, itemIndex, value);
                    }}

                    legendStyles={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                      paddingLeft: "15px",
                      marginBottom: "5px",
                    }}
                    verbiageStyles={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 600,
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "left",
                      color: "#146E6C",
                      marginTop: "10px",
                    }}
                  />
                );
              }
            })}
          </div>
        </Box>
      </Box>
    </Container>
  );
};

export default OnBoardingStepThree;
