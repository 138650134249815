import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import ConversationsJson from "../../Json_Templates/Conversations/ConversationsWithLovedOne/conversations.json";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles/conversation-styles";

import ConversationCheckBox from "../../components/ConversationCheckBox/ConversationCheckBox";
import ConversationTitle from "../../components/ConversationTitle/ConversationTitle";

export default function Conversations({ history }: { history: any }) {
  return (
    <Container
      wrapperStyles={{
        display: "flex",
        alignItems: "center",
        paddingTop: "0px",
      }}
      containerStyles={{
        position: "static",
        width: "100%",
        borderRadius: "15px",
      }}
      responsiveBackgroundColor="#ddd"
    >
      <Header showProgressBar progressBarStatus={60} isSticky />
      <Box
        p={1}
        height="100%"
        marginTop={9}
        style={{ backgroundColor: "#FAF7DC", height: "100%" }}
      >
        <ConversationTitle title={ConversationsJson.title} history={history}/>
        <div style={{ marginTop: "0px !important" }}>
          <p style={{ margin: "19px 0 9px 20px" }}>
            {ConversationsJson.subTitle}
          </p>
          <div
            style={{
              marginLeft: "16px",
              marginRight: "16px",
              marginBottom: "16px",
              backgroundColor: "#fff",
              borderRadius: "15px",
            }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              margin={1}
              padding={1}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "13px",
                  lineHeight: "31px",
                  letterSpacing: 0,
                  color: "#188781",
                  textAlign: "left",
                  opacity: 1,
                }}
              >
                <p>Sample Conversation Starters</p>
              </Typography>
              {ConversationsJson.content.conversations.map((conversation) => {
                return (
                  <>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontStyle: "italic",
                        lineHeight: "18px",
                        letterSpacing: "-0.3px",
                        color: "#000000",
                        textAlign: "left",
                        opacity: 1,
                      }}
                    >
                      <p> {conversation}</p>
                    </Typography>
                  </>
                );
              })}
            </Box>
          </div>
        </div>
      </Box>
      <Box
        display={"flex"}
        justifyContent="center"
        width={"100%"}
        paddingTop={2}
        style={{ backgroundColor: "#FAF7DC", height: "100%" }}
      >
        <ConversationCheckBox
          verbiage="Is your your loved one ready to proceed with the conversation?"
          onYesNavigateTo="/Conversations/Step4"
          onNoNavigateTo="/Conversations/Step2"
        />
      </Box>
    </Container>
  );
}
