import React from "react";
import styles from "../OnBoarding/OnBoardingStepOne.module.scss";

import Container from "../../components/Container/Container";
import Child from "./Child";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

import verbiage from "../../Json_Templates/OnBoarding/onBoardingVerbiage.json";
import Header from "../../components/Header/Header";
import { Typography } from "@material-ui/core";
import Icon from "../../components/Icon/Icon";

const onBoardingStep1 = verbiage.step1;

function OnBoardingStepOne({ history }) {

  return (
    <Container
      style={{ containerPadding: "50px", containerBackground: "#faf7dc" }}
      backgroundColor="#fff"
      containerStyles={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        top: null,
      }}
      isButtonShown
      buttonProps={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
      }}

      buttonTitle="LET'S BEGIN"
      buttonOnClick={() => {

        history.push("/Account/Create")
      }}
    >
      <Header showGauge={false} isConversationPath={false} />
      {onBoardingStep1.map((block, index) => {
        return (
          <Child className={styles.onboard}>
            {index === 0 && (
              <Typography
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 600,
                  fontSize: "17px",
                  lineHeight: "24px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  color: "#146E6C",
                  marginTop: "40px",
                }}
              >
                Welcome
              </Typography>
            )}
            {block.titleParagraph &&
              block.titleParagraph.map((paragraph, index) => {
                return (
                  <>
                    <Typography
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        fontSize: "17px",
                        lineHeight: "24px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#146E6C",
                        marginTop: "10px",
                      }}
                    >
                      {paragraph}
                    </Typography>
                  </>
                );
              })}
            {block.isMarked
              ? block.paragraphs.map((paragraph) => {
                return (
                  <p>


                    <span>{paragraph}</span>
                  </p>
                );
              })
              : block.endParagraphs.map((paragraph) => (
                <span style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Icon icon="right-arrow" style={{ marginRight: '10px', marginTop: '6px' }} />
                  {paragraph}
                </span>

              ))}
            {index === 0 && (
              <Typography
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "22px",
                  lineHeight: "27px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  color: "#146E6C",
                  marginTop: "5px",
                }}
              >

                Managing a loved one’s advanced illness can be quite difficult.
              </Typography>

            )}

          </Child>
        );
      })}
    </Container>
  );
}

export default OnBoardingStepOne;
