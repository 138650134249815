import { homecareStep2 } from "../Json_Templates/Homecare/combinedHomecareImports";
import { insuranceStep2 } from "../Json_Templates/Insurance/combinedInsuranceImports";
import healthcareStep1 from "../Json_Templates/Healthcare/Step1/HealthcareStepOne.json";
import { HealthCareStepTwoJSON } from "../Json_Templates/Healthcare/healthcare";
import { SavedChoices, ProfileChoices } from "../Types/saved-choices-types";
import { HomeCareInsurance } from "../Types/homecare-insurance-types";

import { removeSpecialCharatersLowerCase } from "../utils/string-util";

const mappingJSON = {
  ...healthcareStep1,
};

export const getHealthcareIconCategory = (diagnosis: string) => {
  let categoryIcons: string[] = [];
  Object.entries(healthcareStep1).forEach(([category, match]) => {
    const diagnosisMatch = match.find(
      (json) =>
        removeSpecialCharatersLowerCase(json.text) ===
        removeSpecialCharatersLowerCase(diagnosis)
    );
    diagnosisMatch && categoryIcons.push(category);
  });
  return categoryIcons[0] && removeSpecialCharatersLowerCase(categoryIcons[0]);
};

export const findJsonData = (
  id: string,
  mappingJSON: object,
  getCategory: boolean,
  getWhole: boolean
) => {
  let found = {
    choiceId: null,
  };
  let category = "";
  let item = {};
  Object.values(mappingJSON).find((jsonFile) => {
    const json = jsonFile.pageContent ? jsonFile.pageContent : jsonFile;
    found = json.find((option: any, idx: number) => {
      if (idx === 0)
        category = option.optionTitle
          ? option.optionTitle
          : option.title
          ? option.title
          : option.Title;
      return option.choiceId === id;
    });
    item = found;
    return found;
  });
  if (!getCategory && getWhole) {
    return item;
  }
  if (getCategory && getWhole) {
    return item && { category: category, item: item };
  }
  return getCategory ? category : found && found.choiceId;
};

export const getLibraryOptionNumber = (
  profileChoices?: SavedChoices,
  category?: string
) => {
  const flattenedPlans = [
    ...Object.values(homecareStep2),
    ...Object.values(insuranceStep2),
    HealthCareStepTwoJSON,
  ];
  if (profileChoices && profileChoices.length > 0) {
    let LibrarySelection = profileChoices
      .map((profileChoice: ProfileChoices) => {
        if (category && profileChoice.category === category) {
          return profileChoice.value === 1
            ? getChoiceId(profileChoice.choiceId)
            : null;
        }
      })
      .filter((x) => x);
    return LibrarySelection;
  }
  function getChoiceId(choiceId: string): HomeCareInsurance | undefined {
    let element;
    Object.values(flattenedPlans).find((jsonFile) => {
      //@ts-ignore */
      const json = jsonFile.pageContent || jsonFile;
      //@ts-ignore */
      Object.values(json).find((option: HomeCareInsurance, idx: number) => {
        if (Array.isArray(option)) {
          const item = option.find((option) => option.choiceId === choiceId);
          if (item) {
            let found = {
              ...item,
              name: option[0].OptionTitle,
            };
            element = found;
          }
        }

        //@ts-ignore */
        if (option.choiceId === choiceId) {
          let found = {
            ...option,
            //@ts-ignore */
            name: jsonFile.pageContent
              ? //@ts-ignore */
                jsonFile.pageContent[0].title
              : //@ts-ignore */
                jsonFile[0].Title,
          };
          element = found;
        }
      });
    });

    return element;
  }
};

export const syntaxify = (data: object, category: string) => {
  return Object.values(data).reduce((arr, i) => {
    arr.push({
      choiceId: i.choiceId,
      value: i.value,
      category: category,
    });
    return arr;
  }, []);
};
export const removeWhiteSpaceUpperCaseWord = (string: string) => {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .replace(/\s/g, "");
};

export const getLastMessage = async (descriptor: any) => {
  const channel = await descriptor.getChannel();

  const lastMessage = channel.lastMessage;

  const messages = await channel.getMessages();

  return lastMessage
    ? {
        text: messages[lastMessage.index]
          ? messages.items[lastMessage.index].body
          : "",
        timestamp: lastMessage.timestamp,
      }
    : { text: "Click here to start the chat", timestamp: "" };
};

export const getNameByChoiceId = (profileChoice: any) => {
  if (profileChoice.value !== 1) {
    return null;
  }
  const illnesses = Object.values(mappingJSON).flat();
  return illnesses.find(
    (illness) => profileChoice.choiceId === illness.choiceId
  );
};
