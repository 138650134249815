import React from "react";
import SectionA from "../SectionA/SectionA";
import SectionB from "../SectionB/SectionB";
import SectionC from "../SectionC/SectionC";
import SectionD from "../SectionD/SectionD";

const Components = {
  sectionA: SectionA,
  sectionB: SectionB,
  sectionC: SectionC,
  sectionD: SectionD,
};

export default (props) => {
  if (typeof Components[props.component] !== "undefined") {
    return React.createElement(Components[props.component], {
      key: props.choiceId,
      data: props,
    });
  }
  return React.createElement(
    () => <div>The component {props.component} has not been created yet.</div>,
    { key: props._uid }
  );
};
