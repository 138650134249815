import React from "react";

import styles from "./ChatContainer.module.scss";

import Container from "../../components/Container/Container";

interface ChatContainerProps {
  isChatMessenger?: boolean;
  children: any;
}

const ChatContainer: React.FC<ChatContainerProps> = ({
  isChatMessenger,
  children,
}) => {
  return isChatMessenger ? (
    <div className={styles.container}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  ) : (
    <Container
      wrapperStyles={{
        overflow: "hidden",
        paddingTop: "0",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      }}
      backgroundColor={"#fff"}
    >
      {children}
    </Container>
  );
};

export default ChatContainer;
