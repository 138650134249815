import React from "react";
import styles from "./Slider.module.scss";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import classnames from "classnames";

const SliderInput = ({
  backgroundColor,
  showStep,
  verbiage,
  title,
  showButton,
  history,
  useMarks = false,
  marks,
  borderBottom = false,
  verbiageFontSize,
  step,
  legend,
  legendStyles,
  legendTextStyles,
  width,
  labelStyles,
  sliderColor = "#1D918B",
  labelResponsive,
  sliderStyle,
  titleStyles,
  verbiageStyles,
  bottomLegend,
  ...rest
}) => {


  const PrettoSlider = withStyles({
    root: {
      color: sliderColor,
      width: width ? width : "100%",
      fontSize: 5,
      marginTop: "-10px",
    },
    thumb: {
      backgroundColor: "#1D918B",
      top: "-6px",
      width: "24px",
      height: "23px",
      borderRadius: "5px",
      position: "relative",
      "&:after": {
        display: "block",
        position: "relative",
        top: "13px",
        left: 0,
        content: "",
        borderLeft: "12px solid transparent",
        borderRight: "12px solid transparent",
        borderTop: "7px solid #1D918B",
        borderRadius: "5px",
      },
    },
    markLabel: {
      ...labelStyles,
      ...labelResponsive,
      marginTop: "10px",
      fontSize: "11px",
    },
    rail: {
      backgroundColor: "#ddd",
    },
  })(Slider);

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.sliderContainer, {
          [styles.borderBottom]: borderBottom,
        })}
      >
        <div className={styles.subContainer} style={sliderStyle}>
          <span className={styles.subTitle} style={{ ...titleStyles }}>{title}</span>
          <span
            className={styles.verbiage}
            style={{ fontSize: verbiageFontSize, ...verbiageStyles }}
          >
            {verbiage}
          </span>
          {legend && (
            <div style={{ ...legendStyles }}>
              {legend.map((legend, index) => {
                return (
                  <span style={{ ...legendTextStyles }} key={index}>
                    {legend}
                  </span>
                );
              })}
            </div>
          )}
          <PrettoSlider marks={marks} {...rest} step={step} />
          {bottomLegend && (
            <div style={{ ...legendStyles }}>
              {bottomLegend.map((text, index) => (
                <span key={index} style={{ ...legendTextStyles }}>
                  {text}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SliderInput;
