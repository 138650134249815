import { Typography } from "@material-ui/core";
import React from "react";
import Questionnaire from "../Questionnaire/Questionnaire";

const GettingAdvancedDirectivesInOrder = () => {
  return (
    <div>
      <Typography
        style={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600,
          fontSize: "17px",
          lineHeight: "24px",
          letterSpacing: "0px",
          textAlign: "left",
          color: "#146E6C",
          marginTop: "40px",
        }}
      >
        <Questionnaire />
      </Typography>
    </div>
  );
};

export default GettingAdvancedDirectivesInOrder;
