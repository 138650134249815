import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Step from "../../components/Step/Step";
import ListItem from "../../components/ListItem/ListItem";
import StepTwoModal from "../../components/StepTwoModal/StepTwoModal";
import Container from "../../components/Container/Container";
import Overlay from "../../components/Overlay/Overlay";
import ProceedButton from "../../components/ProceedButton/ProceedButton";

import { getProfile, putProfile } from "../../services/profile-service";
import { findJsonData, syntaxify } from "../../utils/helpers.util";
import { homecareStep2 } from "../../Json_Templates/Homecare/combinedHomecareImports";

import Toastify, {
  successToast,
  errorToast,
} from "../../components/Toastify/Toastify";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const mappingJSON = {
  ...homecareStep2,
};

const HomecareStepTwo = ({ history, userData }) => {
  const [profileData, setProfileData] = useState([]);
  const [userProfile, loadProfile] = useState([]);
  useEffect(() => {
    getProfile().then(({ data }) => {
      const userProfile = data.find(
        (user) => user.profileId === userData.profileId
      );
      loadProfile(userProfile.choices);
      setProfileData(userProfile);
    });
  }, []);

  const [isPanelOpen, setPanel] = useState(false);
  const [left, setLeft] = useState({});
  const [homeCareOptions, setHomeCareOptions] = useState(null);
  const [overlay, setOverlay] = useState(mappingJSON["Driving"]);

  let savedOptions = [];
  let categories = [];
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [homecareSelection, setHomecareSelection] = useState([]);

  const getServerData = userProfile.reduce((matches, remote) => {
    remote.choiceId === findJsonData(remote.choiceId, mappingJSON) &&
      matches.push(remote);
    return matches;
  }, []);
  if (getServerData.length > 0) {
    getServerData.forEach((remote) => {
      const category = findJsonData(remote.choiceId, mappingJSON, true);
      savedOptions = [
        ...savedOptions,
        {
          choice: category,
          choiceId: remote.choiceId,
          value: remote.value,
        },
      ];
    });
  }

  const [profileError, catchError] = useState();
  const saveProfile = async () => {
    let response;
    const choices = syntaxify(homecareSelection, "homecare");
    try {
      response = await putProfile(userData.profileId, choices);
      response.status === 200 && successToast();
    } catch (err) {
      errorToast();
      catchError(err);
    }
  };

  useEffect(() => {
    if (Object.keys(history.location.state.homeCareUserSelection).length > 0) {
      setHomeCareOptions(history.location.state.homeCareUserSelection);
    }
  }, []);

  useEffect(() => {
    if (userProfile.length > 0) {
      setCheckBoxes([...savedOptions]);
    }
    setHomecareSelection([...savedOptions]);
  }, [userProfile]);

  const getLibraryOptions = (isChecked, option) => {
    const checkboxItem =
      homecareSelection.length > 0 &&
      homecareSelection.find((item) => item.choiceId === option.choiceId);

    if (isChecked && !!checkboxItem) {
      checkboxItem.value = 1;
    } else {
      isChecked
        ? setHomecareSelection([
            ...homecareSelection,
            {
              choice: overlay.pageContent[0].title,
              choiceId: option.choiceId,
              value: 1,
            },
          ])
        : !!checkboxItem
        ? (checkboxItem.value = 0)
        : setHomecareSelection([
            ...homecareSelection,
            {
              choice: overlay.pageContent[0].title,
              choiceId: option.choiceId,
              value: 0,
            },
          ]);
    }

    if (isChecked) {
      setCheckBoxes([
        ...checkBoxes,
        {
          choice: overlay.pageContent[0].title,
          choiceId: option.choiceId,
          value: 1,
        },
      ]);
    } else {
      setCheckBoxes(
        checkBoxes.filter((item) => item.choiceId !== option.choiceId)
      );
    }
  };

  return (
    <Header showGauge={false} isSticky={false}>
      <Toastify />
      <Container
        setLeft={!isPanelOpen}
        style={left}
        isButtonShown
        isFixed={false}
        wrapperStyles={{
          display: "flex",
          flexDirection: "column",
        }}
        buttonProps={{
          onClick: () => {
            recordEvent(
              Events.Categories.homecare,
              Events.Actions.finish,
              Events.Labels.stepTwo
            );
            history.push("/Homecare/Step3", {
              params: history.location.state.homeCareUserSelection,
              homecareSelection,
            });
          },
          title: "PROCEED TO STEP 3",
        }}
      >
        <div>
          <Step
            stepCount={"2"}
            title={"Your Options"}
            iconName={"homecare"}
            verbiage={
              "Based on what you are telling us, let’s look at next steps for your loved one’s home care issues."
            }
          />
          {homeCareOptions &&
            Object.values(homeCareOptions).map((homeCareOption, index) => {
              categories = homecareSelection.filter(
                (selection) => selection.choice === homeCareOption.title
              );

              const isChecked = categories.filter(
                (category) => category.value === 1
              );

              if (homeCareOption.value > 10)
                return (
                  <ListItem
                    title={homeCareOption.title}
                    isChecked={isChecked.length > 0}
                    renderIsCheckedIcon={true}
                    onClick={() => {
                      setOverlay(
                        mappingJSON[homeCareOption.title.split(" ").join("")]
                      );
                      setPanel(!isPanelOpen);
                      const off = isPanelOpen ? "0" : "1";
                      setLeft({
                        width: "50%",
                        leftOn: { off },
                      });
                    }}
                    key={`Homecare-${index}`}
                  />
                );
            })}
        </div>
      </Container>
      <Overlay in={isPanelOpen}>
        <div>
          {overlay.pageContent.map((option, index) => {
            const checkValue =
              checkBoxes.length > 0 &&
              checkBoxes.find((saved) => saved.choiceId === option.choiceId);

            return index === 0 ? (
              <StepTwoModal
                backgroundColor={"#FAF7DC"}
                isHeader
                isEdit
                onClick={() => {
                  setPanel(!isPanelOpen);
                  const off = isPanelOpen ? "0" : "1";
                  setLeft({ width: "50%", leftOn: { off } });
                }}
                {...option}
                key={`HomecareOption-${index}`}
              />
            ) : (
              <StepTwoModal
                backgroundColor={index % 2 === 0 ? "#FAF7DC" : "#fff"}
                titleColor={"#1D918B"}
                {...option}
                checkLibrary={checkValue && checkValue.value}
                defaultValue={false}
                hasCheckedLibrary={(isChecked) => {
                  getLibraryOptions(isChecked, option);
                }}
                key={`HomecareOption-${index}`}
                optionHeader={
                  index === 1 && `Select one or all the options below:`
                }
              />
            );
          })}
          <ProceedButton
            title={`SAVE ${overlay.pageContent[0].title.toUpperCase()} OPTIONS`}
            onClick={() => {
              saveProfile();
              setPanel(!isPanelOpen);
              const off = isPanelOpen ? "0" : "1";
              setLeft({ width: "50%", leftOn: { off } });
            }}
          />
        </div>
      </Overlay>
    </Header>
  );
};

export default HomecareStepTwo;
