import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Step from "../../components/Step/Step";
import ListItem from "../../components/ListItem/ListItem";
import Accordion from "../../components/Accordion/Accordion";
import MultipleChoice from "../../components/MultipleChoice/MultipleChoice";
import Container from "../../components/Container/Container";
import styles from "./Healthcare-step-two.module.scss";
import { getProfile } from "../../services/profile-service";
import { HealthCareStepTwoJSON } from "../../Json_Templates/Healthcare/healthcare.ts";
import { getNameByChoiceId } from "../../utils/helpers.util";
import { removeWhiteSpaceUpperCaseWord } from "../../utils/string-util";
import { putProfile } from "../../services/profile-service";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const mappingJSON = {
  ...HealthCareStepTwoJSON,
};

let healthCareSelection = [];

const HealthCareStepTwo = ({ userData, history }) => {
  const [userSelectedLibraryValues, setUserSelectedLibraryValues] = useState(
    userData.profileChoices || []
  );
  const [diagnosesForValidation, setDiagnosesForValidation] = useState([]);
  const [sliderValue, setSliderValue] = useState({
    choiceId: null,
    value: 0,
    category: "healthcare",
  });
  const [savedChoices, setSavedChoices] = useState([]);
  const isNewUser = userData.profileChoices.filter(item => item.category === "isConversationsCompleted").find(e => e.value === 0) == undefined;


  useEffect(() => {
    setUserSavedSelection();
    setUserSelectedLibraryValues(userData.profileChoices);
    if (!userData.isLoading) setUserSelectedIllnesses();
  }, [userData.isLoading]);

  const setUserSelectedIllnesses = async () => {
    const response = await getProfile();

    console.log(response)

    const validation = response.data[0].choices
      .map((profileChoice) => getNameByChoiceId(profileChoice))
      .filter((diagnosis) => diagnosis)
      .map((diagnosis) => {
        return {
          name: diagnosis.text,
          choiceId: diagnosis.choiceId,
        };
      });

    setDiagnosesForValidation(validation);
  };

  const setUserSavedSelection = async () => {
    if (sliderValue.value > 0) {
      setSliderValue({
        choiceId: null,
        value: 0,
        category: "healthcare",
      });
    }
    const response = await getProfile();

    healthCareSelection = response.data[0].choices.filter(
      (element) => element.category === "healthcare"
    );

    setSavedChoices(healthCareSelection);
  };


  const renderAccordion = (diagnosis) => {
    return (
      <Accordion
        item={diagnosis}
        renderBody={() => renderAccordionBody(diagnosis)}
        plankColor={"#FAF7DC"}
        plankFont={"#4A4A4A"}
        expandedPlankColor={"#FAF7DC"}
        expanded={true}
        arrowColor={'#D2E2C7'}
        showArrow={true}
      />
    );
  };

  const handleOnClick = async () => {
    try {
      //@ts-ignore
      const response = await putProfile(userData.profileId, [
        {
          choiceId: "6F9619FF-8B86-D011-B42D-00C04FC96400",
          // Understanding Advanced Illnesses == 0
          value: 0,
          category: "isConversationsCompleted"
        },
      ]);

      if (response.status === 200) {
        let labelArray = [];
        labelArray.push(`NewUser: ${isNewUser}`)
        const userPath = history.location.state.userPath;
        Object.keys(userPath).forEach((key, index) => {
          labelArray.push(`${key}: ${Object.values(userPath)[index]}`)
        })
        const labelString = labelArray.join("; ")
        console.log("labelString", labelString)
        recordEvent(
          Events.Categories.healthcare,
          Events.Actions.submitDiagnosis,
          labelString
        )
        history.push("/HomeScreen");
      }
    } catch (err) { }
  };


  const renderAccordionBody = (diagnosis) => {
    return (
      <div style={{ backgroundColor: "#fff", height: "100%" }}>
        {
          <MultipleChoice
            items={[
              `What is ${diagnosis}?`,
              "How your loved one’s condition may progress",
              `Advanced illness and palliative care ${diagnosis}`,
            ]}
            overlay={mappingJSON[removeWhiteSpaceUpperCaseWord(diagnosis)]}
          />
        }
      </div>
    );
  };





  return (
    <>
      <Container
        setLeft={true}
        wrapperStyles={{
          display: "flex",
          flexDirection: "column",

        }}
        isButtonShown
        isFixed={false}
        buttonProps={{
          title: "PROCEED TO CONVERSATIONS",
          marginTop: '0px',
          zIndex: 40,
          onClick: () => {
            handleOnClick();
          },
        }}
        buttonTitle="FINISH"
        buttonOnClick={handleOnClick}
      >
        <Header showGauge={false} isSticky={false} isConversationPath={false} />
        <div>
          <Step
            stepCount={"2"}
            verbiage={
              "Learning About the Diagnosis Let’s look at how your loved one’s illness will progress so you can prepare. Psi will help you see what stage the illness is at and how to talk with your loved one's doctor, and your family members."
            }
            fontSize={15}
            title={"Learning about the Diagnosis"}
            iconName={"healthcare"}
            label={"Healthcare"}
            stepContainerStyles={{
              backgroundColor: "#fff",
              marginTop: 40,
              marginBottom: 0,
              paddingTop: 20,
              paddingBottom: 20,
              zIndex: 10,
            }}
          />
          {diagnosesForValidation.map((diagnosis, index) => {
            return (
              <div className={styles.accordionContainer} key={index}>
                <ListItem
                  title={`Diagnosis #${index + 1}`}
                  titleColor={"#fff"}
                  backgroundColor={"#1D918B"}
                  renderIsCheckedIcon={false}
                />
                {renderAccordion(diagnosis.name, index)}
              </div>
            );
          })}
        </div>

      </Container>
    </>

  );
};

export default HealthCareStepTwo;
