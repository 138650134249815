import React from "react";
import styles from "./WhiteSectionContainer.module.scss";
interface WhiteSectionContainerProps {
  children: React.ReactNode;
}

const WhiteSectionContainer: React.FC<WhiteSectionContainerProps> = ({
  children,
}) => {
  return <div className={styles.container}>{children}</div>;
};

export default WhiteSectionContainer;
