import React from "react";
import PsiLogo from "../../components/PsiLogo/PsiLogo";
import { IoIosClose } from "react-icons/io";
import styles from "./StepTwoModal.module.scss";

import { createMarks } from "../../utils/slider.util";

import LabelParagraph from "../../components/labelParagraph/labelParagraph";
import Accordion from "../Accordion/Accordion";
import {
  TextContainer,
  CheckBoxContainer,
} from "../../components/TextContainer/TextContainer";
import Slider from "../Slider/Slider";
import AddToLibrary from "../AddToLibrary/AddToLibrary";

const StepTwoModal = ({
  onClick,
  title,
  backgroundColor,
  option,
  optionTitle,
  optionHeader,
  isHeader = false,
  paragraphs = [],
  listOrdered = false,
  list = [],
  labelParagraphs = [],
  titleColor,
  renderBody,
  accordionContent,
  checkBoxQuestions,
  checkBoxVerbiage,
  renderSlider,
  sliderVerbiage,
  sliderToggleItems,
  sliderToggleVerbiage,
  renderAddToLibrary,
  hasCheckedLibrary,
  checkLibrary,
  defaultValue,
  isCheckboxChecked,
  index,
  sliderCallback,
  handleOnChangeCheckBox,
  sliderValue,
  indexesToCheck,
}) => {
  const renderStageSlider = (props, sliderValue, toggle) => {
    const renderToggle = () => {
      return (
        sliderValue === 100 && (
          <>
            <TextContainer {...toggle.sliderToggleItems} />
            <StepTwoModal {...toggle.sliderToggleVerbiage} />
          </>
        )
      );
    };

    return (
      <StepTwoModal
        {...props}
        renderBody={() => {
          const legend = ["Early", "Middle", "Advanced"];

          return (
            <div
              className={styles.sliderContainer}
              style={{ padding: "0 20px" }}
            >
              <div style={{ marginRight: "50px", marginBottom: 20 }}>
                <Slider
                  marks={createMarks(legend)}
                  step={null}
                  sliderColor={"#3DADA2"}
                  onChangeCommitted={(event, value) => {
                    sliderCallback && sliderCallback(value);
                  }}
                  defaultValue={sliderValue}
                />
              </div>
              {renderToggle()}
            </div>
          );
        }}
      />
    );
  };

  const renderList = (list) => {
    return list.map((li, index) => {
      return (
        <li className={styles.paragraph} key={`list-${index}`}>
          {li}
        </li>
      );
    });
  };

  const renderAccordion = (props) => {
    return (
      <Accordion
        plankColor="#F8F3BF"
        plankFont="#000"
        expandedPlankColor="#F8F3BF"
        {...props}
        renderBody={(li) => {
          return <p>{li}</p>;
        }}
      />
    );
  };

  const renderCheckBoxes = (selectedItems, items, verbiage) => {
    const questions = selectedItems.question.map((item) => item.text);
    return (
      <div>
        <div style={{ margin: "0 13px" }}>
          <CheckBoxContainer
            items={questions}
            backgroundColor={"#fff"}
            onChange={(e, index) => {
              handleOnChangeCheckBox(
                selectedItems.question[index],
                index,
                e.target.name,
                e.target.checked
              );
            }}
            checked={isCheckboxChecked}
            indexesToCheck={indexesToCheck}
          />
        </div>
        <StepTwoModal {...verbiage} />
      </div>
    );
  };

  return (
    <div style={{ backgroundColor, paddingBottom: 0 }}>
      <div className={styles.container}>
        <div className={styles.header}>
          {isHeader && (
            <div className={styles.headerContainer}>
              <PsiLogo height={30} />
              <IoIosClose
                name="IoIosClos"
                size={30}
                height={30}
                s
                onClick={onClick}
                style={{ color: "#188781" }}
              />
            </div>
          )}
        </div>
        {<p className={styles.optionHeader}>{optionHeader}</p>}
        {option && <span className={styles.option}>OPTION {option}</span>}
        <div className={styles.subHeader}>
          <span
            className={styles.title}
            style={{ color: titleColor ? titleColor : null }}
          >
            {title}
          </span>
        </div>
        <div className={styles.content}>
          {paragraphs.map((p, index) => {
            return (
              <p className={styles.paragraph} key={`Paragraph-${index}`}>
                {p}
              </p>
            );
          })}
          {list && listOrdered ? (
            <ol>{renderList(list)}</ol>
          ) : (
            <ul>{renderList(list)}</ul>
          )}
          {labelParagraphs &&
            labelParagraphs.map((p, index) => (
              <LabelParagraph {...p} key={`labelP-${index}`} />
            ))}
        </div>
        {renderBody && renderBody()}

        {accordionContent && renderAccordion(accordionContent)}
        {index === 2 &&
          renderCheckBoxes(checkBoxQuestions, checkBoxVerbiage, sliderValue)}

        {renderSlider &&
          renderStageSlider(sliderVerbiage, sliderValue, {
            sliderToggleItems,
            sliderToggleVerbiage,
          })}
        {renderAddToLibrary && (
          <AddToLibrary
            defaultValue={defaultValue}
            check={checkLibrary && checkLibrary}
            hasChecked={(status) =>
              hasCheckedLibrary && hasCheckedLibrary(status)
            }
          />
        )}
      </div>
    </div>
  );
};

export default StepTwoModal;
