import React, { useState } from "react";

import Device from "../../utils/responsive.util";

import Step from "../../components/Step/Step";
import styles from "./Homecare-step-three.module.scss";
import Header from "../../components/Header/Header";
import Icon from "../../components/Icon/Icon";
import IconHolder from "../../components/IconHolder/IconHolder";
import { default as OverlayHeader } from "../../components/StepTwoModal/StepTwoModal";
import ProceedButton from "../../components/ProceedButton/ProceedButton";
import Container from "../../components/Container/Container";
import Overlay from "../../components/Overlay/Overlay";
import StepThreeModal from "../../components/StepThreeModal/StepThreeModal";
import { homecareStep3 } from "../../Json_Templates/Homecare/combinedHomecareImports";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";

const mappingJSON = {
  ...homecareStep3,
};

const screen = Device();
const isPhone = screen.isPhone(screen.getW());

const HomecareStepThree = ({
  history,
  location: {
    state: { params },
  },
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [left, setLeft] = useState({});

  const [overlay, setOverlay] = useState(mappingJSON["Driving"]);

  return (
    <Header showGauge={false} isSticky={false}>
      <Container
        setLeft={!isPanelOpen}
        style={{ ...left, containerBackground: isPhone ? "#F8F6DA" : "#FFF" }}
        isButtonShown
        isFixed={false}
        wrapperStyles={{
          display: "flex",
          flexDirection: "column",
        }}
        buttonProps={{
          onClick: () => {
            recordEvent(
              Events.Categories.homecare,
              Events.Actions.finish,
              Events.Labels.stepThree
            );
            history.push("/HomeScreen");
          },
          title: "SAVE & COMPLETE",
        }}
      >
        <div>
          <Step
            stepCount={"3"}
            title={"Your Plan"}
            verbiage={
              "Now that you have a sense of your family’s situation, you can use this planner for your next steps."
            }
            color={"#8E8E93"}
            paddingTop={2}
            iconName={"homecare"}
          />
          <div className={styles.iconHolderWrapper}>
            {Object.keys(params).map((item) => {
              return (
                params[item].value > 10 && (
                  <IconHolder
                    title={item}
                    onClick={() => {
                      setOverlay(mappingJSON[item.split(" ").join("")]);
                      setPanelOpen(!isPanelOpen);
                      const off = isPanelOpen ? "0" : "1";
                      setLeft({
                        width: "50%",
                        leftOn: { off },
                      });
                    }}
                    key={item.split(" ").join("")}
                  >
                    <Icon icon={item.toLowerCase().replace(/[\s\,]/g, "")} />
                  </IconHolder>
                )
              );
            })}
          </div>
        </div>
      </Container>
      <Overlay in={isPanelOpen}>
        <div>
          <OverlayHeader
            isHeader={true}
            backgroundColor={"#EAEAEA"}
            onClick={() => {
              setPanelOpen(!isPanelOpen);
              const off = isPanelOpen ? "0" : "1";
              setLeft({
                width: "50%",
                leftOn: { off },
              });
            }}
          />
          <StepThreeModal
            onClick={() => {
              setPanelOpen(!isPanelOpen);
              const off = isPanelOpen ? "0" : "1";
              setLeft({ width: "50%", leftOn: { off } });
            }}
            renderTitle={{
              icon: overlay.icon,
              title: overlay.title,
            }}
            content={overlay.pageContent}
          />
          <ProceedButton
            title={overlay.buttonText}
            onClick={() => {
              setPanelOpen(!isPanelOpen);
              const off = isPanelOpen ? "0" : "1";
              setLeft({ width: "50%", leftOn: { off } });
            }}
          />
        </div>
      </Overlay>
    </Header>
  );
};

export default HomecareStepThree;
