import React, { useState } from "react";
import styles from "./SPBusinesses.module.scss";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
//category, zipcode, cities, lat, lng
interface SPBusinessesProps {
  category?: string;
  provider: {
    category?: string;
    zipcode?: string | null;
    cities?: string[];
    businessName: string;
    lat?: number;
    lng?: number;
    website: string | null;
    phone: string | null;
    logo: string | null;
    imgWidth?: number;
  };
  index: number;
  imgWidth?: number;
  height?: number;
}

const SPBusinesses: React.FC<SPBusinessesProps> = ({
  category,
  provider,
  imgWidth,
  height,
}) => {
  console.log("provider", provider);
  const [src, setSrc] = useState("");
  import(`../../assets/providerLogos/${provider.logo}`)
    .then((image) => setSrc(image.default))
    .catch((error) => console.error("Error loading image:", error));
  return (
    <div className={styles.providerContainer}>
      <div className={styles.providerInfoWrapper}>
        <span className={styles.businessNameText}>{provider.businessName}</span>
        <div className={styles.iconWrapper}>
          {provider.logo && (
            <div className={styles.logoWrapper}>
              {provider.logo && provider.logo.includes("https") ? (
                <img
                  className={styles.logo}
                  src={`${provider.logo}`}
                  alt="logo"
                  width={imgWidth}
                  height={height ? height : "auto"}
                />
              ) : (
                <img
                  className={styles.logo}
                  src={src}
                  alt="logo"
                  width={imgWidth}
                  height={height ? height : "auto"}
                />
              )}
            </div>
          )}

          <div className={styles.website}>
            <a
              href={`http://${provider.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LanguageIcon />
              <p className={styles.providerText}>WEBSITE</p>
            </a>
          </div>

          <div className={styles.phone}>
            <a
              href={`tel://${provider.phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PhoneIcon />
              <p className={styles.providerText}>CALL</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SPBusinesses;
