import React from "react";
import SectionContainer from "../SectionContainer/SectionContainer";

import { renderComponents } from "../../utils/json-content-renderer";

interface SectionAProps {
  data: {
    onClick: () => void;
    backgroundColor: string;
  };
}

const SectionA: React.FC<SectionAProps> = ({ data }) => {
  return (
    <>
      <SectionContainer backgroundColor={data.backgroundColor}>
        {renderComponents(data)}
      </SectionContainer>
    </>
  );
};

export default SectionA;
