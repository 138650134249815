import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import ConversationsJson from "../../Json_Templates/ComfortCare/ComfortCare.json";
import { Typography } from "@material-ui/core";

import Button from "../../components/Button/Button";
import { useStyles } from "../Conversations/styles/conversation-styles";
import ConversationTitle from "../../components/ConversationTitle/ConversationTitle";

export default function ConversationsWithDoctorStep3({ history }: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* @ts-ignore */}
      <Header showProgressBar isSticky>
        <div style={{ maxWidth: "90%", margin: "34px auto", height: "100vh" }}>
          <ConversationTitle title={"Options for palliative care"} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "21px",
              letterSpacing: "-0.07px",
              textAlign: "left",
              color: "#1D918B",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            {ConversationsJson.description}
          </Typography>
          <Container
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              backgroundColor: "#FAF7DC",
            }}
            containerStyles={{
              position: "static",
              width: "100%",
              backgroundColor: "#FAF7DC",
            }}
            responsiveBackgroundColor="#FAF7DC"
          >
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              margin={1}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
            >
              {ConversationsJson.content.titleParagraph.map((conversation) => {
                return (
                  <>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: 600,
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#188781",
                        opacity: 1,
                        marginTop: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      {conversation.title}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        lineHeight: "17px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#4A4A4A",
                        opacity: 1,
                      }}
                    >
                      {conversation.paragraphs.map((paragraph) => {
                        return <p>{paragraph}</p>;
                      })}
                    </Typography>
                  </>
                );
              })}
            </Box>
          </Container>
          <Container
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              backgroundColor: "#FAF7DC",
            }}
            containerStyles={{
              position: "static",
              width: "100%",
              marginTop: "18px",
              backgroundColor: "#FAF7DC",
            }}
            responsiveBackgroundColor="#FAF7DC"
          >
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              margin={1}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
            >
              {ConversationsJson.secondContent.titleParagraph.map(
                (conversation) => {
                  return (
                    <>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontWeight: 600,
                          letterSpacing: "0px",
                          textAlign: "left",
                          color: "#188781",
                          opacity: 1,
                          marginTop: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {conversation.title}
                      </Typography>

                      {conversation.paragraphs.map((paragraph, index) => {
                        return (
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "13px",
                              lineHeight: "17px",
                              letterSpacing: "-0.3px",
                              color: "#4A4A4A",
                              textAlign: "left",
                              opacity: 1,
                              paddingBottom: "5px",
                            }}
                          >
                            {paragraph}
                          </Typography>
                        );
                      })}
                    </>
                  );
                }
              )}
            </Box>
          </Container>

          <Box
            display={"flex"}
            justifyContent="center"
            width={"100%"}
            marginTop={5}
            marginBottom={5}
          >
            <Button
              title="Next"
              type="button"
              onClick={() => history.push("/Conversations/WithFamily/Step3b")}
            />
          </Box>
        </div>
      </Header>
    </div>
  );
}
