import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../components/Button/Button";
import { createAccount } from "../../services/auth-service";
import { loginUser } from "../../services/auth-service";
import styles from "./CreateAccount.module.scss";

import InputFieldSet from "../../components/InputFieldSet/InputFieldSet";
import Modal from "../../components/Modal/Modal";
import Container from "../../components/Container/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";

import * as Events from "../../Types/event-types";
import recordEvent from "../../utils/recordEvent";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";

let userModel = {};
const CreateAccount = ({ history }) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [error, setErrorMessage] = useState();
  const [isError, setErrorState] = useState();

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isButtonDisabled) {
      createUserAccount();
    }
  };

  const handleInputChange = (e) => {
    userModel[e.target.name] = e.target.value;

    if (
      userModel.Email &&
      userModel.FirstName &&
      userModel.LastName &&
      userModel.Password &&
      userModel.PhoneNumber &&
      userModel.ConfirmPassword
    ) {
      setButtonDisabled(false);
    }
  };

  const createUserAccount = async () => {
    setButtonDisabled(true);

    userModel.CustomerId = history.urlKey;

    let response;
    try {
      response = await createAccount(userModel);

      if (!response || response.status !== 200) {
        throw new Error(
          "Did not get a successful response from the create account API"
        );
      }

      recordEvent(Events.Categories.createAccount, Events.Actions.newUser);

      await loginUser(userModel.Email, userModel.Password);

      history.push("/OnBoarding/Step2", {
        firstName: userModel.FirstName,
        lastName: userModel.LastName,
      });
    } catch (error) {
      console.error(error);

      let message = "Oops! An unexpected error occurred.";
      if (error && error.data) {
        if (error.data.ModelState) {
          message = Object.values(error.data.ModelState).flat()[0];
        } else if (error.data.Message) {
          message = error.data.Message;
        }
      }
      setErrorState(true);
      setErrorMessage(message);
      setButtonDisabled(false);
    }
  };
  const handleModalClose = () => {
    setErrorState(false);
    setErrorMessage("");
  };

  return (
    <Container
      style={{ containerPadding: "50px" }}
      containerStyles={{
        height: "100vh",
        width: "100vw",
        position: "relative",
        top: null,
      }}
      wrapperStyles={{ backgroundColor: "#fff" }}
    >
      <Modal open={isError} message={error} handleClose={handleModalClose} />
      <div className={styles.wrapper}>
        <Header showGauge={false} isConversationPath={false} hideHamburger />
        <Banner
          iconName={"account-setup"}
          history={history}
          color="#FAF7DC"
          textColor="#146E6C"
          showArrow={false}
        />
        <div className={styles.container}>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <InputFieldSet
              name="Email"
              onChange={handleInputChange}
              title={"Email Address"}
              fullWidth
              type="email"
              autoComplete="false"
            />
            <InputFieldSet
              name="FirstName"
              onChange={handleInputChange}
              title={"First Name"}
              autoComplete="false"
              fullWidth
            />
            <InputFieldSet
              name="LastName"
              onChange={handleInputChange}
              title={"Last Name"}
              autoComplete="false"
              fullWidth
            />
            <InputFieldSet
              name="PhoneNumber"
              onChange={handleInputChange}
              title={"Phone Number"}
              autocomplete="password"
              placeholder="(123) 456-7890"
              fullWidth
            />
            <InputFieldSet
              name="Password"
              onChange={handleInputChange}
              title={"Create Secure Password"}
              fullWidth
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <LockIcon style={{ color: "#cccccc" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment>
                    <VisibilityIcon
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ color: "#9CBF85", cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <InputFieldSet
              name="ConfirmPassword"
              onChange={handleInputChange}
              title={"Confirm Password"}
              fullWidth
              autoComplete="password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <LockIcon style={{ color: "#cccccc" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <VisibilityIcon
                      style={{ color: "#9CBF85", cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <div
              className={styles.buttonWrapper}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                width={"100vw"}
                height={49}
                title={"SETUP ACCOUNT"}
                isDisabled={isButtonDisabled}
              />
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default CreateAccount;
