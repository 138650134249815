import React, { useContext } from "react";
import { UserDataContext } from "../../Context/Auth-Provider";
import Step from "../Step/Step";
import Stage from "../Stage/Stage";
import { getLibraryOptionNumber } from "../../utils/helpers.util";
import { removeWhiteSpaceUpperCaseWord } from "../../utils/string-util";
import { renderComponents } from "../../utils/json-content-renderer";
import SectionContainer from "../SectionContainer/SectionContainer";
import styles from "./StepThreeModal.module.scss";
import Icon from "../../components/Icon/Icon";

type RenderInBox = {
  title: string;
  text: string;

  list: Array<{
    title: string;
    paragraph: string[];
    option?: string;
  }>;

  paragraph: string;
};

type list = {
  title: string;
  paragraph: string[];
  [key: string]: string | string[];
};

type content = {
  title: string;
  paragraph: string[];
  renderInBox: RenderInBox[] & RenderInBox;
};

interface StepThreeModalProps {
  stepTitle?: string;
  label?: string;
  stepCount?: number;
  stepIconName?: string;
  stepVerbiage?: string;
  currentStage: number;
  content: any;
  isList?: boolean;
  renderTitle?: {
    title: string;
    icon?: string;
  };
  displayTitle?: boolean;
  category?: string;
  onClick?: () => void;
}

let StepThreeModal: React.FC<StepThreeModalProps> = ({
  stepTitle,
  label,
  stepCount,
  stepIconName,
  stepVerbiage,
  currentStage,
  content,
  renderTitle,
  displayTitle = true,
}) => {
  const userProfile = useContext(UserDataContext);
  const getLibraryOptionsForHomecareAndInsurance = () => {
    const libraryOptions = getLibraryOptionNumber(
      //@ts-ignore
      userProfile.profile.profileChoices,
      "homecare"
    );

    if (libraryOptions && libraryOptions.length > 0) {
      const librarySelectedOptions = libraryOptions
        .filter((libraryOption) => {
          return (
            libraryOption &&
            renderTitle &&
            removeWhiteSpaceUpperCaseWord(libraryOption.name) ===
              removeWhiteSpaceUpperCaseWord(renderTitle.title)
          );
        })
        .sort((a, b) => (a!.option < b!.option ? -1 : 1));

      if (librarySelectedOptions) {
        const userSelectedLibraryOptions = librarySelectedOptions.map(
          (librarySelectedOption) => {
            if (librarySelectedOption) {
              const index = parseInt(librarySelectedOption["option"]);
              return {
                optionNumber: index,
                paragraphs: [content[0].OptionParagraph[index - 1]],
              };
            }
          }
        );
        return userSelectedLibraryOptions;
      }
    }
  };

  const libraryOptions = getLibraryOptionsForHomecareAndInsurance();

  if (libraryOptions && libraryOptions.length > 0) {
    content[0].OptionParagraphs = libraryOptions;
  }

  return (
    <SectionContainer backgroundColor={"#eaeaea"}>
      {renderTitle && (
        <div className={styles.iconTitleWrapper}>
          {renderTitle.icon && <Icon icon={renderTitle.icon} />}
          {displayTitle && (
            <span className={styles.title}>{renderTitle.title}</span>
          )}
        </div>
      )}
      {stepTitle && (
        /* @ts-ignore */
        <Step
          stepCount={stepCount}
          verbiage={stepVerbiage}
          title={stepTitle}
          iconName={stepIconName}
          label={label}
        />
      )}
      {currentStage && (
        <div style={{ paddingTop: "20px" }}>
          <Stage now={currentStage} />
        </div>
      )}
      {/* @ts-ignore */}
      {content.map(
        //@ts-ignore
        (content) =>
          content && (
            <div className={styles.wrapper}>{renderComponents(content)}</div>
          )
      )}
    </SectionContainer>
  );
};

export default StepThreeModal;
