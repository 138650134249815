import React from "react";
import styles from "./Button.module.scss";
import classnames from "classnames";

interface ButtonProps {
  onClick?: any;
  title: string;
  isDisabled?: boolean;
  width?: string | number | undefined;
  height?: string | number | undefined;
  borderRadius?: string;
  fontColor?: {};
  buttonStyles?: {};
  type: "button" | "submit" | "reset" | undefined;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  title = "start",
  isDisabled,
  width,
  height,
  borderRadius,
  fontColor,
  buttonStyles,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      className={classnames(styles.button, {
        [styles.isDisabled]: isDisabled,
      })}
      style={{
        width: width ? width : undefined,
        height: height ? height : undefined,
        borderRadius: borderRadius ? borderRadius : undefined,
        cursor: isDisabled ? "not-allowed" : "pointer",
        ...buttonStyles,
      }}
      {...rest}
    >
      <span className={styles.buttonTitle} style={{ ...fontColor }}>
        {title}
      </span>
    </button>
  );
};

export default Button;
