import React from "react";
import Device from "../../utils/responsive.util";

import styles from "./ASASMenu.module.scss";

import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import Footer from "../../components/Footer/Footer";

const screen = Device();
const containerMargin = screen.isPhone() ? "auto" : "163px";

const content = () => {
  return <p>content to come...</p>;
};

const contentAbout = () => {
  return (
    <>
      <p>
        Developed by leaders in the field of hospice, palliative care, bereavement, and healthcare delivery , Psī has developed mobile healthcare applications that help families cope with their loved one’s serious illness. Psi's structured conversations model of navigating family caregiving and advanced illness has been rigorously tested throughout the country.
      </p>
      <p>
        For more information about Psī, please contact us at
        <a href="mailto: info@PsiLife.com"> info@PsiLife.com</a>
      </p>
    </>
  );
};

function ASASMenu({ view }) {
  const asas = {
    about: { title: "About Us", content: contentAbout() },
    settings: { title: "Settings", content: content() },
    account: { title: "Account", content: content() },
    support: { title: "Help & Feedback", content: content() },
    admin: { title: "Account Settings", content: content() },
  };
  return (
    <React.Fragment>
      <Container
        style={{
          backgroundColor: "#fff",
          containerBackground: "#fff",
        }}
        wrapperStyles={{
          display: "flex",
          flexDirection: "column",
          height: '100vh'
        }}
      >
        <Header showGauge={false} noBottomBorder={screen.isPhone()} isConversationPath={false} />
        <div className={styles.mainContent}>
          <h4>{asas[view].title}</h4>
          {asas[view].content}
        </div>

      </Container>
      <Footer hideSearchBar={screen.isPhone()} />
    </React.Fragment>
  );
}

export default ASASMenu;
