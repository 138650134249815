import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { putProfile } from "../../services/profile-service";
import styles from "./Healthcare.module.scss";
import Step from "../../components/Step/Step";

import Modal from "../../components/Modal/Modal";
import { modalEmptyState } from "../../utils/ModalError.util";
import HealthcareStepOneJSON from "../../Json_Templates/Healthcare/Step1/HealthcareStepOne.json";
import Container from "../../components/Container/Container";
import HealthcareChoicesWithIds from "../../Json_Templates/Healthcare/Step1/healthcare-diagnosis-choicesId.json";
import { Box } from "@material-ui/core";
import CheckboxesGroup from "../../components/Checkbox/CheckBoxesGroup";

let currentDiagnosis = {};



const HealthcareStepOne = ({ history, userData }) => {
  const [savedChoices, setSavedChoices] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const getUserSavedSelection = () => {
    if (userData.profileChoices) {
      const healthCareSelection = userData.profileChoices.filter(
        (element) =>
          element.category === "healthcare" && element.value === 1
      );

      const savedChoicesFromServer = HealthcareChoicesWithIds.map((element, index) => {
        return healthCareSelection.find((item) => {
          if (item.choiceId === element.choiceId) {
            currentDiagnosis[element.question] =
              HealthcareStepOneJSON[element.question];
            return item;
          }
        });
      }).filter((currentDiagnosis) => currentDiagnosis);

      setSavedChoices(savedChoicesFromServer);
    }
  };



  useEffect(() => {
    getUserSavedSelection();
    return () => {
      if (history.action === "PUSH") {
        currentDiagnosis = {};
      }
    };
  }, [history, userData.isLoading]);



  const handleOnChange = (choiceId) => {

    // Toggling the value of the condition based on the choiceId
    const diagnosisChoice = HealthcareChoicesWithIds.find(choice => choice.choiceId === choiceId);
    const medicalCondition = diagnosisChoice ? diagnosisChoice.question : undefined;

    if (currentDiagnosis[medicalCondition]) {
      currentDiagnosis[medicalCondition] = false;
    } else {
      currentDiagnosis[medicalCondition] = HealthcareStepOneJSON[medicalCondition];
    }


    const existingIndex = savedChoices.findIndex(c => c.choiceId === choiceId);


    if (existingIndex !== -1) {
      const updatedChoices = [...savedChoices];
      updatedChoices.splice(existingIndex, 1);
      setSavedChoices(updatedChoices);
    } else {
      const newChoice = {
        choiceId: choiceId,
        value: 0,
        category: "healthcare",
      };
      setSavedChoices([...savedChoices, newChoice]);
    }
  };



  const [isModalOpen, setModalOpen] = useState(false);
  const handleValidation = async () => {

    const diagnosisToChoiceIdsMapping = {
      "Lung Disease": ["b9fa6823-2d7b-4ec9-8318-c899cfc49175"],
      "Kidney Disease/Diabetes": ["d019f875-0477-4e7f-95e5-c3b87300f989", "76ecab03-24d7-4f18-9638-d14aaa9b708c"],
      "Frailty/General Weakness": ["d71184ca-2419-40c0-8618-be6dc023d175"],
      "Cancer": [
        "c84cf855-ea03-4d28-a46a-b9f4359b426a", "1d1472b0-18ea-471f-a3f2-f0c017fd42a2",
        "7f6f5af8-89a9-48bc-a2bc-cebe01f35af9", "aab95583-b48c-47b3-88bd-0b33b3bde910",
        "fff1dd7d-54b7-4868-ac53-e32ae068d65a", "732c919a-8442-4dff-91f0-4f671fb06a34",
        "c068f7c7-e061-47cb-ba84-8ea070923fc7", "b5354ec5-4097-4d61-9278-ff86799d1cb3",
        "07505141-a190-437a-8a37-fc9519982535",
      ],
      "Heart Disease": [
        "e1a3bc48-b43c-4cc1-ac92-894da412bb1a",
        "4d8791ce-b9d3-4091-a918-9527c53d748c",
        "08665c5b-1484-4254-85a1-1d3b01cf69d8",
        "75f1d43a-4a92-4332-b6e7-ed8c3715d55e"
      ],
      "Alzheimer’s/Dementia/Brain Conditions": [
        "8f9f482b-706e-47a7-9b05-1e95461a5dc9",
        "e2c46554-d1e7-41d7-9770-aa9aca1a41eb",
        "f936596b-ce00-423c-885a-db90334a5441"
      ]
    };

    let choices = [];

    for (let diagnosis in diagnosisToChoiceIdsMapping) {
      if (currentDiagnosis[diagnosis]) {
        console.log({
          category: "healthcare",
          choiceId: lookUpDiagnosisByName(diagnosis).choiceId,
          value: currentDiagnosis[diagnosis] ? 1 : 0
        });
        choices.push({
          category: "healthcare",
          choiceId: lookUpDiagnosisByName(diagnosis).choiceId,
          value: currentDiagnosis[diagnosis] ? 1 : 0
        });
      } else {
        for (let choiceId of diagnosisToChoiceIdsMapping[diagnosis]) {
          choices.push({
            category: "healthcare",
            choiceId: choiceId,
            value: 0
          });
        }
      }
    }
    try {
      const response = await putProfile(userData.profileId, choices);

      if (response.status === 200) {
        for (const key in currentDiagnosis) {
          if (!currentDiagnosis[key]) {
            delete currentDiagnosis[key];
          }
        }
        const userPath = {}
        for(let diagnosis of Object.keys(currentDiagnosis)){
          userPath[diagnosis] = []
        }
        history.push("/Healthcare/Step1/Diagnosis", { currentDiagnosis, userPath });
      }
    } catch (err) { }
  };



  const lookUpDiagnosisByName = (name) => {
    return HealthcareChoicesWithIds.find((obj) => obj.question === name);
  };

  return (
    <Container
      isButtonShown
      wrapperStyles={{
        display: "flex",
        flexDirection: "column",
        height: '100vh',
        backgroundColor: "#FAF7DC"
      }}
      containerStyles={{
        display: "flex",
        flexDirection: "column",
        height: '100vh',
      }}
      buttonProps={{
        title: "CONTINUE",
        onClick: () => handleValidation(),
        zIndex: 40,
      }}
      isButtonDisabled={savedChoices.length === 0}
      buttonTitle="CONTINUE"
      buttonOnClick={() => handleValidation()}

    >
      <div className={styles.iconContainer} style={{ marginTop: '80px' }}>
        <Header isConversationPath={false} />
        <Modal
          open={isModalOpen}
          handleClose={() => setModalOpen(false)}
          message={modalEmptyState}
        />

        <Step
          stepCount={"1"}
          title={"Current Diagnosis"}
          verbiage={
            "Current Diagnosis Take your time, look at the six categories below, and pick your loved one’s medical condition(s). Simply click on the category or categories that you think are their main medical conditions, and then choose whatever sounds familiar."
          }
          iconName={"healthcare"}
          fontSize={15}
          verbiageFontSize={13}
          label={"Understanding Advanced Illness"}
          text={"Understanding your loved one’s medical condition is not as complicated as it may seem. We will help you get information on each of their conditions and guide you through practical next steps."}
        />

        <Box style={{ backgroundColor: '#fff', height: 'auto', margin: '16px', borderRadius: '10px', paddingTop: 1, paddingBottom: 1 }}>
          <CheckboxesGroup
            items={HealthcareChoicesWithIds}
            checked={savedChoices.map(choice => choice.choiceId)} // Assuming savedChoices is an array of objects with a choiceId
            handleOnChange={(event) => handleOnChange(event.target.name)}
            margin="16px"
            padding="0 5px"
          />
        </Box>
      </div>

    </Container>


  );
};

export default HealthcareStepOne;
