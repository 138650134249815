import React, { useState } from "react";

const MenuIcon = ({ icon, style = {} }) => {
  const [src, setSrc] = useState("");
  import(`../../assets/sideBarMenu/${icon}.svg`).then((image) =>
    setSrc(image.default)
  );

  style = { margin: "10px 20px", maxWidth: "22px", height: "auto", ...style };
  return <img src={src} alt="Menu Icon" style={style} />;
};

export default MenuIcon;
