import React, { useContext, useState } from "react";
import styles from "../FooterMenuIcons/FooterMenuIcons.module.scss";
import { useHistory } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import { UserDataContext } from "../../Context/Auth-Provider";
import { BsFillPersonFill } from "react-icons/bs";
import Modal from "../../components/Modal/Modal";
import { serviceAndProvidersJSON } from "../../Json_Templates/ServicesAndProviders/serviceAndProviders";

interface FooterMenuIconsProps {
  active: string;
}

const FooterMenuIcons: React.FC<FooterMenuIconsProps> = ({
  active = "home",
}) => {
  const history = useHistory();
  const home = "home";
  const homeCare = "homecare";
  const library = "library";
  const plans = "plans";
  const chat = "chat";
  const homecareHomeScreen = "homecareHomeScreen";
  const [isModalOpen, setModal] = React.useState(false);

  const user = useContext(UserDataContext);
  const isAdmin = user!.profile.isAdmin;

  return (
    <div className={styles.footerMenuIconsContainer}>
      <Modal
        open={isModalOpen}
        message={() => {
          return (
            <>
              <p>Please select a provider</p>
              <select
                name="providers"
                id="providers"
                //@ts-ignore
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  //@ts-ignore
                  user!.setAdminAffiliation(e.target.value);
                  setModal(false);
                }}
              >
                <option></option>
                {Object.keys(serviceAndProvidersJSON).map((provider, index) => {
                  return <option key={index}>{provider}</option>;
                })}
              </select>
            </>
          );
        }}
        handleClose={() => setModal(false)}
      />
      <div
        className={styles.iconWrapper}
        onClick={() => history.push("/HomeScreen")}
      >
        <Icon icon={home === active ? "homeActive" : home} />
        <span className={styles.footerText}>Home</span>
      </div>
      {/* <div
        className={styles.iconWrapper}
        onClick={() => {
          history.push("/homecareHomeScreen", { tab: 0 });
        }}
      >
        <Icon
          icon={"HomecareHomeScreen" === active ? "homecareActive" : homeCare}
        />
        <span className={styles.footerText}>Homecare</span>
      </div> */}
      <div
        className={styles.iconWrapper}
        onClick={() => history.push("/Library")}
      >
        <Icon icon={library === active ? "libraryActive" : library} />
        <span className={styles.footerText}>Library</span>
      </div>
      {/* <div
        className={styles.iconWrapper}
        onClick={() => history.push("/MyPlans")}
      >
         <Icon icon={plans === active ? "plansActive" : plans} />
        <span className={styles.footerText}>My Plans</span> 
      </div>  */}
      <div
        className={styles.iconWrapper}
        onClick={() => {
          history.push("/Dashboard", { tab: 0 });
        }}
      >
        <Icon icon={chat === active ? "chatActive" : chat} />
        <span className={styles.footerText}>Contact</span>
      </div>
      {isAdmin && (
        <div
          className={styles.iconWrapper}
          onClick={() => {
            setModal(true);
          }}
        >
          <>
            <BsFillPersonFill
              className={styles.iconWrapper}
              color={"#fff"}
              size={30}
            />
            <span className={styles.footerText}>Admin</span>
          </>
        </div>
      )}
    </div>
  );
};

export default FooterMenuIcons;
