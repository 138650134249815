import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../../components/Container/Container";
import Header from "../../../components/Header/Header";
import ConversationsJson from "../../../Json_Templates/ConversationsWithFamily/ConversationsWithFamilyStep3c.json";
import { Typography } from "@material-ui/core";

import { useStyles } from "../styles/conversation-styles";

import Button from "../../../components/Button/Button";
import Accordion from "../../../components/Accordion/Accordion";
import { useQuestionnaire } from "../../../hooks/UseQuestionnaire";
import Questionnaire from "../../../components/Questionnaire/Questionnaire";
import { putProfile } from "../../../services/profile-service";

export default function ConversationsWithFamilyStep3c({
  history,
  userData,
}: any) {
  const classes = useStyles();
  const { handleOnChange } = useQuestionnaire();

  const handleOnClick = async () => {
    try {
      //@ts-ignore
      const response = await putProfile(userData.profileId, [
        {
          choiceId: "6F9619FF-8B86-D011-B42D-00C04FC96401",
          // Conversations & Options for Palliative Care == 1
          value: 1,
          category: "isConversationsCompleted"
        },
      ]);

      if (response.status === 200) {
        history.push("/HomeScreen");
      }
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <Header showProgressBar isSticky progressBarStatus={98} />
      <Box style={{ backgroundColor: "#FAF7DC", height: "100vh" }}>
        <div>
          {/* @ts-ignore */}

          <div className={classes.step4Wrapper}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: 600,
                lineHeight: "31px",
                letterSpacing: "0.09px",
                textAlign: "left",
                color: "#000000",
                textTransform: "uppercase",
                opacity: 1,
              }}
            >
              {ConversationsJson.title}
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                letterSpacing: "-0.07px",
                textAlign: "left",
                color: "#1D918B",
                marginBottom: "5px",
              }}
            >
              {ConversationsJson.description}
            </Typography>
            <Container
              backgroundColor="#FAF7DC"
              wrapperStyles={{
                display: "flex",
                alignItems: "center",
                paddingTop: "0px",
                backgroundColor: "#fff",
              }}
              containerStyles={{
                position: "static",
                width: "100%",
              }}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                margin={1}
                style={{ paddingLeft: "6px", paddingRight: "6px" }}
              >
                {ConversationsJson.content.titleParagraph.map((conversation) => {
                  return (
                    <>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontWeight: 600,
                          letterSpacing: "0px",
                          textAlign: "left",
                          color: "#188781",
                          opacity: 1,
                          marginTop: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {conversation.title}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          lineHeight: "17px",
                          letterSpacing: "0px",
                          textAlign: "left",
                          color: "#4A4A4A",
                          opacity: 1,
                        }}
                      >
                        {conversation.paragraphs.map((paragraph) => {
                          return <p>{paragraph}</p>;
                        })}
                      </Typography>
                    </>
                  );
                })}
              </Box>
            </Container>
            <Container
              backgroundColor="#FAF7DC"
              wrapperStyles={{
                display: "flex",
                alignItems: "center",
                paddingTop: "0px",
                backgroundColor: "#fff",
              }}
              containerStyles={{
                position: "static",
                width: "100%",
                marginTop: "18px",
                marginBottom: "32px",
              }}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                margin={1}
                style={{ paddingLeft: "6px", paddingRight: "6px" }}
              >
                {ConversationsJson.secondContent.titleParagraph.map(
                  (conversation) => {
                    return (
                      <>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "13px",
                            fontWeight: 600,
                            letterSpacing: "0px",
                            textAlign: "left",
                            color: "#188781",
                            opacity: 1,
                            marginTop: "20px",
                            marginBottom: "5px",
                          }}
                        >
                          {conversation.title}
                        </Typography>

                        {conversation.paragraphs.map((paragraph, index) => {
                          return (
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontStyle: "italic",
                                fontSize: "13px",
                                lineHeight: "17px",
                                letterSpacing: "-0.3px",
                                color: "#4A4A4A",
                                textAlign: "left",
                                opacity: 1,
                                paddingBottom: "5px",
                                borderBottom:
                                  conversation.paragraphs.length - 1 === index
                                    ? 0
                                    : "1px solid #D9D9D9",
                                margin: "12px 0 12px 0px",
                              }}
                            >
                              {paragraph}
                            </Typography>
                          );
                        })}
                      </>
                    );
                  }
                )}
              </Box>
            </Container>
            {ConversationsJson.content.titleParagraph.map((item) => (
              <Accordion
                key={item.title}
                title={item.title}
                onClick={() => {}}
                plankColor="#188781"
                plankFont="#FFFFFF"
                expandedPlankColor="#188781"
                paragraphs={["test"]}
                arrowColor="#ffffff"
                overFlow
                renderBody={() => (
                  <Box
                    style={{
                      backgroundColor: "#ffffff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box style={{ width: "90%", margin: "0 auto" }}>
                      {item.paragraphs.map((paragraph, index) => (
                        <React.Fragment key={index}>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "12.5px",
                              fontWeight: "normal",
                              lineHeight: "17px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              color: "#000000",
                            }}
                          >
                            <p>{paragraph}</p>
                          </Typography>
                        </React.Fragment>
                      ))}
                      {/* @ts-ignore */}
                      <Questionnaire handleOnChange={handleOnChange} />
                    </Box>
                  </Box>
                )}
              />
            ))}

            <Box
              display={"flex"}
              justifyContent="center"
              width={"100%"}
              marginTop={5}
              marginBottom={5}
            >
              <Button title="Next" type="button" onClick={handleOnClick} buttonStyles={{ zIndex: 1 }} />
            </Box>
          </div>
        </div>
      </Box>
    </div>
  );
}
