import React from "react";
import { Radio, FormControlLabel, Typography } from "@material-ui/core";
import { useStyles } from "../../Views/Conversations/styles/conversation-styles";
import { useHistory } from "react-router-dom";

type ConversationCheckBoxProps = {
  verbiage?: string;
  onYesNavigateTo?: string;
  onNoNavigateTo?: string;
};

export default function ConversationCheckBox({
  verbiage,
  onYesNavigateTo,
  onNoNavigateTo,
}: ConversationCheckBoxProps) {
  const [checked, setChecked] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const history = useHistory();

  const classes = useStyles();
  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (event.target.value === "yes") {
      onYesNavigateTo && history.push(onYesNavigateTo);
    } else {
      onNoNavigateTo && history.push(onNoNavigateTo);
    }
  };
  return (
    <div>
      {verbiage && (
        <div style={{ textAlign: "center", wordWrap: "break-word" }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              fontWeight: 700,
              lineHeight: "18px",
              letterSpacing: "0.13px",
              color: "#188781",
              textAlign: "center",
              opacity: 1,
            }}
          >
            {verbiage}
          </Typography>
        </div>
      )}
      <div style={{ textAlign: "center", wordWrap: "break-word" }}>
        <FormControlLabel
          value="yes"
          control={
            <Radio
              checked={value === "yes"}
              classes={{ checked: classes.checked }}
              onChange={handleChange}
              value="yes"
              className={classes.customColor}
              inputProps={{ "aria-label": "yes radio" }}
            />
          }
          label={
            <span
              style={{
                font: "var(--unnamed-font-style-normal) normal medium 12.75px/var(--unnamed-line-spacing-16) var(--unnamed-font-family-poppins)",
                textAlign: "left",
                letterSpacing: "0.13px",
                color: "#1D918B",
                textTransform: "uppercase",
                opacity: "1",
              }}
            >
              Yes
            </span>
          }
          labelPlacement="end"
        />

        <FormControlLabel
          value="no"
          control={
            <Radio
              color="primary"
              checked={value === "no"}
              classes={{ checked: classes.checked }}
              onChange={handleChange}
              className={classes.customColor}
              value="no"
              inputProps={{ "aria-label": "no radio" }}
            />
          }
          label={
            <span
              style={{
                font: "var(--unnamed-font-style-normal) normal medium 12.75px/var(--unnamed-line-spacing-16) var(--unnamed-font-family-poppins)",
                textAlign: "left",
                letterSpacing: "0.13px",
                color: "#1D918B",
                textTransform: "uppercase",
                opacity: "1",
              }}
            >
              No
            </span>
          }
          labelPlacement="end"
        />
      </div>
    </div>
  );
}
