import React, { useEffect } from "react";
import { History } from "history";
import Header from "../../components/Header/Header";
import ChatContainer from "../../components/ChatContainer/ChatContainer";
import UserDetails from "../../components/UserDetails/UserDetails";
import Toastify, { errorToast } from "../../components/Toastify/Toastify";
import { IoIosArrowBack } from "react-icons/io";
import Device from "../../utils/responsive.util";
import { ChatInterface, getChats } from "../../services/chat-service";
import useSWR from "swr";
import styles from "./Dashboard.module.scss";

const screen = Device();
const containerMargin = screen.isPhone() ? "auto" : "163px";

interface DashboardProps {
  history: History;
}

const fetcher = () => {
  return getChats().then((response) => response.data);
};

const Dashboard: React.FC<DashboardProps> = ({ history }) => {
  const POLL_INTERVAL_IN_MILLISECONDS = 30000;
  const {
    data,
    error,
  }: {
    data: ChatInterface[];
    error: Error | undefined;
  } = useSWR("chats", fetcher, {
    refreshInterval: POLL_INTERVAL_IN_MILLISECONDS,
  });
  const chats = data || [];

  useEffect(() => {
    if (error) {
      errorToast("Failed to lookup the latest chat messages.");
    }
  }, [!!error]);

  return (
    <>
      <Toastify />
      <Header showGauge={false} noBottomBorder={screen.isPhone()}>
        <ChatContainer isChatMessenger={false}>
          <div className={styles.nameContainer}>
            <span className={styles.goBack}>
              <IoIosArrowBack
                size={25}
                onClick={() => history.push("/HomeScreen")}
              />
            </span>
            <span className={styles.name}>Chat</span>
          </div>
          {chats.map((chat: ChatInterface, idx: number) => {
            const routeToChat = () => history.push(`/Chat/${chat.id}`);
            const isUnreadMessage =
              chat.latestMessage && !chat.latestMessage.dateRead;
            return (
              <UserDetails
                chat={chat}
                routeToChat={routeToChat}
                key={`chat-${idx}`}
                isUnreadMessage={isUnreadMessage}
              />
            );
          })}
        </ChatContainer>
        {/* TODO resolve issue with TypeScript instead of including this <div> tag */}
        <div></div>
      </Header>
    </>
  );
};

export default Dashboard;
