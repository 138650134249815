import React, { useEffect, useImperativeHandle, useState } from "react";
import Header from "../../components/Header/Header";
import Step from "../../components/Step/Step";
import Accordion from "../../components/Accordion/Accordion";
import Checkbox from "../../components/Checkbox/Checkbox";
import styles from "./Healthcare-step-one-diagnosis.module.scss";
import Container from "../../components/Container/Container";
import { emptyState } from "../../utils/modal-messages";
import Modal from "../../components/Modal/Modal";
import { putProfile } from "../../services/profile-service";
import healthcareStepOneJSON from "../../Json_Templates/Healthcare/Step1/HealthcareStepOne.json";

let flattedHealthcareStepsJSON = [];
export default function HealthcareStepTwo({ history, userData }) {
  const [isModalOpen, setModal] = useState(false);
  const [userSavedSelections, setUserSavedSelections] = useState([]);
  const [userSelectedDiagnosis, setUserSelectedDiagnosis] = useState([]);


  useEffect(() => {
    flattedHealthcareStepsJSON = Object.values(healthcareStepOneJSON).reduce(
      (acc, element) => {
        acc.push(...element);
        return acc;
      },
      []
    );

    if (userData.profileChoices) {
      setUserSavedSelections(userData.profileChoices);
      setTimeout(() => {
        prePopulateUserSelection();
      }, [1000]);
    }
  }, [userData]);

  let currentDiagnosis;
  if (history.location.state) {
    currentDiagnosis = history.location.state.currentDiagnosis;
  }

  function prePopulateUserSelection() {
    const userSelectedChoices = getUserSavedSelection();


    if (userSelectedChoices) {
      const previousSelectedChoices = flattedHealthcareStepsJSON.filter(
        (healthcareStepOne) => {
          let item = lookUpByChoiceId(healthcareStepOne.choiceId);
          return item && item.value === 1;
        }
      );

      setUserSelectedDiagnosis(
        previousSelectedChoices.map(
          (previousSelectedChoice) => previousSelectedChoice.text
        )
      );
    }
  }

  const handleUserSelectedDiagnosis = (diagnosis, index, isChecked) => {
    let pos = flattedHealthcareStepsJSON.findIndex((i) => i.text === diagnosis);

    Object.values(currentDiagnosis).forEach((healthCondition) => {
      healthCondition.forEach((illness) => {

        if (illness.text === diagnosis) {
          const existingSelection = userSavedSelections.find(
            (selection) => selection && selection.choiceId === illness.choiceId
          );
          if (existingSelection) {
            existingSelection.value = isChecked ? 1 : 0;
          } else {
            setUserSavedSelections((prevState) => {
              let updatedSelections = [...prevState];
              updatedSelections[pos] = {
                choiceId: illness.choiceId,
                category: "healthcare",
                value: isChecked ? 1 : 0,
              };
              return updatedSelections;
            });
          }
        }
      });
    });

    userSelectedDiagnosis.push(diagnosis);
    if (!isChecked) {
      userSelectedDiagnosis.filter((illness) => illness !== diagnosis);
    }
  };

  const updateProfile = async () => {
    const filteredChoices = userSavedSelections.filter(
      (medicalCondition) => medicalCondition.choiceId
    );
    
    try {
      const response = await putProfile(userData.profileId, filteredChoices);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getUserSavedSelection = () => {
    let healthCareSelection;
    if (userData.profileChoices) {
      healthCareSelection = userData.profileChoices.filter(
        (element) => element.category === "healthcare"
      );
    }
    return healthCareSelection;
  };

  const handleOnClick = async () => {
    if (userSelectedDiagnosis.length > 0) {
      const response = await updateProfile();
      if (response.status === 200) {
        const userPath = history.location.state.userPath
        Object.values(history.location.state.currentDiagnosis).forEach((category, index) => {
          category.forEach(illness => {
            if(userSelectedDiagnosis.includes(illness.text)) {
              userPath[Object.keys(userPath)[index]].push(illness.text)
            }
          })
        })
        history.push("/Healthcare/Step2", { userSelectedDiagnosis, userPath });
      }
    } else {
      setModal(true);
    }
  };

  const lookUpByChoiceId = (choiceId) => {
    return userData.profileChoices.find(
      (userSavedSelection) => userSavedSelection.choiceId === choiceId
    );
  };
  return (

    <Container
      isButtonShown
      buttonS
      wrapperStyles={{
        display: "flex",
        flexDirection: "column",
      }}
      buttonProps={{
        title: "CONTINUE",
        onClick: () => handleOnClick(),
        zIndex: 40,
        marginTop: 0,
      }}
      buttonTitle="PROCEED TO STEP 2"
      buttonOnClick={() => handleOnClick()}
    >
      <Header showGauge={false} isConversationPath={false} />
      <Step
        title={"Current Diagnosis"}
        label={"Healthcare"}
        iconName={"healthcare"}
        stepCount={"1"}
        verbiage={
          "Now let’s look at what your family needs to know for the specific problems you want help with:"
        }
        fontSize={15}
        stepContainerStyles={{
          backgroundColor: "#fff",
          marginTop: 40,
          marginBottom: 0,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      />
      <Modal
        open={isModalOpen}
        message={emptyState[0]}
        handleClose={() => setModal(false)}
      />

      <div className={styles.checkboxContainer}>
        {currentDiagnosis && userSavedSelections.length > 0 ? (
          Object.keys(currentDiagnosis).length > 0 && (
            <Accordion
              items={currentDiagnosis}
              defaultExpanded={true}
              renderBody={(diagnosis) => {
                const illnesses = diagnosis.map((affliction) => affliction);
                return illnesses.map((illness, index) => {
                  let item = lookUpByChoiceId(illness.choiceId);

                  return (
                    <Checkbox
                      item={illness.text}
                      key={index}
                      checked={item && item.value}
                      checkBoxColor={"#cadcdc"}
                      margin={0}
                      labelStyles={{
                        fontSize: 13,
                        fontFamily: "Poppins",
                        color: "#4A4A4A",
                        fontWeight: 400,
                      }}
                      labelRootStyles={{
                        borderBottom: "1px solid #3C3C434A",
                        width: "100vw",
                      }}
                      height={75}
                      handleOnChange={(e) => {
                        handleUserSelectedDiagnosis(
                          e.target.name,
                          index,
                          e.target.checked
                        );
                      }}
                    />
                  );
                });
              }}
            />
          )
        ) : (
          <div>loading</div>
        )}
      </div>
    </Container>

  );
}
