import React from "react";

import ProgressBar from "../../components/ProgressBar/ProgressBar";

import MapElementToScreen from "../../components/MapElementToScreen/MapElementToScreen";

const Stage = ({ now = 15 }) => {
  return (
    <MapElementToScreen
      componentStyle={{
        backgroundColor: "#1D918B",
        padding: "15px",
        borderRadius: "11px",
      }}
      paragraph="Current Stage"
      paragraphStyle={{
        color: "#EEE487",
        fontSize: "15px",
        fontWeight: "bold",
        marginBottom: "8px",
        display: "block",
      }}
      renderAfter={() => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ProgressBar now={now} />
            <p
              style={{
                display: "flex",
                justifyContent: "space-around",
                color: "white",
                fontSize: "9px",
              }}
            >
              <span>EARLY</span>
              <span>MIDDLE</span>
              <span>ADVANCED</span>
            </p>
          </div>
        );
      }}
    />
  );
};

export default Stage;
