import React from "react";
import styles from "./TextContainer.module.scss";
import CheckBox from "../../components/Checkbox/Checkbox";

export const TextContainer = ({ items }) => {
  return items.map((item) => {
    return (
      <div className={styles.textContainer}>
        <div className={styles.label}>{item.label}</div>
        <p className={styles.paragraph}>{item.paragraph}</p>
      </div>
    );
  });
};

export const CheckBoxContainer = ({
  items,
  backgroundColor,
  onChange,
  checked,
  indexesToCheck,
}) => {
  return (
    <div className={styles.textContainer} style={{ backgroundColor }}>
      <span className={styles.header}>
        I need guidance from the doctor regarding:
      </span>
      <CheckBox
        items={items}
        handleOnChange={onChange}
        checked={checked}
        indexesToCheck={indexesToCheck}
      />
    </div>
  );
};
