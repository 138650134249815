import api, { login } from "../utils/api";

export function loginUser(username: string, password: string) {
  return login(async () => {
    return api.post(
      `${appSettings.apiBaseUrl}/token`,
      new URLSearchParams({
        username,
        password,
        grant_type: "password",
      })
    );
  });
}

export function createAccount(userInfo: string) {
  return api.post(
    `${appSettings.apiBaseUrl}/api/account/register
  `,
    userInfo
  );
}

export function forgotPassword(email: string) {
  return api.post(`${appSettings.apiBaseUrl}/api/userauth/forgotpassword`, {
    email: email,
  });
}

export function getModel(resetId: string) {
  return api.get(
    `${appSettings.apiBaseUrl}/api/userauth/resetpassword/${resetId}`
  );
}

export function resetPassword(model: {}) {
  return api.put(`${appSettings.apiBaseUrl}/api/userauth/resetpassword`, model);
}
