import AtrialFibrillation2 from "./Step2/AtrialFibrillation.json";
import AlzheimersDementia2 from "./Step2/AlzheimersDementia.json";
import Brain2 from "./Step2/Brain.json";
import Breast2 from "./Step2/Breast.json";
import Blood2 from "./Step2/Blood.json";
import Colon2 from "./Step2/Colon.json";
import Diabetes2 from "./Step2/Diabetes.json";
import FailureToThrive2 from "./Step2/FailuretoThrive.json";
import HeartAttack2 from "./Step2/HeartAttack.json";
import HeartFailure2 from "./Step2/HeartFailure.json";
import HeartValves2 from "./Step2/HeartValves.json";
import KidneyFailure2 from "./Step2/KidneyFailure.json";
import Lung2 from "./Step2/Lung.json";
import LungDisease2 from "./Step2/LungDisease.json";
import Ovarian2 from "./Step2/Ovarian.json";
import Pancreas2 from "./Step2/Pancreas.json";
import Parkinsons2 from "./Step2/Parkinsons.json";
import Prostate2 from "./Step2/Prostate.json";
import Skin2 from "./Step2/Skin.json";
import Stroke2 from "./Step2/Stroke.json";

import AtrialFibrillation3 from "./Step3/AtrialFibrillation.json";
import AlzheimersDementia3 from "./Step3/AlzheimersDementia.json";
import Brain3 from "./Step3/Brain.json";
import Breast3 from "./Step3/Breast.json";
import Blood3 from "./Step3/Blood.json";
import Colon3 from "./Step3/Colon.json";
import Diabetes3 from "./Step3/Diabetes.json";
import FailureToThrive3 from "./Step3/FailuretoThrive.json";
import HeartAttack3 from "./Step3/HeartAttack.json";
import HeartFailure3 from "./Step3/HeartFailure.json";
import HeartValves3 from "./Step3/HeartValves.json";
import KidneyFailure3 from "./Step3/KidneyFailure.json";
import Lung3 from "./Step3/Lung.json";
import LungDisease3 from "./Step3/LungDisease.json";
import Ovarian3 from "./Step3/Ovarian.json";
import Pancreas3 from "./Step3/Pancreas.json";
import Parkinsons3 from "./Step3/Parkinsons.json";
import Prostate3 from "./Step3/Prostate.json";
import Skin3 from "./Step3/Skin.json";
import Stroke3 from "./Step3/Stroke.json";

export const HealthCareStepTwoJSON = {
  AtrialFibrillation: AtrialFibrillation2,
  "Alzheimer's/Dementia": AlzheimersDementia2,
  Parkinsons: Parkinsons2,
  Stroke: Stroke2,
  HeartFailure: HeartFailure2,
  "HeartAttack/ChestPain/CoronaryArteryDisease": HeartAttack2,
  HeartValveConditions: HeartValves2,
  "Asthma/COPD/Emphysema/PulmonaryEmbolism": LungDisease2,
  Diabetes: Diabetes2,
  KidneyDisease: KidneyFailure2,
  "AgingBones/Falls/WeightLoss/FailureToThrive": FailureToThrive2,
  BreastCancer: Breast2,
  ProstateCancer: Prostate2,
  LungCancer: Lung2,
  BrainCancer: Brain2,
  "BloodCancers:Leukemia,Lymphoma,Myeloma": Blood2,
  Pancreatic: Pancreas2,
  "Colon(Colorectal)Cancer": Colon2,
  "Skin/Melanoma": Skin2,
  OvarianCancer: Ovarian2,
};

export const HealthCareStepThreeJSON = {
  "Alzheimer's/Dementia": AlzheimersDementia3,
  Parkinsons: Parkinsons3,
  Stroke: Stroke3,
  AtrialFibrillation: AtrialFibrillation3,
  HeartFailure: HeartFailure3,
  "HeartAttack/ChestPain/CoronaryArteryDisease": HeartAttack3,
  HeartValveConditions: HeartValves3,
  "Asthma/COPD/Emphysema/PulmonaryEmbolism": LungDisease3,
  Diabetes: Diabetes3,
  KidneyDisease: KidneyFailure3,
  "AgingBones/Falls/WeightLoss/FailureToThrive": FailureToThrive3,
  BreastCancer: Breast3,
  ProstateCancer: Prostate3,
  LungCancer: Lung3,
  BrainCancer: Brain3,
  "BloodCancers:Leukemia,Lymphoma,Myeloma": Blood3,
  Pancreatic: Pancreas3,
  "Colon(Colorectal)Cancer": Colon3,
  "Skin/Melanoma": Skin3,
  OvarianCancer: Ovarian3,
};
