import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Toastify.scss";
import CloseIcon from "@material-ui/icons/Close";

export const successToast = (msg = "Your selection has been saved.") => {
  toast(
    <>
      <div className="success-wrapper">
        <h6 className="toastText">{msg}</h6>
      </div>
      <CloseIcon style={{ color: "#146e6c" }} />
    </>,
    {
      className: "success-toast-container",
      bodyClassName: "success-body",
      draggable: true,
      position: "top-center",
      autoClose: 3000,
      closeOnClick: true,
      hideProgressBar: true,
      closeButton: false,
    }
  );
};

export const errorToast = (msg = "An error has occurred") => {
  toast(
    <>
      <div className="error-wrapper">
        <h6 className="toastText">{msg}</h6>
      </div>
      <CloseIcon style={{ color: "#16161d", marginRight: "10px" }} />
    </>,
    {
      className: "error-toast-container",
      bodyClassName: "error-body",
      draggable: true,
      position: "top-center",
      autoClose: 3000,
      closeOnClick: true,
      hideProgressBar: true,
      closeButton: false,
    }
  );
};

function Toastify() {
  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export default Toastify;
