import React from "react";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import { Box, Typography } from "@material-ui/core";

import Container from "../../components/Container/Container";

import { useQuestionnaire } from "../../hooks/UseQuestionnaire";
import Questionnaire from "../../components/Questionnaire/Questionnaire";
import Toastify from "../../components/Toastify/Toastify";

const Contact = ({ history }: any) => {
  const { handleOnChange } = useQuestionnaire();

  return (
    <Box>
      <Container wrapperStyles={{ height: "100vh", backgroundColor: "#fff" }}>
        <Box style={{ marginTop: "50px", height: "auto" }}>
          <Toastify />

          <Header
            showProgressBar
            isSticky
            isConversationPath={false}
            noBottomBorder
          />
          <Banner
            iconName="account-setup"
            color="#CADCDC"
            history={history}
            textColor="#1D918B"
          />
          <Box
            style={{
              marginLeft: "28px",
              marginRight: "22px",
              marginTop: "20px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "21px",
                letterSpacing: "-0.07px",
                textAlign: "left",
                color: "#1D918B",
              }}
            >
              Please Select an Option
            </Typography>
            {/* @ts-ignore */}
            <Questionnaire handleOnChange={handleOnChange} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
