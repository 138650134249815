import React from "react";
import classnames from "classnames";
import styles from "./Ellipse.module.scss";

const Ellipse = ({ circleToFill, isAllActive = false }) => {
  return (
    <div className={styles.ellipseContainer}>
      <div
        className={classnames(styles.ellipse, {
          [styles.filled]: circleToFill === 1,
          [styles.green]: isAllActive,
        })}
      />
      <div
        className={classnames(styles.ellipse, {
          [styles.filled]: circleToFill === 2,
          [styles.green]: isAllActive,
        })}
      />
      <div
        className={classnames(styles.ellipse, {
          [styles.filled]: circleToFill === 3,
          [styles.green]: isAllActive,
        })}
      />
    </div>
  );
};
export default Ellipse;
