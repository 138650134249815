import React from "react";
import styles from "./SliderMenu.module.scss";
import Slider from "../../components/Slider/Slider";
import ProceedButton from "../ProceedButton/ProceedButton";
import Step from "../Step/Step";
import classnames from "classnames";
import { createMarks } from "../../utils/slider.util";

const SliderMenu = ({
  backgroundColor,
  showStep,
  verbiage,
  title,
  showButton,
  history,
  useMarks = false,
  marks,
  borderBottom = false,
  verbiageFontSize,
  step,
  legend,
  legendStyles,
  legendTextStyles,
  max,

  ...rest
}) => {
  return (
    <div className={styles.container}>
      {showStep && (
        <Step
          stepCount={"1"}
          verbiage={`Lets look at what is going on with loved one, by answering  these straightforward questions. \n Covid-19 has made it more difficult to secure safe transportation as well as reduce isolation for your loved ones. We will help you find community-based resources that are available wherever your loved one lives.`}
          title={"What's going on?"}
          iconName={"homecare"}
        />
      )}
      <div
        className={classnames(styles.sliderContainer, {
          [styles.borderBottom]: borderBottom,
        })}
        style={{ backgroundColor }}
      >
        <div className={styles.verbiageContainer}>
          <div className={styles.title}>{title}</div>
          <div
            className={styles.verbiage}
            style={{ fontSize: verbiageFontSize }}
          >
            {verbiage}
          </div>
        </div>
        <div className={styles.wrapper}>
          {legend && (
            <div style={{ ...legendStyles }}>
              {legend.map((legend) => {
                return <span style={{ ...legendTextStyles }}>{legend}</span>;
              })}
            </div>
          )}
          <Slider
            aria-labelledby="discrete-slider-small-steps"
            marks={createMarks(marks)}
            {...rest}
            step={null}
          />
        </div>
      </div>

      {showButton && (
        <ProceedButton
          onClick={() => history.push("/Homecare/Step2")}
          title={"PROCEED TO STEP 2"}
        />
      )}
    </div>
  );
};

export default SliderMenu;
