import React from "react";
import { createStyles, withStyles, makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#9CBF85",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#9CBF85",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9CBF85",
      },
      "&:hover fieldset": {
        borderColor: "#9CBF85",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9CBF85",
      },
    },
  },
})(TextField);

interface InputFieldSetProps {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  width: number;
  type: string;
  placeholder: string;
  required: boolean;
  autoComplete?: string;
  autoCapitalize?: string;
  InputProps?: object;
  height?: number;
}

const InputFieldSet: React.FC<InputFieldSetProps> = ({
  title,
  autoCapitalize,
  onChange,
  defaultValue,
  width,
  height,
  ...rest
}) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        flexWrap: "wrap",
        width,
        height,
      },
      margin: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssTextField
        className={classes.margin}
        id="custom-css-standard-input"
        label={title}
        variant="outlined"
        fullWidth
        autoCapitalize={autoCapitalize}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default InputFieldSet;
