import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Icon from "../Icon/Icon";

const ExpansionDetails = makeStyles({
  root: {
    backgroundColor: "#e6e6e6",
    padding: 16,
    display: "flex",
    flexDirection: "column",
  },
  body: {
    // padding: "15px",
    // display: "flex",
    // flexDirection: "column",
    // borderRadius: "10px",
    // justifyContent: "center",
    // backgroundColor: "#fff",
  },

  optionTitle: {
    paddingTop: " 15px",
    fontSize: "1em",
    fontFamily: "Poppins",
    fontWeight: "bold",
  },

  accordionTitle: {
    fontSize: "1em",
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#1D918B",
  },

  accordionDescription: {
    fontSize: "1em",
    fontFamily: "Poppins",
  },
});

interface ArchiveAccordionProps {
  tabContent: any;
  icon: string | null;
  title?: string;
  subtitle: string;
  onClick: (e: string) => void;
  OptionTitle: string;
  additionalSubTitlePadding?: any;
}

const ArchiveAccordion: React.FC<ArchiveAccordionProps> = ({
  tabContent,
  icon = null,
  title = "Homecare",
  subtitle = "Driving",
  onClick,
  OptionTitle,
  additionalSubTitlePadding = false,
}) => {
  const useStyles = makeStyles({
    root: {
      width: "100%",
      minHeight: 44,
      // paddingBottom: 100,
    },

    panelContainer: {
      borderBottom: "1px solid #e6e6e6",
      boxShadow: "none",
      borderRadius: "0 !important",
    },

    summaryWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },

    titleWrapper: {
      display: "flex",
      flexDirection: "column",
    },

    iconWrapper: {
      display: "flex",
      flexDirection: "row",
    },

    title: {
      margin: "3px 0",
      fontFamily: "Poppins",
      fontSize: "10.5px !important",
      fontWeight: "bold",
    },
    subtitle: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
      fontFamily: "Poppins",
      fontSize: "12.5px !important",
      fontWeight: "bold",
      color: "#1D918B",
      marginLeft: additionalSubTitlePadding && "16px",
    },

    arrowWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    arrow: {
      color: "#1D918B",
      fontSize: "27px",
    },
  });
  const classes = useStyles();
  const expansionDetails = ExpansionDetails();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <ExpansionPanel
          className={classes.panelContainer}
          onChange={() => {
            setExpanded(!expanded);
          }}
        >
          <ExpansionPanelSummary
            onClick={() => onClick && onClick(OptionTitle)}
          >
            <div className={classes.summaryWrapper}>
              <div
                className={icon ? classes.iconWrapper : classes.titleWrapper}
              >
                {icon ? (
                  <Icon icon={icon} style={{ width: "20px" }} />
                ) : (
                  <Typography className={classes.title}>{title}</Typography>
                )}
                <Typography className={classes.subtitle}>{subtitle}</Typography>
              </div>
              <div className={classes.arrowWrapper}>
                {!expanded ? (
                  <KeyboardArrowRight className={classes.arrow} />
                ) : (
                  <KeyboardArrowDown className={classes.arrow} />
                )}
              </div>
            </div>
          </ExpansionPanelSummary>

          {/* DETAILS DROPDOWN */}
          <ExpansionPanelDetails className={expansionDetails.root}>
            {tabContent && tabContent}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </>
  );
};

export default ArchiveAccordion;
