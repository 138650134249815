import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Button from "../../../components/Button/Button";
import Container from "../../../components/Container/Container";
import ConversationTitle from "../../../components/ConversationTitle/ConversationTitle";

import Header from "../../../components/Header/Header";

import Verbiage from "../../../Json_Templates/Conversations/Onboarding/Onboarding-step3.json";

function ConversationOnboardingStep3({ history }: { history: any }) {
  const useStyles = makeStyles({
    conversationText: {
      fontFamily: "Poppins",
      fontWeight: 600,
      textAlign: "left",
      fontSize: 15,
      letterSpacing: 0.09,
      marginTop: 32,
      textTransform: "uppercase",
    },
    header: {
      fontFamily: "Poppins",
      fontSize: 15,
      color: "#146E6C",
      marginTop: 16,
      marginBottom: 9,
      fontWeight: 600,
    },
    paragraph: {
      font: "normal normal 300 13px/20px Poppins",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
    },
    list: {
      font: "normal normal medium 13px/20px Poppins",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
    },
    boldedParagraph: {
      fontFamily: "Poppins",
      fontSize: 15,
      fontWeight: 600,
      lineHeight: "30px",
      letterSpacing: "0px",
      color: "#000000",
      textAlign: "left",
      opacity: 1,
    },
  });

  const classes = useStyles();

  return (
    //@ts-ignore
    <Container style={{ containerBackground: "#faf7dc" }} isFixed={false}>
      <Header showProgressBar progressBarStatus={30} />
      <Box p={1} height="100%" marginTop={10} marginLeft={1} marginRight={1}>
        <ConversationTitle title={Verbiage.title} history={history}/>
        <Typography variant="h6" className={classes.header}>
          {Verbiage.content.description}
        </Typography>

        {Verbiage.content.conversations.map((conversation) => {
          return (
            <Typography variant="h6" className={classes.paragraph}>
              <p>{conversation}</p>
            </Typography>
          );
        })}
        {Verbiage.content.list.map((list) => {
          return (
            <Typography variant="h6" className={classes.boldedParagraph}>
              <li>{list}</li>
            </Typography>
          );
        })}

        <Box
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          display={"flex"}
          style={{ marginTop: "50px" }}
        >
          <Button
            title={"Next"}
            type="button"
            onClick={() => history.push("/Conversations/Onboarding/Step4")}
            buttonStyles={{ zIndex: 1 }}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default ConversationOnboardingStep3;
