import React, { useState, createContext, useEffect } from "react";
import { hasLoggedIn, logout } from "../utils/api";
import { getUser } from "../services/user-service";
import { getProfile } from "../services/profile-service";
import { useLocation } from "react-router";
import { ProfileChoices } from "../Types/profile-choices";
interface profileInterface {
  userId?: string;
  profileId?: string;
  isLoading?: boolean;
  profileChoices?: ProfileChoices[] | undefined;
  fullName?: string | undefined;
  relationship?: string | undefined;
  isAdmin?: boolean | undefined;
  affiliation?: string | undefined;
  adminOnCall?: string | undefined;
  adminAffiliation?: string | null;
}

interface profileContextProps {
  profile: profileInterface;
  isLoggedIn: boolean;
  logout: () => void;
  setAdminAffiliation: (adminAffiliation: string) => void;
}

export const UserDataContext = createContext<profileContextProps | undefined>(
  undefined
);

const AuthProvider: React.FC = ({ children }) => {
  const [profile, setProfile] = useState<profileInterface>({
    userId: "",
    profileId: "",
    isLoading: true,
  });

  const isLoggedIn = hasLoggedIn();

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await getUser();

      setProfile({
        ...profile,
        userId: data.id,
        fullName: `${data.firstName} ${data.lastName}`,
        isAdmin: data.isAdmin,
        affiliation: data.customerId,
      });
    };

    if (isLoggedIn) {
      fetchUser();
    }
  }, [isLoggedIn]);

  const location = useLocation();
  useEffect(() => {
    async function getUserProfile() {
      const response = await getProfile();

      if (Array.isArray(response.data) && response.data.length > 0) {
        setProfile({
          ...profile,
          profileId: response.data[0].profileId,
          profileChoices: response.data[0].choices,
          relationship: response.data[0].relationship,
          // TODO set me if needed
          // adminOnCall: chatOnCall.id,
          // adminAffiliation: profile.adminAffiliation || null,
          isLoading: false,
          //@ts-ignore
        });
      }
    }
    if (profile.userId) {
      getUserProfile();
    }
  }, [profile.userId, location.pathname]);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (isLoggedIn) {
          const userResponse = await getUser();
          setProfile((prevState) => ({
            ...prevState,
            userId: userResponse.data.id,
            fullName: `${userResponse.data.firstName} ${userResponse.data.lastName}`,
            isAdmin: userResponse.data.isAdmin,
            affiliation: userResponse.data.customerId,
          }));

          if (userResponse.data.id) {
            const profileResponse = await getProfile();
            if (
              Array.isArray(profileResponse.data) &&
              profileResponse.data.length > 0
            ) {
              setProfile((prevState) => ({
                ...prevState,
                profileId: profileResponse.data[0].profileId,
                profileChoices: profileResponse.data[0].choices,
                relationship: profileResponse.data[0].relationship,
                isLoading: false,
              }));
            }
          }
        }
      } catch (error) {
        // handle errors, maybe set some error state
      }
    };

    fetchUserData();
  }, [isLoggedIn]);

  const setAdminAffiliation = (adminAffiliation: string) => {
    const updatedProfile = { ...profile, adminAffiliation };
    setProfile(updatedProfile);
  };

  return (
    <UserDataContext.Provider
      value={{ profile, setAdminAffiliation, isLoggedIn, logout }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default AuthProvider;
