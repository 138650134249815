import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";


const CustomCheckbox = withStyles({
    root: {
        color: "#1D918B",
        '&$checked': {
            color: "#1D918B",
        },
        padding: '0 5px', // reduce padding

    },
    checked: {
        color: "#1D918B"
    },
})((props) => <Checkbox {...props} />);

const useCustomStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    checked: {
        color: "#1d918b",
    },
    label: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 'bold',
        pointerEvents: 'none',
        color: "#1D918B",

    },
}));

export default function CheckboxesGroup({
    handleOnChange,
    items,
    checked,
    margin,
    padding
}) {
    const classes = useCustomStyles();
    const checkboxStyle = {
        margin: margin || 8,
        padding: padding || '0 5px',
    };
    return (
        items && items.map((item) => {
            return (
                <div className={classes.root} style={checkboxStyle}>
                    <FormControl component="fieldset" className={classes.formControl} onClick={(e) => console.log('clicked')}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={checked && checked.includes(item.choiceId)}
                                        onChange={handleOnChange}
                                        name={item.choiceId}
                                    />
                                }
                                label={item.question}
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            )
        })
    )
}

