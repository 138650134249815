import React, { useState, useEffect } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";

const BorderLinearProgress = withStyles((theme: Theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    backgroundColor: "white",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1D918B",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

interface Props {
  progress: number;
}

export default function LinearProgressWithLabel(props: Props) {
  const { progress } = props;
  const classes = useStyles();

  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    const animateProgress = () => {
      if (currentProgress < progress) {
        setCurrentProgress(currentProgress + 1);
      }
    };
    const timer = setInterval(animateProgress, 20);
    return () => clearInterval(timer);
  }, [currentProgress, progress]);

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      <Box width="100%">
        <BorderLinearProgress variant="determinate" value={currentProgress} />
      </Box>
    </Box>
  );
}
