import React from "react";
import styles from "./labelParagraph.module.scss";

const labelParagraph = ({ label, paragraph }) => {
  return (
    <div>
      <span className={styles.label}>{label}</span>
      <p className={styles.paragraph}>{paragraph}</p>
    </div>
  );
};

export default labelParagraph;
