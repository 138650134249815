import React, { useState } from "react";

interface Icon {
  icon?: string;
  style?: {};
  className?: string;
}
const Icon: React.FC<Icon> = ({ icon, style, className }) => {
  const [src, setSrc] = useState("");
  import(`../../assets/${icon}-icon.svg`).then((image) =>
    setSrc(image.default)
  );

  return <img src={src} alt="Home Icon" style={style} className={className} />;
};

export default Icon;
