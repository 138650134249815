import InsuranceSelections from "./Insurance.json";

import EldercareLawyer2 from "./Step2/EldercareLawyer.json";
import FinancialAdvisor2 from "./Step2/FinancialAdvisor.json";
import FinancialPlan2 from "./Step2/FinancialPlan.json";
import HealthcareProxyandLivingWill2 from "./Step2/HealthcareProxyAndLivingWill.json";
import LongTermCareInsurance2 from "./Step2/LongTermCareInsurance.json";
import Medicaid2 from "./Step2/Medicaid.json";
import Medicare2 from "./Step2/Medicare.json";
import PowerofAttorney2 from "./Step2/PowerOfAttorney.json";
import ProtectingAssets2 from "./Step2/ProtectingAssets.json";
import WillsandTrusts2 from "./Step2/WillsAndTrusts.json";

import EldercareLawyer3 from "./Step3/EldercareLawyer.json";
import FinancialAdvisor3 from "./Step3/FinancialAdvisor.json";
import FinancialPlan3 from "./Step3/FinancialPlan.json";
import HealthcareProxyandLivingWill3 from "./Step3/HealthcareProxyAndLivingWill.json";
import LongTermCareInsurance3 from "./Step3/LongTermCareInsurance.json";
import Medicaid3 from "./Step3/Medicaid.json";
import Medicare3 from "./Step3/Medicare.json";
import PowerofAttorney3 from "./Step3/PowerOfAttorney.json";
import ProtectingAssets3 from "./Step3/ProtectingAssets.json";
import WillsandTrusts3 from "./Step3/WillsAndTrusts.json";

export const insuranceStep1 = InsuranceSelections.InsuranceStepOne;

export const insuranceStep2 = {
  ElderLawAttorney: EldercareLawyer2,
  FinancialAdvisor: FinancialAdvisor2,
  FinancialPlan: FinancialPlan2,
  HealthcareProxyandLivingWill: HealthcareProxyandLivingWill2,
  LongTermCareInsurance: LongTermCareInsurance2,
  Medicaid: Medicaid2,
  Medicare: Medicare2,
  PowerofAttorney: PowerofAttorney2,
  ProtectingAssets: ProtectingAssets2,
  WillsandTrusts: WillsandTrusts2,
};

export const insuranceStep3 = {
  ElderLawAttorney: EldercareLawyer3,
  FinancialAdvisor: FinancialAdvisor3,
  FinancialPlan: FinancialPlan3,
  HealthcareProxyandLivingWill: HealthcareProxyandLivingWill3,
  LongTermCareInsurance: LongTermCareInsurance3,
  Medicaid: Medicaid3,
  Medicare: Medicare3,
  PowerofAttorney: PowerofAttorney3,
  ProtectingAssets: ProtectingAssets3,
  WillsandTrusts: WillsandTrusts3,
};
