import { Box } from "@material-ui/core";
import React from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import ConversationsJson from "../../Json_Templates/Conversations/ConversationsWithLovedOne/conversations-step5.json";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles/conversation-styles";

import Button from "../../components/Button/Button";
import ConversationTitle from "../../components/ConversationTitle/ConversationTitle";

export default function ConversationsStep5({ history }: any) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ height: "100vh" }}>
      {/* @ts-ignore */}
      <Header showProgressBar progressBarStatus={75}>
        <div
          className={classes.responsiveWrapper}
          style={{ margin: "11px auto" }}
        >
          <ConversationTitle title={ConversationsJson.title} history={history}/>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "21px",
              letterSpacing: "-0.07px",
              color: "#188781",
              textAlign: "left",
              textTransform: "uppercase",
              opacity: 1,
              marginBottom: "10px",
            }}
          >
            {ConversationsJson.content.title}
          </Typography>
          <Container
            style={{ width: "100% !important" }}
            responsiveBackgroundColor="#fff"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              width: "100%",
              borderRadius: "10px",
            }}
            containerStyles={{
              width: "100%",
              position: "static",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            {ConversationsJson.content.conversations.map((conversation) => {
              return (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={0}
                  height="100%"
                  margin={1}
                >
                  {
                    <Typography className={classes.customText} gutterBottom>
                      <span>{conversation}</span>
                    </Typography>
                  }
                </Box>
              );
            })}
          </Container>
          <Container
            style={{ width: "100% !important" }}
            backgroundColor="#FAF7DC"
            wrapperStyles={{
              display: "flex",
              alignItems: "center",
              paddingTop: "0px",
              width: "100%",
              borderRadius: "10px",
            }}
            containerStyles={{
              width: "100%",
              borderRadius: "10px",
              marginTop: "26px",
              backgroundColor: "#fff",
            }}
          >
            <Box marginTop={2}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: "13px",
                  lineHeight: "21px",
                  letterSpacing: "-0.07px",
                  color: "#188781",
                  textAlign: "left",
                  textTransform: "uppercase",
                  opacity: 1,
                  marginLeft: "16px",
                }}
              >
                Sample Conversations
              </Typography>
            </Box>
            {ConversationsJson.content.list.map((conversation, index) => {
              return (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start"
                  height="100%"
                  margin={2}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "italic",
                      fontSize: "13px",
                      lineHeight: "17px",
                      letterSpacing: "-0.3px",
                      color: "#4A4A4A",
                      textAlign: "left",
                      borderBottom:
                        index === ConversationsJson.content.list.length - 1
                          ? "none"
                          : "1px solid #D9D9D9",
                      paddingBottom: "5px",
                    }}
                  >
                    <span>{conversation}</span>
                  </Typography>
                </Box>
              );
            })}
          </Container>

          <Box style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                title="Next"
                type="button"
                onClick={() => history.push("/Conversations/Step6")}
              />
            </Box>
          </Box>
        </div>
      </Header>
    </div>
  );
}
