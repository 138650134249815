import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Button from "../../../components/Button/Button";
import Container from "../../../components/Container/Container";

import Verbiage from "../../../Json_Templates/Conversations/Onboarding/Onboarding-step2.json";

import Header from "../../../components/Header/Header";
import ConversationTitle from "../../../components/ConversationTitle/ConversationTitle";
import Icon from "../../../components/Icon/Icon";

function ConversationOnboardingStep2({ history }: { history: any }) {
  const useStyles = makeStyles({
    customList: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    listItem: {
      position: "relative",
      paddingLeft: "24px",
    },

    listItemIcon: {
      position: "absolute",
      left: 0,
      top: "0%",
    },
    conversationText: {
      fontFamily: "Poppins",
      fontWeight: 600,
      textAlign: "left",
      fontSize: 15,
      letterSpacing: 0.09,
      marginTop: 32,
      textTransform: "uppercase",
    },
    header: {
      fontFamily: "Poppins",
      fontSize: 15,
      color: "#146E6C",
      marginTop: 16,
      marginBottom: 9,
      fontWeight: 600,
    },
    paragraph: {
      font: "normal normal 300 13px/20px Poppins",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
      textAlign: "left",
    },
    list: {
      fontFamily: "Poppins",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "17px",
      letterSpacing: "0px",
      color: "#4A4A4A",
      textAlign: "left",
      opacity: 1,
      marginTop: "5px",
    },
    highlightedText: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "13px",
      lineHeight: "19px",
      fontFamily: "Poppins",
      textAlign: "left",
      letterSpacing: "-0.13px",
      color: "#188781",
    },
  });
  const classes = useStyles();

  return (
    //@ts-ignore
    <Container style={{ containerBackground: "#faf7dc" }} isFixed={false}>
      <Header showProgressBar progressBarStatus={20} />
      <Box p={1} height="100%" marginTop={10} marginLeft={1} marginRight={1}>
        <ConversationTitle title={Verbiage.title} history={history}/>
        <Typography variant="h6" className={classes.header}>
          {Verbiage.content.description}
        </Typography>

        {Verbiage.content.conversations.map((conversation) => {
          return (
            <Typography variant="h6" className={classes.paragraph}>
              <p>{conversation}</p>
            </Typography>
          );
        })}
        {Verbiage.content.list.map((list, index) => {
          const highlightedText = (text: string) => (
            <span className={classes.highlightedText}>{text}</span>
          );

          const listItemContent = () => {
            switch (index) {
              case 0:
                return (
                  <>
                    {highlightedText(
                      "A focus on comfort, peace, and acceptance."
                    )}{" "}
                    Can help your loved one and everyone in your family see
                    positive aspects at the end of life.
                  </>
                );
              case 1:
                return (
                  <>
                    {highlightedText(
                      "It’s a good idea to talk about the fact that the future is uncertain."
                    )}{" "}
                    Avoiding important issues only makes them harder to deal
                    with later. Talking over your concerns can bring comfort to
                    both of you, as well as others in your life.
                  </>
                );
              case 2:
                return (
                  <>
                    {highlightedText("Be honest about your feelings.")} It’s
                    important to get any thoughts or concerns you have out in
                    the open.
                  </>
                );
              case 3:
                return (
                  <>
                    {highlightedText(
                      "Know that the way each person handles situations is different."
                    )}{" "}
                    You each have to process your thoughts and fears in your own
                    time and own way.
                  </>
                );
              default:
                return <></>;
            }
          };

          return (
            <ul className={classes.customList}>
              <Typography variant="h6" className={classes.paragraph}>
                <li className={classes.listItem}>
                  <Box className={classes.listItemIcon}>
                    <Icon icon="right-arrow" />
                  </Box>
                  {listItemContent()}
                </li>
              </Typography>
            </ul>
          );
        })}

        <Box
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          display={"flex"}
        >
          <Button
            title={"Next"}
            type="button"
            onClick={() => history.push("/Conversations/Onboarding/Step3")}
            buttonStyles={{ zIndex: 1 }}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default ConversationOnboardingStep2;
