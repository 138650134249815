import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "../Footer/Footer.module.scss";
import FooterMenuIcons from "../FooterMenuIcons/FooterMenuIcons";

import Icon from "../Icon/Icon";

interface FooterProps {
  hideSearchBar: boolean;
  active?: string;
  isTransparentBG?: boolean;
  shouldAnimateFooter?: boolean;
  shouldShowFooter?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  hideSearchBar = false,
  active = "home",
  isTransparentBG = false,
  shouldAnimateFooter = false,
  shouldShowFooter = true,
}) => {
  const history = useHistory();
  const isTransparent = isTransparentBG ? "#faf7dc" : "#fff";

  const [animationClass, setAnimationClass] = useState(
    shouldShowFooter ? "open" : "close"
  );
  useEffect(() => {
    if (shouldAnimateFooter)
      setAnimationClass(shouldShowFooter ? "opening" : "closing");
  }, [shouldShowFooter]);

  return !hideSearchBar ? (
    <div className={styles.footerContainer}>
      <div className={styles.footerWrapper}>
        <span className={styles.title}>
          <Icon icon="services-providers-search" className="mr-2" />
          Services &amp; Providers
        </span>
        {/* <button
          className={styles.servicesAndProvidersButton}
          onClick={() => {
            history.push("/ServicesAndProviders");
          }}
        >
          Search by City
        </button> */}
        <Link
          className={styles.servicesAndProvidersButton}
          to={{ pathname: "https://communityresources.trinity-health.org/" }} 
          target="_blank"
        >
          Search for Resources
        </Link>
        <FooterMenuIcons active={active} />
      </div>
    </div>
  ) : (
    <div
      className={`${styles.mobileFooterContainer} ${styles[animationClass]}`}
      style={{ backgroundColor: isTransparent }}
    >
      <div className={styles.mobileFooterWrapper}>
        <FooterMenuIcons active={active} />
      </div>
    </div>
  );
};

export default Footer;
