import React from "react";
import styles from "./AddToLibrary.module.scss";
import Icon from "../Icon/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
const defaultText = "CHECK THIS OPTION IF IT IS FOR YOU.";
const defaultSubText = "More information will be available at the library";

const checkboxStyles = makeStyles({
  root: {
    backgroundColor: "white",
    margin: "15px 15px 30px",
    color: "#1d918b",
    width: "18px",
    height: "18px",
    borderRadius: "0",
  },
});

interface AddToLibraryProps {
  defaultValue?: boolean;
  title: string;
  subTitle: string;
  check?: boolean;
  hasChecked: (isChecked: boolean) => boolean;
  onChange?: (e: boolean) => void;
}

const AddToLibrary: React.FC<AddToLibraryProps> = ({
  title = defaultText,
  subTitle = defaultSubText,
  defaultValue,
  check,
  hasChecked,
  onChange,
}) => {
  const classes = checkboxStyles();
  return (
    <div className={styles.container}>
      <div>
        <Checkbox
          style={{ color: "#1d918b" }}
          className={classes.root}
          checked={check ? check : defaultValue}
          onChange={(e) => {
            hasChecked && hasChecked(e.target.checked);
            onChange && onChange(e.target.checked);
          }}
        />
      </div>
      <div className={styles.wrapper}>
        <div
          dangerouslySetInnerHTML={{ __html: title }}
          className={styles.title}
        />
        <div className={styles.subTitle}>{subTitle}</div>
      </div>
      <div>
        <Icon icon={"bookmark"} className={styles.icon} />
      </div>
    </div>
  );
};

export default AddToLibrary;
