import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Icon from "../../components/Icon/Icon";
import { IoIosArrowBack } from "react-icons/io";

interface ConversationTitle {
  title?: string;
  history?: any;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center", // Align items horizontally
  },
  conversationText: {
    fontFamily: "Poppins",
    fontWeight: 600,
    textAlign: "left",
    fontSize: 13,
    letterSpacing: 0.09,
    textTransform: "uppercase",
    color: "#1D918B",
    paddingLeft: "7px",
  },
  header: {
    fontFamily: "Poppins",
    fontSize: 15,
    color: "#146E6C",
    marginBottom: 9,
    fontWeight: 600,
  },
  paragraph: {
    font: "normal normal 300 13px/20px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    opacity: 1,
    textAlign: "left",
  },
});

const ConversationTitle: React.FC<ConversationTitle> = ({ title = "", history }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <IoIosArrowBack
          style={{
            color: "#CADCDC",
            cursor: "pointer",
            zIndex: 1
          }}
          size={20}
          onClick={() => history ? history.goBack() : null}
        />
      <Icon icon="heart" style={{ marginRight: "5px", marginLeft: "5px" }} />
      <Typography variant="h6" className={classes.conversationText}>
        {title}
      </Typography>
    </Box>
  );
};
export default ConversationTitle;
