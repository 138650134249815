import React from "react";
import { ChatInterface, ChatUserInterface } from "../../services/chat-service";
import "./UserDetails.scss";
import moment from "moment";

interface UserDetailProps {
  chat: ChatInterface;
  routeToChat: Function;
  isUnreadMessage?: boolean;
}

const UserDetails: React.FC<UserDetailProps> = ({
  chat,
  routeToChat,
  isUnreadMessage = false,
}) => {
  const user = chat.users.find((user: ChatUserInterface) => {
    return !user.isAdmin;
  });

  const getInitials = (user: ChatUserInterface) => {
    return user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
  };

  const date = moment(
    chat.latestMessage ? chat.latestMessage.dateCreated : chat.dateCreated
  ).fromNow();

  return (
    <div className="user-row-chats" onClick={() => routeToChat()}>
      <div className="initial-container-chats">
        <span className="user-initials-chats">{user && getInitials(user)}</span>
      </div>
      <div className="name-container-chats">
        <span className="user-name-chats">
          {user && `${user.firstName} ${user.lastName}`}
        </span>
        <span className="user-sub-title-chats">
          {chat.latestMessage && chat.latestMessage.message}
        </span>
      </div>
      <div className="time-container-chats">
        <span className="time-chats">{date}</span>
        {isUnreadMessage && <span className="online-status-chat"></span>}
      </div>
    </div>
  );
};

export default UserDetails;
