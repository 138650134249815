import React, { useState, useEffect } from "react";
// @ts-ignore
import GoogleMapReact from "google-map-react";
import MapMarker from "../MapMarker/MapMarker";
import config from "../../config";
const { GOOGLE_MAPS_API_KEY } = config;

interface MapProps {
  affiliatedProviderJSON: {
    category: string;
    zipcode: string | null | string[];
    cities: string[];
    businessName: string;
    lat: number;
    lng: number;
    website: string | null;
    phone: string | null;
    logo: string | null;
  }[];
  searchArea: string;
  centeredCoordinates: {
    lat: number;
    lng: number;
  };
}

const Map: React.FC<MapProps> = ({
  affiliatedProviderJSON,
  searchArea,
  centeredCoordinates,
}) => {
  const [zoom] = useState(10);
  const [center, setCenter] = useState({});

  useEffect(() => {
    setCenter({
      lat: centeredCoordinates.lat,
      lng: centeredCoordinates.lng,
    });
  }, [centeredCoordinates.lat, centeredCoordinates.lng]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "300px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        center={center}
        defaultZoom={zoom}
      >
        {affiliatedProviderJSON
          .filter(
            (providers) =>
              providers.zipcode === searchArea ||
              providers.cities.includes(searchArea)
          )
          .map((provider, index) => {
            return (
              <MapMarker
                lat={provider.lat}
                lng={provider.lng}
                index={index + 1}
                key={`mapMarker - ${index}`}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
