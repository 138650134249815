import React, { FunctionComponent } from "react";
import styles from "./SectionContainer.module.scss";

interface SectionContainerProps {
  backgroundColor: string;
  paddingTop?: number;
}

const SectionContainer: FunctionComponent<SectionContainerProps> = ({
  children,
  backgroundColor,
  paddingTop,
}) => {
  return (
    <div
      className={styles.sectionContainer}
      style={{ backgroundColor, paddingTop }}
    >
      {children}
    </div>
  );
};

export default SectionContainer;
