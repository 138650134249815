import React, { useState } from "react";
import ProvidedByLogo from "../../components/ProvidedByLogo/ProvidedByLogo";
import Button from "../../components/Button/Button";
import Form from "react-bootstrap/Form";
import {
  forgotPassword,
  resetPassword,
  getModel,
} from "../../services/auth-service";
import Toastify, { successToast } from "../../components/Toastify/Toastify";
import styles from "./SignIn.module.scss";
import InputFieldSet from "../../components/InputFieldSet/InputFieldSet";
import IntroTitle from "../../components/IntroTitle/IntroTitle";
import { Link } from "react-router-dom";

const ResetPassword = ({ history }) => {
  const resetId = history.location.pathname.substring(15);
  const logo = history.cId;

  const [isError, setIsError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!!resetId) {
      password === passwordMatch
        ? getModel(resetId).then((response) => {
            const model = response.data[0];

            if (response.status === 200) {
              model.Password = password;
              resetPassword(model).then((modelResponse) => {
                if (modelResponse.status === 200) {
                  successToast("Password changed");
                  setPassword("");
                  setPasswordMatch("");
                  history.push("/Account/SignIn");
                }
              });
            }
          })
        : setIsError(true);
    } else {
      forgotPassword(email)
        .then((response) => {
          if (response.status === 200) {
            successToast("Check your email");
            setEmail("");
          }
        })
        .catch((e) => setIsError(true));
    }
  };

  return (
    <div className={styles.App}>
      <Toastify />
      <IntroTitle logo={logo} />
      <div className={styles.introTitleContainer}>
        <h5>Reset Password</h5>
        <Form
          noValidate
          validated={false}
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={styles.loginInputs}>
            {resetId === "" ? (
              <>
                <p className={styles.text}>
                  Enter your email to reset your password.
                </p>
                <InputFieldSet
                  title="Email Address"
                  type="text"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    isError && setIsError(false);
                  }}
                  required
                  autoComplete="on"
                  defaultValue="Error"
                  width={300}
                  helperText={isError && "Email incorrect."}
                  error={isError}
                />
              </>
            ) : (
              <Form.Group controlId="formBasicPassword">
                <p className={styles.text}>Create a new password</p>
                <InputFieldSet
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  required
                  title={"New Password"}
                  width={300}
                />

                <InputFieldSet
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPasswordMatch(e.target.value);
                    isError && setIsError(false);
                  }}
                  value={passwordMatch}
                  required
                  title={"Re-enter your new Password"}
                  width={300}
                  helperText={isError && "Your password does not match."}
                  error={isError}
                />
              </Form.Group>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button title="Continue" type="submit" isDisabled={isError} />
          </div>
        </Form>
      </div>
      <div className={styles.footer} style={{ marginBottom: "30px" }}>
        <div>
          <span className={styles.firstTimeText}>Login In</span>
        </div>
        <Link to={`/Account/SignIn`}>
          <span className={styles.link}>Here</span>
        </Link>
      </div>
      <ProvidedByLogo />
    </div>
  );
};

export default ResetPassword;
