import React, { useContext } from "react";
import { Route, Redirect } from "react-router";
import { UserDataContext } from "../../Context/Auth-Provider";

export const AdminRoute = ({ component: Component, redirect, ...rest }) => {
  const { isLoggedIn, profile } = useContext(UserDataContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (profile.isLoading) {
          return <></>;
        }

        if (profile.userId && profile.isAdmin) {
          return <Component {...props} userData={profile} {...rest} />;
        } else if (isLoggedIn) {
          return <Redirect to={redirect} {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={`/Account/SignIn?cId=${profile.affiliation}`}
              {...props}
              {...rest}
            />
          );
        }
      }}
    />
  );
};
